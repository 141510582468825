import {extendTheme} from 'native-base';

export const customTheme = extendTheme({
  config: {
    useSystemColorMode: true
  },
  colors: {
    primary: {
      50: '#552E44',
      100: '#653859',
      200: '#764270',
      300: '#814C86',
      400: '#865695',
      500: '#8961a5',
      600: '#8F79B5',
      700: '#9992C4',
      800: '#ABAED2',
      900: '#C4CDE1'
    },
    secondary: {
      50: '#f1f1fb',
      100: '#d7d7dd',
      200: '#bdbdc2',
      300: '#a2a2a8',
      400: '#88888f',
      500: '#6e6e75',
      600: '#56565c',
      700: '#3d3d42',
      800: '#25252a',
      900: '#0b0b14'
    }
  },
  components: {
    Text: {
      defaultProps: {
        fontSize: 'md',
        color: 'muted.900'
      }
    },
    Divider: {
      defaultProps: {
        bgColor: 'gray.200'
      }
    },
    Input: {
      defaultProps: {
        size: 'md'
      },
      baseStyle: {
        px: '3',
        py: '2.5'
      },
      sizes: {
        md: {
          fontSize: 'md',
          height: '44px'
        }
      }
    },
    Button: {
      baseStyle: {
        _spinner: {
          height: '24px'
        }
      },
      sizes: {
        md: {
          px: '3',
          py: '2.5',
          height: '44px',
          _text: {
            fontSize: 'md'
          },
          _icon: {
            size: 'md'
          }
        }
      }
    }
  }
});

type CustomThemeType = typeof customTheme;

declare module 'native-base' {
  interface ICustomTheme extends CustomThemeType {}
}
