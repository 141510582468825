import {useActionSheet} from '@expo/react-native-action-sheet';
import {NativeStackScreenProps} from '@react-navigation/native-stack';
import {Button, Box, ShareIcon, ThreeDotsIcon} from 'native-base';
import React, {useCallback, useEffect, useMemo, useRef} from 'react';
import {RefreshControl, ScrollView} from 'react-native';
import {SafeAreaView} from 'react-native-safe-area-context';
import {t} from 'react-native-tailwindcss';
import {ChargeStatus, useChargeQuery, useOnUpdateChargeSubscription} from '../api/generated';
import {Loader} from '../components/Loader';
import {PaymentStatusIcon} from '../components/PaymentStatusIcon';
import {PropertyCardList} from '../components/PropertyList';
import {useAsyncAction} from '../hooks/useAsyncAction';
import {usePaymentUrl} from '../hooks/usePaymentUrl';
import {useUserContext} from '../lib/authContext';
import {formatEmail, formatMoney, formatPhone} from '../lib/formatters';
import {useTranslation} from 'react-i18next';
import moment from '../lib/moment';
import {canBeRefunded, getPaymentMethod} from '../lib/paymentUtils';
import {shareUrl} from '../lib/utils';
import {Nav, NavParams} from './navigation';
import {Ionicons} from '@expo/vector-icons';
import {triggerHapticFeedback} from '../lib/hapticFeedback';
import i18n, {TranslationKeys} from '../lib/i18n';
import {useKeepAwake} from 'expo-keep-awake';
import {HeaderButtonRight} from '../components/HeaderButton';

const getStatusStyle = (status: ChargeStatus) => {
  switch (status) {
    case ChargeStatus.SUCCEEDED: {
      return t.bgGreen600;
    }
    case ChargeStatus.FAILED: {
      return t.bgRed600;
    }
    default: {
      return t.bgGray600;
    }
  }
};

const getStatusLabel = (status: ChargeStatus) => {
  switch (status) {
    case ChargeStatus.SUCCEEDED: {
      return i18n.t('paymentSucceeded');
    }
    case ChargeStatus.PENDING: {
      return i18n.t('paymentPending');
    }
    case ChargeStatus.PENDING_PROCESSING: {
      return i18n.t('paymentPendingProcessing');
    }
    case ChargeStatus.FAILED: {
      return i18n.t('paymentFailed');
    }
    case ChargeStatus.REFUNDED: {
      return i18n.t('paymentRefunded');
    }
    case ChargeStatus.PARTIALLY_REFUNDED: {
      return i18n.t('paymentPartiallyRefunded');
    }
    case ChargeStatus.AUTHORIZED: {
      return i18n.t('paymentAuthorized');
    }
    case ChargeStatus.CANCELED: {
      return i18n.t('paymentCanceled');
    }
    case ChargeStatus.EXPIRED: {
      return i18n.t('paymentExpired');
    }
  }
};

export const PaymentScreen: React.FC<NativeStackScreenProps<NavParams, Nav.PaymentScreen>> = ({
  navigation,
  route
}) => {
  useKeepAwake();
  const {paymentId} = route.params;
  const i18n = useTranslation();
  const {accountId} = useUserContext();
  const {data, loading: loadingPayment, refetch} = useChargeQuery({variables: {id: paymentId}});
  const {showActionSheetWithOptions} = useActionSheet();
  const payment = data?.charge;
  const [onRefresh, isRefreshing] = useAsyncAction(refetch);
  const paymentUrl = usePaymentUrl(paymentId);
  const canTriggerFeedback = useRef<boolean>(false);
  const isPending = payment?.status === ChargeStatus.PENDING;

  const actions: ReadonlyArray<TranslationKeys> = useMemo(() => {
    const actionsArray: Array<TranslationKeys> = ['cancel'];
    if (canBeRefunded(payment)) {
      actionsArray.unshift('refundPayment');
    }
    if (isPending) {
      actionsArray.unshift('requestToPay');
    }
    if (actionsArray.length > 1) {
      actionsArray.unshift('share');
    }
    return actionsArray;
  }, [payment, isPending]);

  useOnUpdateChargeSubscription({
    variables: {accountId, id: paymentId}
  });

  const status = data?.charge?.status;

  useEffect(() => {
    if (status && [ChargeStatus.PENDING, ChargeStatus.PENDING_PROCESSING].includes(status)) {
      canTriggerFeedback.current = true;
    }
  }, [status]);

  useEffect(() => {
    if (status && ![ChargeStatus.PENDING, ChargeStatus.PENDING_PROCESSING].includes(status)) {
      if (canTriggerFeedback.current) {
        triggerHapticFeedback();
        canTriggerFeedback.current = false;
      }
    }
  }, [status]);

  const handleShare = useCallback(async () => {
    return shareUrl(isPending ? paymentUrl : `${paymentUrl}/receipt`);
  }, [isPending, paymentUrl]);

  const handleRefund = useCallback(async () => {
    if (!payment) return;
    navigation.navigate(Nav.RefundScreen, {paymentId});
  }, [navigation, payment, paymentId]);

  const handleRequestToPay = useCallback(async () => {
    if (!payment) return;
    navigation.navigate(Nav.RequestToPayScreen, {paymentId});
  }, [navigation, payment, paymentId]);

  const handleAction = useCallback(
    async (buttonIndex?: number) => {
      if (typeof buttonIndex !== 'number') return;
      const action = actions[buttonIndex];
      if (action === 'refundPayment') {
        return handleRefund();
      }
      if (action === 'share') {
        return handleShare();
      }
      if (action === 'requestToPay') {
        return handleRequestToPay();
      }
    },
    [actions, handleRefund, handleShare, handleRequestToPay]
  );

  const openActionSheet = useCallback(() => {
    showActionSheetWithOptions(
      {
        options: actions.map((a) => i18n.t(a)),
        cancelButtonIndex: actions.indexOf('cancel')
      },
      handleAction
    );
  }, [actions, handleAction, i18n, showActionSheetWithOptions]);

  useEffect(() => {
    if (!payment) return;
    navigation.setOptions({
      headerRight: () => {
        if (payment.status === ChargeStatus.EXPIRED) {
          return null;
        }
        return (
          <Box style={[t.flexRow]}>
            {actions.length > 1 ? (
              <HeaderButtonRight icon={'ellipsis-horizontal-sharp'} onPress={openActionSheet} />
            ) : (
              <HeaderButtonRight icon={'share-social'} onPress={handleShare} />
            )}
          </Box>
        );
      }
    });
  }, [navigation, route, actions, payment, handleShare, handleRefund, openActionSheet]);

  const formattedAmount = formatMoney((payment?.amount ?? 0) / 100, payment?.currency);

  const properties = useMemo(() => {
    if (!payment) return [];

    const phone = payment.paymentMethod?.bizum?.phoneNumber || payment.customer?.phone;
    const email = payment.customer?.email || payment.paymentMethod?.card?.cardholderEmail;

    const baseProperties = [
      {label: i18n.t('amount'), value: formattedAmount},
      {label: i18n.t('description'), value: payment.description},
      {label: i18n.t('createdAt'), value: moment.unix(payment.createdAt).format('lll')},
      {label: i18n.t('orderId'), value: payment.orderId},
      {
        label: i18n.t('paymentMethod'),
        value: getPaymentMethod(payment)
      },
      {
        label: i18n.t('customerName'),
        value: payment.customer?.name || payment.paymentMethod?.card?.cardholderName
      },
      {
        label: i18n.t('customerEmail'),
        value: formatEmail(email),
        onPress: email
          ? () => navigation.navigate(Nav.PaymentsScreen, {search: {email}})
          : undefined
      },
      {
        label: i18n.t('customerPhone'),
        value: formatPhone(phone, null, true),
        onPress: phone
          ? () => navigation.navigate(Nav.PaymentsScreen, {search: {phone}})
          : undefined
      },
      {label: i18n.t('status'), value: i18n.t(payment.status)}
    ];

    if (payment.refundedAmount) {
      baseProperties.push({
        label: i18n.t('refundedAmount'),
        value: formatMoney(payment.refundedAmount / 100, payment.currency)
      });
    }

    if (payment.status === ChargeStatus.FAILED) {
      baseProperties.push(
        {label: i18n.t('statusCode'), value: payment.statusCode},
        {label: i18n.t('statusMessage'), value: payment.statusMessage}
      );
    }

    return baseProperties;
  }, [payment, i18n, formattedAmount, navigation]);

  if (loadingPayment || !payment) return <Loader active />;

  return (
    <SafeAreaView style={[t.flex1, t.bgWhite]} edges={['bottom']}>
      <ScrollView
        refreshControl={<RefreshControl refreshing={isRefreshing} onRefresh={onRefresh} />}>
        <Box
          style={[
            t.flex,
            t.flexCol,
            t.itemsCenter,
            t.p6,
            t.justifyCenter,
            getStatusStyle(payment.status)
          ]}>
          <Box
            style={t.mB2}
            _text={{
              fontSize: '2xl',
              color: 'white'
            }}>
            {getStatusLabel(payment.status)}
          </Box>
          <PaymentStatusIcon
            status={payment.status}
            style={[t.textWhite, t.mB1, {fontSize: 100}]}
          />
          <Box
            style={t.mB3}
            _text={{
              fontSize: '5xl',
              color: 'white',
              fontWeight: 'bold'
            }}>
            {formattedAmount}
          </Box>
          <Box
            _text={{
              fontSize: '3xl',
              color: 'white',
              fontWeight: 'bold'
            }}>
            {payment.orderId}
          </Box>
        </Box>
        <Box style={t.mX2}>
          <PropertyCardList properties={properties} />
        </Box>
      </ScrollView>
      {isPending && (
        <Box style={[t.p4]}>
          <Button
            leftIcon={<Ionicons name="qr-code-outline" size={18} color="white" />}
            onPress={() =>
              navigation.navigate(Nav.PaymentQRScreen, {
                paymentId
              })
            }>
            {i18n.t('showQRCode')}
          </Button>
        </Box>
      )}
    </SafeAreaView>
  );
};
