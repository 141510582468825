import {Spinner, Box} from 'native-base';
import React from 'react';
import {colors, t} from 'react-native-tailwindcss';

type Props = {active: boolean; dimmed?: boolean};

export const Loader: React.FC<Props> = ({active, dimmed}) => {
  if (!active) return null;
  return (
    <Box
      style={[
        t.absolute,
        t.flex,
        t.justifyCenter,
        t.contentCenter,
        t.wFull,
        t.hFull,
        t.z10,
        dimmed && {backgroundColor: 'rgb(0, 0, 0)', opacity: 0.32}
      ]}>
      <Spinner size="lg" color={colors.gray500} />
    </Box>
  );
};
