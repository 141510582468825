import React from 'react';
import {Platform} from 'react-native';
import SkeletonContent from 'react-native-skeleton-content';
import {t} from 'react-native-tailwindcss';

interface Props {
  isLoading: boolean;
  numberOfEntries: number;
  children?: React.ReactNode;
}

export const LoadingEntriesSkeleton: React.FC<Props> = ({isLoading, numberOfEntries, children}) => {
  return (
    <SkeletonContent
      isLoading={isLoading}
      containerStyle={[t.flex1, t.flexGrow]}
      layout={[...Array(numberOfEntries)].map((_, i) => ({
        key: i,
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        marginHorizontal: 20,
        marginTop: 12,
        marginBottom: 13,
        children: [
          {
            width: 30,
            height: 30,
            borderRadius: 15,
            marginRight: 20,
            marginLeft: 2,
            marginTop: 8
          },
          {
            height: Platform.OS === 'web' ? 51 : 48,
            flex: 1
          }
        ]
      }))}>
      {children}
    </SkeletonContent>
  );
};
