import {createNativeStackNavigator} from '@react-navigation/native-stack';
import {useToast} from 'native-base';
import {useTranslation} from 'react-i18next';
import React, {useContext, useEffect} from 'react';
import {Platform} from 'react-native';
import {AuthScreens} from '../auth';
import {AuthContext} from '../lib/authContext';
import {Screens} from '../screens';
import {config} from '../stage.config';
import {Anchor} from './Anchor';
import {NavParams} from '../screens/navigation';
import {NavigatorScreenParams} from '@react-navigation/native';
import {AuthNavParams} from '../auth/navigation';

export type LayoutParams = {
  Screens: NavigatorScreenParams<NavParams>;
  AuthScreens: NavigatorScreenParams<AuthNavParams>;
};

const Stack = createNativeStackNavigator<LayoutParams>();

export const Layout: React.FC = () => {
  const i18n = useTranslation();
  const authState = useContext(AuthContext);
  const toast = useToast();

  useEffect(() => {
    if (Platform.OS !== 'web') return;
    const params = new URLSearchParams(window.location.search);
    const isFromDesktop = params.get('from_desktop') === '1';
    if (isFromDesktop) {
      toast.show({
        // @ts-ignore
        text: (
          <Anchor style={{color: '#FFF'}} href={`${config.dashboardUrl}?from_mobile=1`}>
            {i18n.t('goToDesktop')}
          </Anchor>
        ),
        buttonText: i18n.t('close'),
        placement: 'top',
        duration: 0
      });
    }
  }, [toast]);

  return (
    <Stack.Navigator>
      {authState.isAuthenticated ? (
        <Stack.Screen
          name="Screens"
          component={Screens}
          options={{
            headerShown: false,
            presentation: 'modal',
            animationTypeForReplace: authState.isAuthenticated ? 'pop' : 'push'
          }}
        />
      ) : (
        <Stack.Screen
          name="AuthScreens"
          component={AuthScreens}
          options={{
            presentation: 'modal',
            headerShown: false
          }}
        />
      )}
    </Stack.Navigator>
  );
};
