import {Alert, Text} from 'native-base';
import React from 'react';
import {useTranslation, Trans} from 'react-i18next';
import {Linking} from 'react-native';
import {TouchableOpacity} from 'react-native-gesture-handler';
import {t} from 'react-native-tailwindcss';

export const AccountNotConfiguredBanner: React.FC<{onClose: () => void}> = ({onClose}) => {
  const i18n = useTranslation();
  return (
    <Alert status={'error'} margin={5}>
      <TouchableOpacity onPress={onClose}>
        <Alert.Icon size={6} style={t.selfCenter} />
        <Text textAlign="center" style={[t.fontBold]}>
          {i18n.t('accountNotConfigured')}
        </Text>
        <Text textAlign="center" style={[t.textSm]}>
          <Trans
            i18nKey="accountNotConfiguredDescription"
            components={[
              <Text
                underline
                color="blue.800"
                onPress={() => Linking.openURL('https://dashboard.monei.com/')}>
                {'MONEI\u202FDashboard'}
              </Text>
            ]}
          />
        </Text>
      </TouchableOpacity>
    </Alert>
  );
};
