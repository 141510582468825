import {Box, HStack, Text, useTheme} from 'native-base';
import React, {useState} from 'react';
import {StyleSheet, TextInput, View} from 'react-native';
import {t} from 'react-native-tailwindcss';

interface Props {
  value: string;
  onChange: (newValue: string) => void;
  editable?: boolean;
  errorMessage?: string;
  label?: string;
  autoFocus?: boolean;
}

const DOT_SIZE = 20;

export const PinInput = React.forwardRef<TextInput, Props>(
  ({onChange, value, editable, errorMessage, label, autoFocus}, ref) => {
    const {colors} = useTheme();

    const updatePin = (value: string) => {
      try {
        parseInt(value);
      } catch {
        return;
      }
      onChange(value);
    };

    const renderDigit = (digit: number) => {
      const hasValue = value.length > digit;
      return (
        <View
          style={{
            height: DOT_SIZE,
            width: DOT_SIZE,
            margin: 10,
            borderRadius: DOT_SIZE / 2,
            borderWidth: 1,
            borderColor: colors.muted[900],
            backgroundColor: hasValue ? colors.muted[900] : colors.white
          }}
        />
      );
    };

    return (
      <View>
        <Box style={[t.p2, t.itemsCenter]}>
          {!!label && <Text textAlign={'center'}>{label}</Text>}
          <HStack style={[t.p2]}>
            {renderDigit(0)}
            {renderDigit(1)}
            {renderDigit(2)}
            {renderDigit(3)}
          </HStack>
          {!!errorMessage && (
            <Text textAlign={'center'} color={colors.error[500]}>
              {errorMessage}
            </Text>
          )}
        </Box>
        <TextInput
          ref={ref}
          autoFocus={autoFocus}
          editable={editable}
          style={styles.input}
          keyboardType="numeric"
          // @ts-ignore
          inputMode="numeric"
          autoComplete="off"
          autoCorrect={false}
          secureTextEntry
          autoCapitalize="none"
          value={value}
          onChangeText={updatePin}
        />
      </View>
    );
  }
);

const styles = StyleSheet.create({
  input: {
    width: '100%',
    height: '100%',
    position: 'absolute',
    left: 0,
    top: 0,
    textAlign: 'center',
    zIndex: 1,
    opacity: 0
  }
});
