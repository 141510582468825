import Constants from 'expo-constants';

type Stage = 'dev' | 'prod';

const stage = (Constants.manifest?.extra?.stage as Stage) || 'prod';

const globalConfig = {
  termsOfServiceUrl: 'https://monei.com/terms-of-service',
  privacyPolicyUrl: 'https://monei.com/privacy-policy',
  testingUrl: 'https://docs.monei.com/docs/testing/',
  supportEmail: 'support@monei.com',
  supportUrl: 'https://support.monei.com',
  docsUrl: 'https://docs.monei.com'
};

const stageConfig = {
  dev: {
    production: false,
    region: 'eu-west-1',
    apiEndpoint: 'https://iye6jsnmozda3epe2tqfmapcri.appsync-api.eu-west-1.amazonaws.com/graphql',
    graphqlEndpoint: 'https://mo-graphql.microapps-staging.com',
    identityPoolId: 'eu-west-1:f473b883-ee13-481f-98ce-faedf4b1c769',
    userPoolId: 'eu-west-1_4DJSoUfPN',
    userPoolWebClientId: '3b2lfk8ju3lf28qr82hhi9onm',
    intercomAppId: 'blsf713t',
    demoEmail: '',
    demoPassword: '',
    gtmId: undefined,
    gtmAuth: undefined,
    gtmPreview: undefined,
    recaptchaSiteKey: '6LejBMYUAAAAAIqU0f3BtAMzBqy70HIIbUuKjqvT',
    serviceBucket: 'monei-dev-service-bucket',
    paymentPagePreviewUrl: 'https://mo-js.microapps-staging.com/v1/payment-page/',
    hostedPaymentDomain: 'secure.microapps-staging.com',
    dashboardUrl: 'https://mo.microapps-staging.com',
    signUpUrl: 'https://mo.microapps-staging.com?action=signUp&from_mobile=1'
  },
  prod: {
    production: true,
    region: 'eu-west-1',
    apiEndpoint: 'https://tg2prshv7fa7fa6wxrw7yufnpu.appsync-api.eu-west-1.amazonaws.com/graphql',
    graphqlEndpoint: 'https://graphql.monei.com',
    identityPoolId: 'eu-west-1:9a97e4d1-4b73-4fb9-9c41-2a916450751f',
    userPoolId: 'eu-west-1_H1gXoXZt8',
    userPoolWebClientId: '4aasr6j53s7g8a0cmogjf0jeh4',
    intercomAppId: 'blsf713t',
    demoEmail: null,
    demoPassword: null,
    gtmId: 'GTM-NHS56QJ',
    gtmAuth: undefined,
    gtmPreview: undefined,
    recaptchaSiteKey: '6LejBMYUAAAAAIqU0f3BtAMzBqy70HIIbUuKjqvT',
    serviceBucket: 'monei-prod-service-bucket',
    paymentPagePreviewUrl: 'https://js.monei.com/v1/payment-page/',
    hostedPaymentDomain: 'secure.monei.com',
    dashboardUrl: 'https://dashboard.monei.com',
    signUpUrl: 'https://dashboard.monei.com?action=signUp&from_mobile=1'
  }
};

export const config = {...globalConfig, ...stageConfig[stage]};
