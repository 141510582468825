import Color from 'color';
import {Box, Text} from 'native-base';
import React from 'react';
import NativeQRCode from 'react-native-qrcode-svg';
import {t} from 'react-native-tailwindcss';
import {useThemeQuery} from '../api/generated';
import {usePaymentUrl} from '../hooks/usePaymentUrl';
import {MoneiLogo} from './Logo';

type Props = {
  paymentId: string;
};

const QR_CODE_SIZE = 280;
const MONEI_LOGO =
  'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAGQAAABkCAYAAABw4pVUAAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAA55JREFUeNrsne9t2zAQxdkgA3gEjaARXC9g9XuB2hPEniD2BPIG7gcP4C5QaASNoBE8QkiXCowg/qNKunvHvAcEFoxE5PHHuyOPtuIcRVEURf2fvrUXP//+WfiXTKDN02E231kYHD8mK/8yEWiq8WPyO1w8X7z5y/9MhQytfQcqcBhhLEqh5sJYnIE8Kdm79wZPgGGEvu012tYCEkLjCthBVkLhGwZI0KufiRmgd4Q+vWq1/6Rs/x7QO1T7pA1k6mdkAeQdhdTCBhUITILXTORoQCaaMfsypwntOeCBnFc1fobmit6Ro6z6UIBoJ1OYxQUSkDyWKqS9I7SZE8j1vclEEAZK/oIFEgaoFGyvREjkyECCFrGwN7Z3hDYWaMYjApFKsohVAlggmZ/BmxG9I9w7I5Buehmj+Bjv+YJqNDKQsRI8XCK3AiSoGLL4GO9VIBuMDuQ8o4fYm8R7lOjGWgASYv4QO3i1U8DUgLQ7+LyHd+RoO3LrQFzPcFNaMdISkGn87FhX7wh/MyUQgARvJZFbBtK1OgtxCpgykPNq6ZHiY/ydlTXjLAJ5NEmXFg2zCuTm6SLaKeBXANLuTbJPYGRW9hypAbm2goIuHqYMJKi4TPDxurBskHUgQfsr1yb1nACQy9PFjEBAEnwidiQRspISgRAIRSD9dEoZyNEgkG3KQNbSM24AGHWyQA6zeSM943oo9FX8iRPiOSQ+VqMyAGTp+3pKHkhrLDiMndajP1SAgIeuk2bf1Ja9HspGOmEihyqUfQha6Dp6GKpLc1Ug3vhaYyVzI1SpTxCEnfo2LjG1tdYMVTBA4iBoz8yqfaIbPeQflLDE1BoQiFAFBaQNGU6nrLKNy3ACAQhdNdoDOaHK73HJKblDhqsYIJ6HLIVC1zYuuwnkjpc0bvzSRR0rBY5AHoMydkV47UCFfIQ71qDtkB/iDAskxvehQ5dEOEzWQ8aoCC8RyiNmgQwcuo7oz5s3ASQOYt/NG1R5xLqHONe/IgwfqkwB6VlWqbQPnVL0kDZ0dR1YM6HKHJA29LhuZRWoSm5yQDqGrsrKv1ay7CFdKsJLZ1BWP/1+L3SZC1WmgdypCMNWclP2kLYiXKcSqswDuTL4O8RDpy8D5ENF+FYYIxBBKJu4YTRTHrmlJL6n7kH8cImIX/okEIpACIQiEAKhCIRAKAIhEIpAKAIhEIpACIQaXO/nIYfZ/DuH41M1bvyTyIbDTFEURfXVmwADAIYnEL+P29B9AAAAAElFTkSuQmCC';
const MONEI_BRAND_COLORS = ['#44ab9d', '#888fc9'];

export const QRCode: React.FC<Props> = ({paymentId}) => {
  const url = usePaymentUrl(paymentId);
  const {data, loading} = useThemeQuery({
    fetchPolicy: 'cache-first'
  });

  if (loading) {
    return <Box style={{height: QR_CODE_SIZE}} />;
  }

  let brandColor: string | undefined;
  let brandIcon: string | undefined;

  if (data) {
    const {accentColor, iconUrl} = data.account.branding ?? {};
    if (iconUrl) {
      brandIcon = iconUrl;
    }
    if (accentColor) {
      brandColor = Color(accentColor).hex();
    }
  }

  return (
    <>
      <NativeQRCode
        value={url}
        size={QR_CODE_SIZE}
        // @ts-expect-error
        logo={brandColor ? brandIcon : MONEI_LOGO}
        logoBackgroundColor="#fff"
        logoSize={42}
        logoMargin={6}
        enableLinearGradient={!brandColor}
        linearGradient={brandColor ? undefined : MONEI_BRAND_COLORS}
        color={brandColor}
      />
      <Box style={[t.flexRow, t.itemsCenter, t.justifyCenter, t.mT4]}>
        <Text style={[t.textSm, t.textGray600]}>Powered by </Text>
        <MoneiLogo height={12} />
      </Box>
    </>
  );
};
