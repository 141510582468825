import React from 'react';
import {useTranslation} from 'react-i18next';
import {ConfirmLogInScreen} from './ConfirmLogInScreen';
import {LogInScreen} from './LogInScreen';
import {AuthNav, Stack} from './navigation';

export const authStackLinking = {
  initialRouteName: AuthNav.LogInScreen,
  screens: {
    [AuthNav.LogInScreen]: 'login',
    [AuthNav.ConfirmLogInScreen]: 'confirm-login'
  }
};

export const AuthScreens: React.FC = () => {
  const i18n = useTranslation();
  return (
    <Stack.Navigator initialRouteName={AuthNav.LogInScreen}>
      <Stack.Screen
        name={AuthNav.LogInScreen}
        component={LogInScreen}
        options={{
          title: i18n.t('logIn'),
          headerShown: false
        }}
      />
      <Stack.Screen
        name={AuthNav.ConfirmLogInScreen}
        component={ConfirmLogInScreen}
        options={{
          title: i18n.t('logIn'),
          headerShown: false
        }}
      />
    </Stack.Navigator>
  );
};
