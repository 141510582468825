import {ActionSheetProvider} from '@expo/react-native-action-sheet';
import {NavigationContainer} from '@react-navigation/native';
import {Amplify, Auth, I18n} from 'aws-amplify';
import * as SplashScreen from 'expo-splash-screen';
import {checkForUpdateAsync, fetchUpdateAsync, reloadAsync} from 'expo-updates';
import React, {useEffect, useState} from 'react';
import {Alert, Platform} from 'react-native';
import {SafeAreaProvider} from 'react-native-safe-area-context';
import {authStackLinking} from './auth';
import {Layout} from './components/Layout';
import {AuthContext, IAuthState} from './lib/authContext';
import {loadFonts} from './lib/loadFonts';
import {mainStackLinking} from './screens/MainStack';
import {config} from './stage.config';
import {Text} from 'native-base';
import {ThemeContextProvider} from './themes/ThemeProvider';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';
import {OutsideClickContextProvider} from './lib/outsideClickContext';
import './lib/i18n';

// Keep the splash screen visible while we fetch resources
SplashScreen.preventAutoHideAsync();

Amplify.configure({
  Auth: {
    identityPoolId: config.identityPoolId,
    region: config.region,
    userPoolId: config.userPoolId,
    userPoolWebClientId: config.userPoolWebClientId,
    mandatorySignIn: true
  },
  Analytics: {
    disabled: true
  }
});

I18n.setLanguage('en');

const linking = {
  prefixes: [
    'https://tpv.monei.com',
    'https://business.monei.com',
    'https://m-pay.microapps-staging.com',
    'http://localhost:19006'
  ],
  config: {
    screens: {
      Screens: mainStackLinking,
      AuthScreens: authStackLinking
    }
  }
};

export default () => {
  const [authState, setAuthState] = useState<IAuthState>({
    isAuthenticated: false,
    currentUser: null
  });
  const [isLoading, setLoading] = useState(true);

  const signOut = async () => {
    await Auth.signOut();
    setAuthState({isAuthenticated: false, currentUser: null});
  };

  const checkForUpdates = async () => {
    if (__DEV__ || Platform.OS === 'web') return;
    try {
      const update = await checkForUpdateAsync();
      if (update.isAvailable) {
        await fetchUpdateAsync();
        Alert.alert(
          'Update',
          'New version is available',
          [
            {text: 'Cancel', style: 'cancel'},
            {text: 'Reload', onPress: () => reloadAsync()}
          ],
          {cancelable: true}
        );
      }
    } catch (e) {
      console.error(e);
    }
  };

  const checkAuth = async () => {
    try {
      const currentUser = await Auth.currentAuthenticatedUser();
      setAuthState({currentUser, isAuthenticated: true});
    } catch {
      setAuthState({currentUser: null, isAuthenticated: false});
    }
  };

  const loadApp = async () => {
    try {
      await Promise.all([loadFonts(), checkAuth(), checkForUpdates()]);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    loadApp();
  }, []);

  if (isLoading) {
    return null;
  }

  return (
    <ThemeContextProvider>
      <AuthContext.Provider value={{...authState, setAuthState, signOut}}>
        <OutsideClickContextProvider>
          <SafeAreaProvider
            style={
              Platform.OS === 'web'
                ? {maxWidth: 500, width: '100%', flex: 1, alignSelf: 'center'}
                : undefined
            }>
            <ActionSheetProvider>
              <NavigationContainer
                //@ts-ignore
                linking={linking}
                onReady={async () => {
                  await SplashScreen.hideAsync();
                }}
                documentTitle={{
                  formatter: (options, route) => `${options?.title ?? route?.name} | MONEI Pay`
                }}
                fallback={<Text>Loading…</Text>}>
                <Layout />
              </NavigationContainer>
            </ActionSheetProvider>
          </SafeAreaProvider>
        </OutsideClickContextProvider>
      </AuthContext.Provider>
    </ThemeContextProvider>
  );
};

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorkerRegistration.register();
