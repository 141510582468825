import {GraphQLError} from 'graphql';
import {Toast} from 'native-base';
import {getAdminEmail} from '../storage/sessionStorage';
import i18n from '../lib/i18n';

const adminEmail = getAdminEmail();

export const showError = (message: string) => {
  Toast.show({
    description: message,
    duration: 6000,
    backgroundColor: 'danger.700',
    _text: {
      textAlign: 'center'
    }
  });
};

export const showGraphQLError = (error: GraphQLError) => {
  if ((error as any).errorType === 'Unauthorized') {
    switch (error.path?.[0]) {
      case 'createPayment':
        return showError(i18n.t('authCreatePayment', {adminEmail}));
      case 'refundPayment':
        return showError(i18n.t('authRefundPayment', {adminEmail}));
    }
    return showError(i18n.t('authGeneric', {adminEmail}));
  }
  showError(error.message);
};
