import Color from 'color';
import {NativeBaseProvider, StatusBar} from 'native-base';
import React, {useState} from 'react';
import {Platform} from 'react-native';
import {customTheme} from './default';
// @ts-expect-error
import MetaTags from 'react-meta-tags';

const defaultContext = {
  setTheme: (_args: {primaryColor: string}) => {},
  resetTheme: () => {}
};

export const ThemeContext = React.createContext(defaultContext);

export const ThemeContextProvider: React.FC<{children?: React.ReactNode}> = ({children}) => {
  const [theme, setTheme] = useState(customTheme);

  const createAndSetTheme = ({primaryColor}: {primaryColor: string}) => {
    const color = Color(primaryColor);
    const baseLightness = color.lightness();

    const theme = {
      ...customTheme,
      colors: {
        ...customTheme.colors,
        primary: {
          50: color.lightness(baseLightness + ((100 - baseLightness) * 11) / 12).hex(),
          100: color.lightness(baseLightness + ((100 - baseLightness) * 5) / 6).hex(),
          200: color.lightness(baseLightness + ((100 - baseLightness) * 2) / 3).hex(),
          300: color.lightness(baseLightness + (100 - baseLightness) / 2).hex(),
          400: color.lightness(baseLightness + (100 - baseLightness) / 3).hex(),
          500: color.lightness(baseLightness + (100 - baseLightness) / 6).hex(),
          600: color.hex(), // used by CTAs
          700: color.lightness(baseLightness * 0.75).hex(),
          800: color.lightness(baseLightness * 0.5).hex(),
          900: color.lightness(baseLightness * 0.25).hex()
        }
      }
    };
    setTheme(theme);
  };

  const resetTheme = () => {
    setTheme(customTheme);
  };

  return (
    <ThemeContext.Provider value={{setTheme: createAndSetTheme, resetTheme}}>
      {Platform.OS === 'web' && (
        <MetaTags>
          <meta name={'theme-color'} content={theme.colors.primary[600]} />
        </MetaTags>
      )}
      {Platform.OS === 'android' && <StatusBar backgroundColor={theme.colors.primary[600]} />}
      <NativeBaseProvider theme={theme}>{children}</NativeBaseProvider>
    </ThemeContext.Provider>
  );
};
