import {NativeStackScreenProps} from '@react-navigation/native-stack';
import {Auth} from 'aws-amplify';
import {
  Box,
  FormControl,
  Heading,
  VStack,
  WarningOutlineIcon,
  Button,
  Text,
  Checkbox,
  Input
} from 'native-base';
import React, {useContext, useEffect, useState} from 'react';
import {Controller, useForm} from 'react-hook-form';
import {ScrollView} from 'react-native';
import {SafeAreaView} from 'react-native-safe-area-context';
import {colors, t} from 'react-native-tailwindcss';
import {KeyboardAvoidingView} from '../components/KeyboardAvoidingView';
import {AuthContext} from '../lib/authContext';
import {AuthNav, AuthNavParams} from './navigation';
import {useTranslation} from 'react-i18next';

export const ConfirmLogInScreen: React.FC<
  NativeStackScreenProps<AuthNavParams, AuthNav.ConfirmLogInScreen>
> = ({navigation}) => {
  const {
    control,
    handleSubmit,
    setError,
    formState: {errors}
  } = useForm();
  const i18n = useTranslation();
  const [isLoading, setLoading] = useState(false);
  const {currentUser, setAuthState, mfaType, mfaDestination} = useContext(AuthContext);

  useEffect(() => {
    if (!mfaDestination) {
      navigation.navigate(AuthNav.LogInScreen);
    }
  }, [mfaDestination, navigation]);

  const onSubmit = async ({code, remember}: any) => {
    setLoading(true);
    try {
      await Auth.confirmSignIn(currentUser, code, mfaType);
      if (remember) await Auth.rememberDevice();
      const user = await Auth.currentAuthenticatedUser();
      setAuthState({currentUser: user, isAuthenticated: true});
    } catch (error) {
      if (error instanceof Error) {
        setError('code', {type: 'manual', message: error.message});
      }
      setLoading(false);
    }
  };

  return (
    <SafeAreaView style={[t.flex1, t.bgWhite]} edges={['bottom']}>
      <KeyboardAvoidingView>
        <ScrollView contentContainerStyle={[t.flex, t.flex1, t.flexCol, t.justifyCenter]}>
          <Box style={[t.p6]}>
            <Heading size="md" style={[t.textCenter, t.mY6, t.textGray800]}>
              {i18n.t('twoStepAuth')}
            </Heading>
            <Text style={[t.textCenter, t.mB6, t.textGray800]}>
              {i18n.t('codeDescription')} <Text style={{fontWeight: 'bold'}}>{mfaDestination}</Text>
            </Text>
            <VStack space={3}>
              <FormControl isRequired isInvalid={'code' in errors}>
                <Controller
                  name="code"
                  control={control}
                  defaultValue=""
                  rules={{
                    required: i18n.t('codeRequired')
                  }}
                  render={({field: {onChange, onBlur, value}}) => (
                    <Input
                      autoFocus
                      value={value}
                      onBlur={onBlur}
                      autoCorrect={false}
                      autoCapitalize="none"
                      onChangeText={(text) => onChange(text)}
                      onSubmitEditing={() => handleSubmit(onSubmit)()}
                      placeholder={i18n.t('verificationCode')}
                      textContentType="oneTimeCode"
                      keyboardType="number-pad"
                      returnKeyType="done"
                    />
                  )}
                />
                <FormControl.ErrorMessage leftIcon={<WarningOutlineIcon size="xs" />}>
                  {errors?.code?.message}
                </FormControl.ErrorMessage>
              </FormControl>
              <Controller
                name="remember"
                defaultValue={true}
                control={control}
                render={({field: {onChange, value}}) => (
                  <Box style={[t.flex, t.flexRow]}>
                    <Checkbox
                      aria-label="Save"
                      defaultIsChecked
                      value={value}
                      onChange={onChange}
                      color={colors.purple500}
                      _text={{color: 'black'}}>
                      {i18n.t('remember')}
                    </Checkbox>
                  </Box>
                )}
              />
              <Box alignItems="center" style={[t.p6]}>
                <Button
                  variant="link"
                  onPress={() => navigation.navigate(AuthNav.LogInScreen)}
                  color={t.textPurple500}>
                  {i18n.t('backToLogin')}
                </Button>
              </Box>
            </VStack>
          </Box>
        </ScrollView>
        <Box style={[t.p6]}>
          <Button isLoading={isLoading} onPress={handleSubmit(onSubmit)} style={[t.rounded]}>
            {i18n.t('confirm')}
          </Button>
        </Box>
      </KeyboardAvoidingView>
    </SafeAreaView>
  );
};
