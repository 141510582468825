import {NativeStackScreenProps} from '@react-navigation/native-stack';
import {Box, Button} from 'native-base';
import React, {useMemo, useRef, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {TextInput} from 'react-native';
import {ScrollView} from 'react-native-gesture-handler';
import {SafeAreaView} from 'react-native-safe-area-context';
import {t} from 'react-native-tailwindcss';
import {RefundReason, useChargeQuery, useRefundPaymentMutation} from '../api/generated';
import {AmountInput} from '../components/AmountInput';
import {KeyboardAvoidingView} from '../components/KeyboardAvoidingView';
import {formatMoney} from '../lib/formatters';
import {triggerHapticFeedback} from '../lib/hapticFeedback';
import {Nav, NavParams} from './navigation';

export const RefundScreen: React.FC<NativeStackScreenProps<NavParams, Nav.RefundScreen>> = ({
  navigation,
  route
}) => {
  const i18n = useTranslation();
  const {paymentId} = route.params;
  const {data} = useChargeQuery({variables: {id: paymentId}});
  const [refundPayment, {loading: refundLoading}] = useRefundPaymentMutation();
  const scrollRef = useRef<ScrollView>(null);
  const inputRef = useRef<TextInput>(null);

  const maxAmountToRefund = useMemo(() => {
    const alreadyRefunded = data?.charge?.refundedAmount ?? 0;
    const totalAmount = data?.charge?.amount ?? 0;
    return totalAmount - alreadyRefunded;
  }, [data?.charge?.amount, data?.charge?.refundedAmount]);

  const [amount, setAmount] = useState(maxAmountToRefund);

  const payment = data?.charge;

  const adjustAmount = () => {
    if (amount === maxAmountToRefund) {
      setAmount(0);
      inputRef.current?.focus();
    } else {
      setAmount(maxAmountToRefund);
      inputRef.current?.blur();
    }
  };

  const handleRefund = async () => {
    if (!payment) return;
    if (amount > maxAmountToRefund) return;
    await refundPayment({
      variables: {
        input: {
          paymentId,
          amount,
          refundReason: RefundReason.REQUESTED_BY_CUSTOMER
        }
      },
      update(cache, {data}) {
        if (!data?.refundPayment) return;
        const payment = data.refundPayment;
        cache.modify({
          id: cache.identify({...payment, __typename: 'Charge'}),
          fields: {
            status: () => payment.status,
            statusCode: () => payment.statusCode,
            statusMessage: () => payment.statusMessage,
            refundedAmount: () => payment.refundedAmount
          }
        });
      }
    });
    triggerHapticFeedback();
    navigation.goBack();
  };

  const isValid = amount > 0 && amount <= maxAmountToRefund;
  const chargeAmount = data?.charge?.amount;
  const currency = data?.charge?.currency;

  if (!chargeAmount || !currency) {
    return null;
  }

  return (
    <SafeAreaView style={[t.flex1, t.bgWhite]} edges={['bottom']}>
      <KeyboardAvoidingView offset={125}>
        <ScrollView
          style={[t.flex1]}
          contentContainerStyle={[t.flexGrow]}
          keyboardDismissMode="interactive"
          ref={scrollRef}>
          <Box style={[t.flex1, t.justifyCenter]}>
            <AmountInput
              ref={inputRef}
              amount={amount}
              onError={() => scrollRef.current?.scrollToEnd()}
              onAmountChange={setAmount}
              onSubmit={handleRefund}
              maxAmount={maxAmountToRefund}
              hint={i18n.t('refundMessage')}
              getMaxAmountErrorMessage={(maxAmountString) =>
                i18n.t('maxRefundError', {amount: maxAmountString})
              }
            />
          </Box>
        </ScrollView>
        <Button style={[t.mX4]} variant="ghost" onPress={adjustAmount}>
          {amount === maxAmountToRefund ? i18n.t('partialRefund') : i18n.t('fullRefund')}
        </Button>
        <Button
          style={[t.m4, t.mT4]}
          isDisabled={!isValid}
          onPress={handleRefund}
          isLoading={refundLoading}>
          {`${i18n.t('refund')} ${formatMoney(amount / 100, currency)}`}
        </Button>
      </KeyboardAvoidingView>
    </SafeAreaView>
  );
};
