import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
const defaultOptions =  {}
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  AWSEmail: any;
  AWSIPAddress: any;
  AWSPhone: any;
  AWSTimestamp: any;
  Long: any;
};

export type Account = {
  __typename?: 'Account';
  adminEmail?: Maybe<Scalars['String']>;
  allowCreateApiKey?: Maybe<Scalars['Boolean']>;
  apiKey?: Maybe<Scalars['String']>;
  billingPlan?: Maybe<BillingPlans>;
  branding?: Maybe<AccountBranding>;
  business?: Maybe<BusinessData>;
  contract?: Maybe<AccountContract>;
  customDomain?: Maybe<Scalars['String']>;
  customDomainConfig?: Maybe<CustomDomainConfig>;
  defaultCurrency?: Maybe<Currencies>;
  iban?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  language?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  notifications?: Maybe<AccountNotifications>;
  paymentMethods?: Maybe<AccountPaymentMethods>;
  payoutTerms?: Maybe<AccountPayoutTerms>;
  promoCode?: Maybe<Scalars['String']>;
  publicBusinessDetails?: Maybe<AccountPublicBusinessDetails>;
  reason?: Maybe<Scalars['String']>;
  referralCode?: Maybe<Scalars['String']>;
  representatives?: Maybe<Array<Representative>>;
  status: AccountStatus;
  test?: Maybe<Scalars['Boolean']>;
  timezone?: Maybe<Scalars['String']>;
  tokenizationMethods?: Maybe<AccountTokenizationMethods>;
};

export type AccountBranding = {
  __typename?: 'AccountBranding';
  accentColor?: Maybe<Scalars['String']>;
  bgColor?: Maybe<Scalars['String']>;
  iconUrl?: Maybe<Scalars['String']>;
  logoUrl?: Maybe<Scalars['String']>;
};

export type AccountBrandingInput = {
  accentColor?: InputMaybe<Scalars['String']>;
  bgColor?: InputMaybe<Scalars['String']>;
  iconUrl?: InputMaybe<Scalars['String']>;
  logoUrl?: InputMaybe<Scalars['String']>;
};

export type AccountChange = {
  __typename?: 'AccountChange';
  accountId?: Maybe<Scalars['ID']>;
};

export type AccountContract = {
  __typename?: 'AccountContract';
  envelopeId: Scalars['String'];
  path?: Maybe<Scalars['String']>;
  signedDate?: Maybe<Scalars['String']>;
  status: ContractStatus;
};

export type AccountInvoice = {
  __typename?: 'AccountInvoice';
  accountId: Scalars['ID'];
  amount: Scalars['Int'];
  business?: Maybe<AccountInvoiceBusiness>;
  createdAt: Scalars['Int'];
  currency: Currencies;
  id: Scalars['ID'];
  lineItems: Array<AccountInvoiceLineItem>;
  period: Period;
  referralCode?: Maybe<Scalars['String']>;
  tax: Scalars['Int'];
  totalAmount: Scalars['Int'];
};

export type AccountInvoiceBusiness = {
  __typename?: 'AccountInvoiceBusiness';
  documentNumber?: Maybe<Scalars['String']>;
  iban?: Maybe<Scalars['String']>;
  legalName?: Maybe<Scalars['String']>;
  mandateSignatureTimestamp?: Maybe<Scalars['Int']>;
};

export type AccountInvoiceLineItem = {
  __typename?: 'AccountInvoiceLineItem';
  amount: Scalars['Int'];
  currency: Currencies;
  name: Scalars['String'];
  quantity: Scalars['Int'];
  unitAmount: Scalars['Float'];
  unitType: UnitType;
};

export type AccountInvoicesConnection = {
  __typename?: 'AccountInvoicesConnection';
  items: Array<AccountInvoice>;
  total: Scalars['Int'];
};

export type AccountNotifications = {
  __typename?: 'AccountNotifications';
  emailCustomerOnRefund?: Maybe<Scalars['Boolean']>;
  emailCustomerOnSuccess?: Maybe<Scalars['Boolean']>;
};

export type AccountNotificationsInput = {
  emailCustomerOnRefund?: InputMaybe<Scalars['Boolean']>;
  emailCustomerOnSuccess?: InputMaybe<Scalars['Boolean']>;
};

export type AccountPaymentMethods = {
  __typename?: 'AccountPaymentMethods';
  BIZUM?: Maybe<AccountPaymentMethodsBizum>;
  CREDITCARD?: Maybe<AccountPaymentMethodsCreditCard>;
};

export type AccountPaymentMethodsBizum = {
  __typename?: 'AccountPaymentMethodsBizum';
  enabled?: Maybe<Scalars['Boolean']>;
};

export type AccountPaymentMethodsCreditCard = {
  __typename?: 'AccountPaymentMethodsCreditCard';
  enabled?: Maybe<Scalars['Boolean']>;
  preauthorization?: Maybe<Scalars['Boolean']>;
};

export type AccountPayoutTerms = {
  __typename?: 'AccountPayoutTerms';
  firstPeriod?: Maybe<Scalars['Int']>;
  iban?: Maybe<Scalars['String']>;
  period?: Maybe<Scalars['Int']>;
  reservePercent?: Maybe<Scalars['Float']>;
  suspended?: Maybe<Scalars['Boolean']>;
};

export type AccountPublicBusinessDetails = {
  __typename?: 'AccountPublicBusinessDetails';
  address?: Maybe<Address>;
  companyName?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  supportEmail?: Maybe<Scalars['String']>;
  supportPhone?: Maybe<Scalars['String']>;
  supportWebsite?: Maybe<Scalars['String']>;
  website?: Maybe<Scalars['String']>;
};

export type AccountPublicBusinessDetailsInput = {
  address?: InputMaybe<AddressInput>;
  companyName?: InputMaybe<Scalars['String']>;
  phone?: InputMaybe<Scalars['String']>;
  supportEmail?: InputMaybe<Scalars['String']>;
  supportPhone?: InputMaybe<Scalars['String']>;
  supportWebsite?: InputMaybe<Scalars['String']>;
  website?: InputMaybe<Scalars['String']>;
};

export type AccountSettlement = {
  __typename?: 'AccountSettlement';
  accountId: Scalars['ID'];
  acquirerFees: Scalars['Int'];
  grossVolume: Scalars['Int'];
  iban?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  netVolume: Scalars['Int'];
  payMethod?: Maybe<SettlementPayMethods>;
  periodEndAt: Scalars['AWSTimestamp'];
  periodStartAt: Scalars['AWSTimestamp'];
  releasedAmount?: Maybe<Scalars['Int']>;
  reservedAmount?: Maybe<Scalars['Int']>;
  scheduledAt: Scalars['AWSTimestamp'];
  status: SettlementStatus;
};

export type AccountSettlementsConnection = {
  __typename?: 'AccountSettlementsConnection';
  items: Array<AccountSettlement>;
  total: Scalars['Int'];
};

export enum AccountStatus {
  ACTIVE = 'ACTIVE',
  APPROVED = 'APPROVED',
  DELETED = 'DELETED',
  NOT_CONFIGURED = 'NOT_CONFIGURED',
  PENDING_APPROVAL = 'PENDING_APPROVAL',
  PENDING_CONTRACT = 'PENDING_CONTRACT',
  REJECTED = 'REJECTED',
  SUSPENDED = 'SUSPENDED'
}

export type AccountTokenizationMethods = {
  __typename?: 'AccountTokenizationMethods';
  applePay?: Maybe<AccountTokenizationMethodsItem>;
  clickToPay?: Maybe<AccountTokenizationMethodsClickToPayItem>;
  googlePay?: Maybe<AccountTokenizationMethodsItem>;
};

export type AccountTokenizationMethodsClickToPayItem = {
  __typename?: 'AccountTokenizationMethodsClickToPayItem';
  dpaId?: Maybe<Scalars['String']>;
  enabled?: Maybe<Scalars['Boolean']>;
};

export type AccountTokenizationMethodsItem = {
  __typename?: 'AccountTokenizationMethodsItem';
  enabled?: Maybe<Scalars['Boolean']>;
};

export enum Acquirer {
  ABANCA = 'ABANCA',
  ADYEN = 'ADYEN',
  BANCAMARCH = 'BANCAMARCH',
  BANKIA = 'BANKIA',
  BANKINTER = 'BANKINTER',
  BBVA = 'BBVA',
  CAJALABORAL = 'CAJALABORAL',
  CAJAMAR = 'CAJAMAR',
  CAJARURAL = 'CAJARURAL',
  CAJASUR = 'CAJASUR',
  COFIDIS = 'COFIDIS',
  COMERCIAGLOBALPAY = 'COMERCIAGLOBALPAY',
  CREDITANDORRA = 'CREDITANDORRA',
  CUSTOM = 'CUSTOM',
  DEUTSCHE_BANK = 'DEUTSCHE_BANK',
  KUTXABANK = 'KUTXABANK',
  MONEI_X = 'MONEI_X',
  PAYCOMET = 'PAYCOMET',
  PAYPAL = 'PAYPAL',
  SABADELL = 'SABADELL',
  SANTANDER = 'SANTANDER',
  STRIPE = 'STRIPE',
  UNICAJA = 'UNICAJA'
}

export type ActivateAccount = {
  __typename?: 'ActivateAccount';
  contractUrl?: Maybe<Scalars['String']>;
};

export type ActivateSubscriptionInput = {
  addAmount?: InputMaybe<Scalars['Int']>;
  cancelUrl?: InputMaybe<Scalars['String']>;
  completeUrl?: InputMaybe<Scalars['String']>;
  failUrl?: InputMaybe<Scalars['String']>;
  paymentToken?: InputMaybe<Scalars['String']>;
  sequenceId?: InputMaybe<Scalars['String']>;
  sessionId?: InputMaybe<Scalars['String']>;
  subscriptionId: Scalars['String'];
};

export type Address = {
  __typename?: 'Address';
  city?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  line1?: Maybe<Scalars['String']>;
  line2?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
  zip?: Maybe<Scalars['String']>;
};

export type AddressInput = {
  city?: InputMaybe<Scalars['String']>;
  country?: InputMaybe<Scalars['String']>;
  line1?: InputMaybe<Scalars['String']>;
  line2?: InputMaybe<Scalars['String']>;
  state?: InputMaybe<Scalars['String']>;
  zip?: InputMaybe<Scalars['String']>;
};

export type AnalyticsChange = {
  __typename?: 'AnalyticsChange';
  accountId?: Maybe<Scalars['ID']>;
};

export type AuthorizedUser = {
  __typename?: 'AuthorizedUser';
  accessLevel: AuthorizedUserAccessLevel;
  createdAt: Scalars['String'];
  id: Scalars['ID'];
  storeId?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['String']>;
  userEmail: Scalars['String'];
};

export enum AuthorizedUserAccessLevel {
  ADMIN_ACCESS = 'ADMIN_ACCESS',
  READ_ACCESS = 'READ_ACCESS',
  WRITE_ACCESS = 'WRITE_ACCESS'
}

export type AuthorizedUsersConnection = {
  __typename?: 'AuthorizedUsersConnection';
  items: Array<AuthorizedUser>;
  nextToken?: Maybe<Scalars['String']>;
};

export type AvailablePaymentMethod = {
  __typename?: 'AvailablePaymentMethod';
  configured?: Maybe<Scalars['Boolean']>;
  enabled?: Maybe<Scalars['Boolean']>;
  paymentMethod?: Maybe<PaymentMethods>;
};

export type AvailableProcessor = {
  __typename?: 'AvailableProcessor';
  configurable?: Maybe<Scalars['Boolean']>;
  configured?: Maybe<Scalars['Boolean']>;
  enabled?: Maybe<Scalars['Boolean']>;
  paymentMethod?: Maybe<PaymentMethods>;
  processor?: Maybe<Processor>;
};

export enum BillingPlans {
  MONEI_PLUS = 'MONEI_PLUS',
  MONEI_POS = 'MONEI_POS',
  MONEI_X = 'MONEI_X'
}

export type BusinessData = {
  __typename?: 'BusinessData';
  address?: Maybe<Address>;
  companyName?: Maybe<Scalars['String']>;
  confirmServicesDescription?: Maybe<Scalars['Boolean']>;
  confirmWebsite?: Maybe<Scalars['Boolean']>;
  country?: Maybe<Scalars['String']>;
  documentNumber?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  industry?: Maybe<Industries>;
  legalForm?: Maybe<LegalForm>;
  legalName?: Maybe<Scalars['String']>;
  mcc?: Maybe<Scalars['String']>;
  monthlyVolume?: Maybe<Scalars['Long']>;
  phone?: Maybe<Scalars['String']>;
  servicesDescription?: Maybe<Scalars['String']>;
  status?: Maybe<BusinessStatus>;
  website?: Maybe<Scalars['String']>;
};

export type BusinessDataInput = {
  address?: InputMaybe<AddressInput>;
  companyName?: InputMaybe<Scalars['String']>;
  confirmServicesDescription?: InputMaybe<Scalars['Boolean']>;
  confirmWebsite?: InputMaybe<Scalars['Boolean']>;
  country?: InputMaybe<Scalars['String']>;
  creationDate?: InputMaybe<Scalars['String']>;
  documentNumber?: InputMaybe<Scalars['String']>;
  email?: InputMaybe<Scalars['String']>;
  industry?: InputMaybe<Industries>;
  legalForm: LegalForm;
  legalName?: InputMaybe<Scalars['String']>;
  mcc?: InputMaybe<Scalars['String']>;
  monthlyVolume?: InputMaybe<Scalars['Int']>;
  phone?: InputMaybe<Scalars['String']>;
  servicesDescription?: InputMaybe<Scalars['String']>;
  website?: InputMaybe<Scalars['String']>;
};

export enum BusinessStatus {
  ACTIVE = 'active',
  DISSOLVED = 'dissolved',
  INACTIVE = 'inactive',
  RENAMED = 'renamed',
  RESUBMISSION_REQUESTED = 'resubmission_requested',
  TRANSFORMED = 'transformed'
}

export type CancelPaymentInput = {
  cancellationReason?: InputMaybe<CancellationReason>;
  paymentId: Scalars['ID'];
};

export type CancelSubscriptionInput = {
  cancelAtPeriodEnd?: InputMaybe<Scalars['Boolean']>;
  subscriptionId: Scalars['String'];
};

export enum CancellationReason {
  DUPLICATED = 'duplicated',
  FRAUDULENT = 'fraudulent',
  REQUESTED_BY_CUSTOMER = 'requested_by_customer'
}

export type CapturePaymentInput = {
  amount?: InputMaybe<Scalars['Int']>;
  paymentId: Scalars['ID'];
};

export enum CardBrand {
  AMEX = 'amex',
  DINERS = 'diners',
  DISCOVER = 'discover',
  JCB = 'jcb',
  MASTERCARD = 'mastercard',
  UNIONPAY = 'unionpay',
  UNKNOWN = 'unknown',
  VISA = 'visa'
}

export enum CardType {
  CREDIT = 'credit',
  DEBIT = 'debit'
}

export type Charge = {
  __typename?: 'Charge';
  accountId: Scalars['ID'];
  amount?: Maybe<Scalars['Int']>;
  authorizationCode?: Maybe<Scalars['String']>;
  billingDetails?: Maybe<ContactDetails>;
  billingPlan?: Maybe<BillingPlans>;
  cancellationReason?: Maybe<CancellationReason>;
  checkoutId: Scalars['ID'];
  createdAt?: Maybe<Scalars['AWSTimestamp']>;
  currency: Scalars['String'];
  customer?: Maybe<Customer>;
  description?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  lastRefundAmount?: Maybe<Scalars['Int']>;
  lastRefundReason?: Maybe<RefundReason>;
  livemode?: Maybe<Scalars['Boolean']>;
  masterAccountId?: Maybe<Scalars['String']>;
  orderId?: Maybe<Scalars['String']>;
  pageOpenedAt?: Maybe<Scalars['AWSTimestamp']>;
  paymentMethod?: Maybe<PaymentMethod>;
  pointOfSaleId?: Maybe<Scalars['String']>;
  providerId?: Maybe<Scalars['ID']>;
  providerInternalId?: Maybe<Scalars['String']>;
  providerReferenceId?: Maybe<Scalars['String']>;
  refundedAmount?: Maybe<Scalars['Int']>;
  sequenceId?: Maybe<Scalars['String']>;
  sessionDetails?: Maybe<SessionDetails>;
  shippingDetails?: Maybe<ContactDetails>;
  shop?: Maybe<Shop>;
  status: ChargeStatus;
  statusCode?: Maybe<Scalars['String']>;
  statusMessage?: Maybe<Scalars['String']>;
  storeId?: Maybe<Scalars['String']>;
  subscriptionId?: Maybe<Scalars['String']>;
  traceDetails?: Maybe<TraceDetails>;
  updatedAt?: Maybe<Scalars['AWSTimestamp']>;
};

export type ChargeEvent = {
  __typename?: 'ChargeEvent';
  accountId: Scalars['ID'];
  createdAt?: Maybe<Scalars['AWSTimestamp']>;
  id: Scalars['ID'];
  livemode?: Maybe<Scalars['Boolean']>;
  object: ChargeEventObject;
  objectId: Scalars['ID'];
  objectType: Scalars['String'];
  type: Scalars['String'];
};

export type ChargeEventObject = {
  __typename?: 'ChargeEventObject';
  accountId: Scalars['ID'];
  amount?: Maybe<Scalars['Int']>;
  authorizationCode?: Maybe<Scalars['String']>;
  billingDetails?: Maybe<ContactDetails>;
  billingPlan?: Maybe<BillingPlans>;
  cancellationReason?: Maybe<CancellationReason>;
  checkoutId: Scalars['ID'];
  createdAt?: Maybe<Scalars['AWSTimestamp']>;
  currency: Scalars['String'];
  customer?: Maybe<Customer>;
  description?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  lastRefundAmount?: Maybe<Scalars['Int']>;
  lastRefundReason?: Maybe<RefundReason>;
  livemode?: Maybe<Scalars['Boolean']>;
  masterAccountId?: Maybe<Scalars['String']>;
  orderId?: Maybe<Scalars['String']>;
  pageOpenedAt?: Maybe<Scalars['AWSTimestamp']>;
  paymentMethod?: Maybe<PaymentMethod>;
  pointOfSaleId?: Maybe<Scalars['String']>;
  providerId?: Maybe<Scalars['ID']>;
  providerInternalId?: Maybe<Scalars['String']>;
  providerReferenceId?: Maybe<Scalars['String']>;
  refundedAmount?: Maybe<Scalars['Int']>;
  sequenceId?: Maybe<Scalars['String']>;
  sessionDetails?: Maybe<SessionDetails>;
  shippingDetails?: Maybe<ContactDetails>;
  shop?: Maybe<Shop>;
  status: ChargeStatus;
  statusCode?: Maybe<Scalars['String']>;
  statusMessage?: Maybe<Scalars['String']>;
  storeId?: Maybe<Scalars['String']>;
  subscriptionId?: Maybe<Scalars['String']>;
  traceDetails?: Maybe<TraceDetails>;
  updatedAt?: Maybe<Scalars['AWSTimestamp']>;
};

export type ChargeEventsChange = {
  __typename?: 'ChargeEventsChange';
  accountId?: Maybe<Scalars['ID']>;
  id?: Maybe<Scalars['String']>;
};

export type ChargeEventsChangeInput = {
  id: Scalars['String'];
};

export type ChargeEventsConnection = {
  __typename?: 'ChargeEventsConnection';
  items: Array<ChargeEvent>;
  total: Scalars['Int'];
};

export enum ChargeStatus {
  AUTHORIZED = 'AUTHORIZED',
  CANCELED = 'CANCELED',
  EXPIRED = 'EXPIRED',
  FAILED = 'FAILED',
  PARTIALLY_REFUNDED = 'PARTIALLY_REFUNDED',
  PENDING = 'PENDING',
  PENDING_PROCESSING = 'PENDING_PROCESSING',
  REFUNDED = 'REFUNDED',
  SUCCEEDED = 'SUCCEEDED'
}

export type ChargesChange = {
  __typename?: 'ChargesChange';
  accountId?: Maybe<Scalars['ID']>;
};

export type ChargesConnection = {
  __typename?: 'ChargesConnection';
  items: Array<Charge>;
  total: Scalars['Int'];
};

export type ChargesDateRangeKpi = {
  __typename?: 'ChargesDateRangeKPI';
  currency: Scalars['String'];
  data: Array<ChargesDateRangeKpiRow>;
  total: ChargesDateRangeKpiTotal;
};

export type ChargesDateRangeKpiRow = {
  __typename?: 'ChargesDateRangeKPIRow';
  canceledAmount: Scalars['Long'];
  canceledCount: Scalars['Long'];
  capturedAmount: Scalars['Long'];
  capturedCount: Scalars['Long'];
  cuCapturedAmount: Scalars['Long'];
  cuCapturedCount: Scalars['Long'];
  cuDirectAmount: Scalars['Long'];
  cuDirectCount: Scalars['Long'];
  cuSucceededAmount: Scalars['Long'];
  cuSucceededCount: Scalars['Long'];
  directAmount: Scalars['Long'];
  directCount: Scalars['Long'];
  failedAmount: Scalars['Long'];
  failedCount: Scalars['Long'];
  refundedAmount: Scalars['Long'];
  refundedCount: Scalars['Long'];
  succeededAmount: Scalars['Long'];
  succeededCount: Scalars['Long'];
  timestamp: Scalars['AWSTimestamp'];
};

export type ChargesDateRangeKpiTotal = {
  __typename?: 'ChargesDateRangeKPITotal';
  canceledAmount: Scalars['Long'];
  canceledCount: Scalars['Long'];
  capturedAmount: Scalars['Long'];
  capturedCount: Scalars['Long'];
  directAmount: Scalars['Long'];
  directCount: Scalars['Long'];
  failedAmount: Scalars['Long'];
  failedCount: Scalars['Long'];
  refundedAmount: Scalars['Long'];
  refundedCount: Scalars['Long'];
  succeededAmount: Scalars['Long'];
  succeededCount: Scalars['Long'];
};

export type CheckoutEventObject = {
  __typename?: 'CheckoutEventObject';
  amount?: Maybe<Scalars['Int']>;
  authorizationCode?: Maybe<Scalars['String']>;
  billingDetails?: Maybe<ContactDetails>;
  checkoutId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['AWSTimestamp']>;
  currency?: Maybe<Scalars['String']>;
  customer?: Maybe<Customer>;
  description?: Maybe<Scalars['String']>;
  id: Scalars['String'];
  orderId?: Maybe<Scalars['String']>;
  paymentMethod?: Maybe<PaymentMethod>;
  pointOfSaleId?: Maybe<Scalars['String']>;
  providerId?: Maybe<Scalars['String']>;
  providerInternalId?: Maybe<Scalars['String']>;
  providerReferenceId?: Maybe<Scalars['String']>;
  refundedAmount?: Maybe<Scalars['Int']>;
  sequenceId?: Maybe<Scalars['String']>;
  sessionDetails?: Maybe<SessionDetails>;
  shippingDetails?: Maybe<ContactDetails>;
  shop?: Maybe<Shop>;
  statusCode?: Maybe<Scalars['String']>;
  statusMessage?: Maybe<Scalars['String']>;
  storeId?: Maybe<Scalars['String']>;
  traceDetails?: Maybe<TraceDetails>;
  transactionId?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['AWSTimestamp']>;
};

export type Code = {
  __typename?: 'Code';
  enabled?: Maybe<Scalars['Boolean']>;
  id: Scalars['ID'];
  metadata?: Maybe<Array<MetadataItem>>;
  type: CodeTypes;
};

export type CodeConfig = {
  __typename?: 'CodeConfig';
  metadataConfig?: Maybe<Array<MetadataConfigItem>>;
};

export enum CodeTypes {
  POINT_OF_SALE = 'POINT_OF_SALE'
}

export type ContactDetails = {
  __typename?: 'ContactDetails';
  address?: Maybe<Address>;
  company?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
};

export type ContactDetailsInput = {
  address?: InputMaybe<AddressInput>;
  company?: InputMaybe<Scalars['String']>;
  email?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  phone?: InputMaybe<Scalars['String']>;
};

export enum ContractStatus {
  COMPLETED = 'completed',
  CREATED = 'created',
  DECLINED = 'declined',
  DELIVERED = 'delivered',
  SENT = 'sent',
  SIGNED = 'signed',
  VOIDED = 'voided'
}

export type Countries = {
  __typename?: 'Countries';
  currentCountry?: Maybe<Country>;
  currentCountryCode?: Maybe<Scalars['String']>;
  isSupported: Scalars['Boolean'];
  supportedCountries: Array<Country>;
};

export type Country = {
  __typename?: 'Country';
  code: Scalars['String'];
  name: Scalars['String'];
  phoneCode: Scalars['String'];
};

export type CreateAuthorizedUserInput = {
  accessLevel?: InputMaybe<AuthorizedUserAccessLevel>;
  storeId?: InputMaybe<Scalars['String']>;
  userEmail: Scalars['String'];
};

export type CreatePaymentInput = {
  allowedPaymentMethods?: InputMaybe<Array<InputMaybe<PaymentMethods>>>;
  amount: Scalars['Int'];
  billingDetails?: InputMaybe<ContactDetailsInput>;
  callbackUrl?: InputMaybe<Scalars['String']>;
  cancelUrl?: InputMaybe<Scalars['String']>;
  completeUrl?: InputMaybe<Scalars['String']>;
  currency?: InputMaybe<Currencies>;
  customer?: InputMaybe<CustomerInput>;
  description?: InputMaybe<Scalars['String']>;
  expireAt?: InputMaybe<Scalars['AWSTimestamp']>;
  failUrl?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['ID']>;
  orderId?: InputMaybe<Scalars['String']>;
  pointOfSaleId?: InputMaybe<Scalars['String']>;
  shippingDetails?: InputMaybe<ContactDetailsInput>;
  storeId?: InputMaybe<Scalars['String']>;
  transactionType?: InputMaybe<TransactionTypes>;
};

export type CreatePointOfSaleInput = {
  manualAmount?: InputMaybe<Scalars['Boolean']>;
  name?: InputMaybe<Scalars['String']>;
  storeId: Scalars['ID'];
};

export type CreateProviderInput = {
  acquirer: Acquirer;
  enabled?: InputMaybe<Scalars['Boolean']>;
  metadata?: InputMaybe<Array<MetadataItemInput>>;
  paymentMethod: PaymentMethods;
  processor: Processor;
  rules?: InputMaybe<ProviderRulesInput>;
};

export type CreateStoreInput = {
  address?: InputMaybe<AddressInput>;
  email?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  phone?: InputMaybe<Scalars['String']>;
  website?: InputMaybe<Scalars['String']>;
};

export type CreateSubscriptionInput = {
  amount: Scalars['Int'];
  billingDetails?: InputMaybe<ContactDetailsInput>;
  callbackUrl?: InputMaybe<Scalars['String']>;
  currency?: InputMaybe<Currencies>;
  customer?: InputMaybe<CustomerInput>;
  description?: InputMaybe<Scalars['String']>;
  interval: Interval;
  intervalCount: Scalars['Int'];
  masterAccountId?: InputMaybe<Scalars['String']>;
  paymentCallbackUrl?: InputMaybe<Scalars['String']>;
  shippingDetails?: InputMaybe<ContactDetailsInput>;
  storeId?: InputMaybe<Scalars['String']>;
  trialPeriodDays?: InputMaybe<Scalars['Int']>;
  trialPeriodEnd?: InputMaybe<Scalars['AWSTimestamp']>;
};

export enum Currencies {
  CHF = 'CHF',
  EUR = 'EUR',
  GBP = 'GBP',
  JPY = 'JPY',
  USD = 'USD'
}

export type CustomDomainConfig = {
  __typename?: 'CustomDomainConfig';
  certificateDnsRecord?: Maybe<DnsRecord>;
  distributionDnsRecord?: Maybe<DnsRecord>;
  domain?: Maybe<Scalars['String']>;
  status?: Maybe<CustomDomainStatus>;
};

export enum CustomDomainStatus {
  FAILED = 'FAILED',
  PENDING_DELETION = 'PENDING_DELETION',
  PENDING_VALIDATION = 'PENDING_VALIDATION',
  SUCCESS = 'SUCCESS'
}

export type Customer = {
  __typename?: 'Customer';
  email?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
};

export type CustomerInput = {
  email?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  phone?: InputMaybe<Scalars['String']>;
};

export enum DeviceType {
  DESKTOP = 'desktop',
  MOBILE = 'mobile',
  SMARTTV = 'smartTV',
  TABLET = 'tablet'
}

export type DnsRecord = {
  __typename?: 'DnsRecord';
  name?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
};

export type DownloadFile = {
  __typename?: 'DownloadFile';
  downloadUrl: Scalars['String'];
  expiresAt: Scalars['AWSTimestamp'];
};

export type EventsChange = {
  __typename?: 'EventsChange';
  accountId?: Maybe<Scalars['ID']>;
};

export type ExportCharges = {
  __typename?: 'ExportCharges';
  downloadUrl: Scalars['String'];
  expiresAt: Scalars['AWSTimestamp'];
};

export type IdSession = {
  __typename?: 'IdSession';
  sessionUrl: Scalars['String'];
};

export enum IdVerificationStatus {
  ABANDONED = 'abandoned',
  APPROVED = 'approved',
  DECLINED = 'declined',
  EXPIRED = 'expired',
  RESUBMISSION_REQUESTED = 'resubmission_requested',
  SUBMITTED = 'submitted'
}

export enum Industries {
  ECOMMERCE = 'ECOMMERCE'
}

export enum Interval {
  DAY = 'day',
  HOUR = 'hour',
  MONTH = 'month',
  QUARTER = 'quarter',
  WEEK = 'week',
  YEAR = 'year'
}

export type LastPayment = {
  __typename?: 'LastPayment';
  id: Scalars['ID'];
  status: PaymentStatus;
  statusCode?: Maybe<Scalars['String']>;
  statusMessage?: Maybe<Scalars['String']>;
};

export enum LegalForm {
  COMPANY = 'COMPANY',
  FREELANCE = 'FREELANCE',
  INDIVIDUAL = 'INDIVIDUAL'
}

export enum MessageChannel {
  EMAIL = 'EMAIL',
  SMS = 'SMS'
}

export type MetadataConfigItem = {
  __typename?: 'MetadataConfigItem';
  default?: Maybe<Scalars['String']>;
  example?: Maybe<Scalars['String']>;
  internal?: Maybe<Scalars['Boolean']>;
  key: Scalars['String'];
  label?: Maybe<Scalars['String']>;
  possibleValues?: Maybe<Array<Scalars['String']>>;
  regex?: Maybe<Scalars['String']>;
  required?: Maybe<Scalars['Boolean']>;
  secret?: Maybe<Scalars['Boolean']>;
  type: MetadataItemType;
};

export type MetadataItem = {
  __typename?: 'MetadataItem';
  default?: Maybe<Scalars['String']>;
  example?: Maybe<Scalars['String']>;
  internal?: Maybe<Scalars['Boolean']>;
  key: Scalars['String'];
  label?: Maybe<Scalars['String']>;
  possibleValues?: Maybe<Array<Scalars['String']>>;
  regex?: Maybe<Scalars['String']>;
  required?: Maybe<Scalars['Boolean']>;
  secret?: Maybe<Scalars['Boolean']>;
  type: MetadataItemType;
  value?: Maybe<Scalars['String']>;
};

export type MetadataItemInput = {
  key: Scalars['String'];
  value?: InputMaybe<Scalars['String']>;
};

export enum MetadataItemType {
  BOOLEAN = 'BOOLEAN',
  NUMBER = 'NUMBER',
  NUMBER_ARRAY = 'NUMBER_ARRAY',
  STRING = 'STRING',
  STRING_ARRAY = 'STRING_ARRAY'
}

export type Mutation = {
  __typename?: 'Mutation';
  activateAccount: ActivateAccount;
  activateSubscription?: Maybe<Payment>;
  cancelPayment?: Maybe<Payment>;
  cancelSubscription: Subscription;
  capturePayment?: Maybe<Payment>;
  createAccount: Scalars['Boolean'];
  createApiKey: Scalars['String'];
  createAuthorizedUser?: Maybe<AuthorizedUser>;
  createIdSession: IdSession;
  createPayPalLink: PayPalLink;
  createPayment?: Maybe<Payment>;
  createPointOfSale: PointOfSale;
  createProvider: Provider;
  createStore: Store;
  createSubscription: Subscription;
  deleteApiKey?: Maybe<Scalars['Boolean']>;
  deleteAuthorizedUser?: Maybe<AuthorizedUser>;
  deleteProvider: Provider;
  deleteStore: Scalars['Boolean'];
  disableCustomDomain: Account;
  disableTokenizationMethod: Account;
  downloadAccountInvoice?: Maybe<DownloadFile>;
  downloadContract?: Maybe<DownloadFile>;
  downloadOperationsFile?: Maybe<DownloadFile>;
  enableCustomDomain: Account;
  enableProvider: Provider;
  enableTokenizationMethod: Account;
  exportCharges: ExportCharges;
  exportChargesOld: ExportCharges;
  pauseSubscription: Subscription;
  publishUpdateAccount?: Maybe<AccountChange>;
  publishUpdateAnalytics?: Maybe<AnalyticsChange>;
  publishUpdateCharge?: Maybe<Charge>;
  publishUpdateChargeEvents?: Maybe<ChargeEventsChange>;
  publishUpdateCharges?: Maybe<ChargesChange>;
  publishUpdateEvents?: Maybe<EventsChange>;
  refundPayment?: Maybe<Payment>;
  registerApplePayDomain: RegisterApplePayDomain;
  registerCode: Code;
  resumeSubscription: Subscription;
  selectUserAccount: Scalars['Boolean'];
  sendPaymentLink?: Maybe<Payment>;
  sendPaymentReceipt?: Maybe<Payment>;
  sendPaymentRequest?: Maybe<Payment>;
  setDefaultCardProvider: Provider;
  setupAccount: Account;
  updateAccount: Account;
  updateAuthorizedUser?: Maybe<AuthorizedUser>;
  updateCode: Code;
  updatePointOfSale: PointOfSale;
  updateProvider: Provider;
  updateStore: Store;
  updateSubscription: Subscription;
  verifyBusinessDetails: Account;
  verifyCustomDomain: Account;
};


export type MutationActivateAccountArgs = {
  language?: InputMaybe<Scalars['String']>;
};


export type MutationActivateSubscriptionArgs = {
  input: ActivateSubscriptionInput;
};


export type MutationCancelPaymentArgs = {
  input: CancelPaymentInput;
};


export type MutationCancelSubscriptionArgs = {
  input: CancelSubscriptionInput;
};


export type MutationCapturePaymentArgs = {
  input: CapturePaymentInput;
};


export type MutationCreateAccountArgs = {
  copyBusinessDetails?: InputMaybe<Scalars['Boolean']>;
  name: Scalars['String'];
};


export type MutationCreateAuthorizedUserArgs = {
  input: CreateAuthorizedUserInput;
};


export type MutationCreateIdSessionArgs = {
  id?: InputMaybe<Scalars['Int']>;
};


export type MutationCreatePaymentArgs = {
  input: CreatePaymentInput;
};


export type MutationCreatePointOfSaleArgs = {
  input: CreatePointOfSaleInput;
};


export type MutationCreateProviderArgs = {
  input: CreateProviderInput;
};


export type MutationCreateStoreArgs = {
  input: CreateStoreInput;
};


export type MutationCreateSubscriptionArgs = {
  input: CreateSubscriptionInput;
};


export type MutationDeleteAuthorizedUserArgs = {
  userEmail: Scalars['String'];
};


export type MutationDeleteProviderArgs = {
  id: Scalars['ID'];
};


export type MutationDeleteStoreArgs = {
  id: Scalars['ID'];
};


export type MutationDisableTokenizationMethodArgs = {
  method: TokenizationMethods;
};


export type MutationDownloadAccountInvoiceArgs = {
  invoiceId: Scalars['ID'];
};


export type MutationDownloadOperationsFileArgs = {
  settlementId: Scalars['ID'];
};


export type MutationEnableCustomDomainArgs = {
  domain: Scalars['String'];
};


export type MutationEnableProviderArgs = {
  enabled: Scalars['Boolean'];
  id: Scalars['ID'];
};


export type MutationEnableTokenizationMethodArgs = {
  method: TokenizationMethods;
};


export type MutationExportChargesArgs = {
  columns?: InputMaybe<Array<Scalars['String']>>;
  filter?: InputMaybe<SearchableChargeFilterInput>;
  timezone?: InputMaybe<Scalars['String']>;
};


export type MutationExportChargesOldArgs = {
  filter?: InputMaybe<SearchableChargeFilterInput>;
  timezone?: InputMaybe<Scalars['String']>;
};


export type MutationPauseSubscriptionArgs = {
  input: PauseSubscriptionInput;
};


export type MutationPublishUpdateAccountArgs = {
  accountId: Scalars['ID'];
};


export type MutationPublishUpdateAnalyticsArgs = {
  accountId: Scalars['ID'];
};


export type MutationPublishUpdateChargeArgs = {
  accountId: Scalars['ID'];
  data: UpdateChargeInput;
};


export type MutationPublishUpdateChargeEventsArgs = {
  accountId: Scalars['ID'];
  data: ChargeEventsChangeInput;
};


export type MutationPublishUpdateChargesArgs = {
  accountId: Scalars['ID'];
};


export type MutationPublishUpdateEventsArgs = {
  accountId: Scalars['ID'];
};


export type MutationRefundPaymentArgs = {
  input: RefundPaymentInput;
};


export type MutationRegisterApplePayDomainArgs = {
  input: RegisterApplePayDomainInput;
};


export type MutationRegisterCodeArgs = {
  input: RegisterCodeInput;
};


export type MutationResumeSubscriptionArgs = {
  input: ResumeSubscriptionInput;
};


export type MutationSelectUserAccountArgs = {
  accountId: Scalars['String'];
};


export type MutationSendPaymentLinkArgs = {
  input: SendPaymentMessageInput;
};


export type MutationSendPaymentReceiptArgs = {
  input: SendPaymentMessageInput;
};


export type MutationSendPaymentRequestArgs = {
  input: SendPaymentRequestInput;
};


export type MutationSetDefaultCardProviderArgs = {
  providerId: Scalars['ID'];
};


export type MutationSetupAccountArgs = {
  input: SetupAccountInput;
};


export type MutationUpdateAccountArgs = {
  input: UpdateAccountInput;
};


export type MutationUpdateAuthorizedUserArgs = {
  input: UpdateAuthorizedUserInput;
};


export type MutationUpdateCodeArgs = {
  input: UpdateCodeInput;
};


export type MutationUpdatePointOfSaleArgs = {
  input: UpdatePointOfSaleInput;
};


export type MutationUpdateProviderArgs = {
  input: UpdateProviderInput;
};


export type MutationUpdateStoreArgs = {
  input: UpdateStoreInput;
};


export type MutationUpdateSubscriptionArgs = {
  input: UpdateSubscriptionInput;
};


export type MutationVerifyBusinessDetailsArgs = {
  input: SetupAccountInput;
};

export type NextAction = {
  __typename?: 'NextAction';
  mustRedirect?: Maybe<Scalars['Boolean']>;
  redirectUrl?: Maybe<Scalars['String']>;
  type?: Maybe<NextActionTypes>;
};

export enum NextActionTypes {
  BIZUM_CHALLENGE = 'BIZUM_CHALLENGE',
  CHALLENGE = 'CHALLENGE',
  COMPLETE = 'COMPLETE',
  CONFIRM = 'CONFIRM',
  FRICTIONLESS_CHALLENGE = 'FRICTIONLESS_CHALLENGE'
}

export enum Partner {
  ACTIVE_MERCHANT = 'ACTIVE_MERCHANT',
  SHOPIFY = 'SHOPIFY',
  V1 = 'V1'
}

export type PauseSubscriptionInput = {
  pauseAtPeriodEnd?: InputMaybe<Scalars['Boolean']>;
  pauseIntervalCount: Scalars['Int'];
  subscriptionId: Scalars['String'];
};

export type PayPalLink = {
  __typename?: 'PayPalLink';
  link: Scalars['String'];
};

export type Payment = {
  __typename?: 'Payment';
  accountId: Scalars['ID'];
  amount?: Maybe<Scalars['Int']>;
  authorizationCode?: Maybe<Scalars['String']>;
  billingDetails?: Maybe<ContactDetails>;
  cancellationReason?: Maybe<CancellationReason>;
  createdAt?: Maybe<Scalars['AWSTimestamp']>;
  currency: Scalars['String'];
  customer?: Maybe<Customer>;
  description?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  lastRefundAmount?: Maybe<Scalars['Int']>;
  lastRefundReason?: Maybe<RefundReason>;
  livemode?: Maybe<Scalars['Boolean']>;
  masterAccountId?: Maybe<Scalars['String']>;
  nextAction?: Maybe<NextAction>;
  orderId?: Maybe<Scalars['String']>;
  pageOpenedAt?: Maybe<Scalars['AWSTimestamp']>;
  paymentMethod?: Maybe<PaymentMethod>;
  paymentToken?: Maybe<Scalars['String']>;
  pointOfSaleId?: Maybe<Scalars['String']>;
  refundedAmount?: Maybe<Scalars['Int']>;
  sequenceId?: Maybe<Scalars['String']>;
  sessionDetails?: Maybe<SessionDetails>;
  shippingDetails?: Maybe<ContactDetails>;
  shop?: Maybe<Shop>;
  status: PaymentStatus;
  statusCode?: Maybe<Scalars['String']>;
  statusMessage?: Maybe<Scalars['String']>;
  storeId?: Maybe<Scalars['String']>;
  subscriptionId?: Maybe<Scalars['String']>;
  traceDetails?: Maybe<TraceDetails>;
  updatedAt?: Maybe<Scalars['AWSTimestamp']>;
};

export enum PaymentEmailType {
  PAYMENT_LINK = 'PAYMENT_LINK',
  RECEIPT = 'RECEIPT'
}

export type PaymentMethod = {
  __typename?: 'PaymentMethod';
  bizum?: Maybe<PaymentMethodBizum>;
  card?: Maybe<PaymentMethodCard>;
  cofidis?: Maybe<PaymentMethodCofidis>;
  cofidisLoan?: Maybe<PaymentMethodCofidisLoan>;
  klarna?: Maybe<PaymentMethodKlarna>;
  method?: Maybe<PaymentMethods>;
  paypal?: Maybe<PaymentMethodPaypal>;
  sepa?: Maybe<PaymentMethodSepa>;
  trustly?: Maybe<PaymentMethodTrustly>;
};

export type PaymentMethodBizum = {
  __typename?: 'PaymentMethodBizum';
  integrationType?: Maybe<Scalars['String']>;
  phoneNumber?: Maybe<Scalars['String']>;
};

export type PaymentMethodBizumInput = {
  integrationType?: InputMaybe<Scalars['String']>;
  phoneNumber?: InputMaybe<Scalars['String']>;
};

export type PaymentMethodCard = {
  __typename?: 'PaymentMethodCard';
  bank?: Maybe<Scalars['String']>;
  brand?: Maybe<CardBrand>;
  cardholderEmail?: Maybe<Scalars['String']>;
  cardholderName?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  expiration?: Maybe<Scalars['AWSTimestamp']>;
  last4?: Maybe<Scalars['String']>;
  threeDSecure?: Maybe<Scalars['Boolean']>;
  threeDSecureFlow?: Maybe<ThreeDSecureFlow>;
  threeDSecureVersion?: Maybe<Scalars['String']>;
  tokenizationMethod?: Maybe<TokenizationMethods>;
  type?: Maybe<CardType>;
};

export type PaymentMethodCardInput = {
  bank?: InputMaybe<Scalars['String']>;
  brand?: InputMaybe<CardBrand>;
  cardholderEmail?: InputMaybe<Scalars['String']>;
  cardholderName?: InputMaybe<Scalars['String']>;
  country?: InputMaybe<Scalars['String']>;
  last4?: InputMaybe<Scalars['String']>;
  threeDSecure?: InputMaybe<Scalars['Boolean']>;
  threeDSecureVersion?: InputMaybe<Scalars['String']>;
  tokenizationMethod?: InputMaybe<TokenizationMethods>;
  type?: InputMaybe<CardType>;
};

export type PaymentMethodCofidis = {
  __typename?: 'PaymentMethodCofidis';
  orderId?: Maybe<Scalars['String']>;
};

export type PaymentMethodCofidisInput = {
  orderId?: InputMaybe<Scalars['String']>;
};

export type PaymentMethodCofidisLoan = {
  __typename?: 'PaymentMethodCofidisLoan';
  orderId?: Maybe<Scalars['String']>;
};

export type PaymentMethodCofidisLoanInput = {
  orderId?: InputMaybe<Scalars['String']>;
};

export type PaymentMethodInput = {
  bizum?: InputMaybe<PaymentMethodBizumInput>;
  card?: InputMaybe<PaymentMethodCardInput>;
  cofidis?: InputMaybe<PaymentMethodCofidisInput>;
  cofidisLoan?: InputMaybe<PaymentMethodCofidisLoanInput>;
  klarna?: InputMaybe<PaymentMethodKlarnaInput>;
  method?: InputMaybe<PaymentMethods>;
  paypal?: InputMaybe<PaymentMethodPaypalInput>;
  sepa?: InputMaybe<PaymentMethodSepaInput>;
  trustly?: InputMaybe<PaymentMethodTrustlyInput>;
};

export type PaymentMethodKlarna = {
  __typename?: 'PaymentMethodKlarna';
  authPaymentMethod?: Maybe<Scalars['String']>;
  billingCategory?: Maybe<Scalars['String']>;
};

export type PaymentMethodKlarnaInput = {
  authPaymentMethod?: InputMaybe<Scalars['String']>;
  billingCategory?: InputMaybe<Scalars['String']>;
};

export type PaymentMethodPaypal = {
  __typename?: 'PaymentMethodPaypal';
  email?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  orderId?: Maybe<Scalars['String']>;
  payerId?: Maybe<Scalars['String']>;
};

export type PaymentMethodPaypalInput = {
  email?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  orderId?: InputMaybe<Scalars['String']>;
  payerId?: InputMaybe<Scalars['String']>;
};

export type PaymentMethodSepa = {
  __typename?: 'PaymentMethodSepa';
  accountholderAddress?: Maybe<Address>;
  accountholderEmail?: Maybe<Scalars['String']>;
  accountholderName?: Maybe<Scalars['String']>;
  bankAddress?: Maybe<Scalars['String']>;
  bankCode?: Maybe<Scalars['String']>;
  bankName?: Maybe<Scalars['String']>;
  bic?: Maybe<Scalars['String']>;
  countryCode?: Maybe<Scalars['String']>;
  last4?: Maybe<Scalars['String']>;
};

export type PaymentMethodSepaInput = {
  accountholderAddress?: InputMaybe<AddressInput>;
  accountholderEmail?: InputMaybe<Scalars['String']>;
  accountholderName?: InputMaybe<Scalars['String']>;
  bankAddress?: InputMaybe<Scalars['String']>;
  bankCode?: InputMaybe<Scalars['String']>;
  bankName?: InputMaybe<Scalars['String']>;
  bic?: InputMaybe<Scalars['String']>;
  countryCode?: InputMaybe<Scalars['String']>;
  last4?: InputMaybe<Scalars['String']>;
};

export type PaymentMethodTrustly = {
  __typename?: 'PaymentMethodTrustly';
  customerId?: Maybe<Scalars['String']>;
};

export type PaymentMethodTrustlyInput = {
  customerId?: InputMaybe<Scalars['String']>;
};

export enum PaymentMethods {
  APPLEPAY = 'applePay',
  BANCONTACT = 'bancontact',
  BIZUM = 'bizum',
  CARD = 'card',
  CLICKTOPAY = 'clickToPay',
  COFIDIS = 'cofidis',
  COFIDISLOAN = 'cofidisLoan',
  GIROPAY = 'giropay',
  GOOGLEPAY = 'googlePay',
  IDEAL = 'iDeal',
  KLARNA = 'klarna',
  MULTIBANCO = 'multibanco',
  PAYPAL = 'paypal',
  SEPA = 'sepa',
  SOFORT = 'sofort',
  TRUSTLY = 'trustly'
}

export enum PaymentStatus {
  AUTHORIZED = 'AUTHORIZED',
  CANCELED = 'CANCELED',
  EXPIRED = 'EXPIRED',
  FAILED = 'FAILED',
  PARTIALLY_REFUNDED = 'PARTIALLY_REFUNDED',
  PENDING = 'PENDING',
  PENDING_PROCESSING = 'PENDING_PROCESSING',
  REFUNDED = 'REFUNDED',
  SUCCEEDED = 'SUCCEEDED'
}

export type Period = {
  __typename?: 'Period';
  from: Scalars['Int'];
  to: Scalars['Int'];
};

export type PointOfSale = {
  __typename?: 'PointOfSale';
  currentPaymentId?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  manualAmount?: Maybe<Scalars['Boolean']>;
  name?: Maybe<Scalars['String']>;
  number: Scalars['Int'];
  storeId: Scalars['ID'];
};

export type PointsOfSaleConnection = {
  __typename?: 'PointsOfSaleConnection';
  items: Array<PointOfSale>;
  nextToken?: Maybe<Scalars['String']>;
};

export enum Processor {
  ADYEN = 'ADYEN',
  COFIDIS = 'COFIDIS',
  MONEIX_F = 'MONEIX_F',
  MONEIX_G = 'MONEIX_G',
  MONEIX_P = 'MONEIX_P',
  MONEI_REDSYS = 'MONEI_REDSYS',
  MONEI_X = 'MONEI_X',
  PAYPAL = 'PAYPAL',
  REDSYS = 'REDSYS',
  STRIPE = 'STRIPE'
}

export type Provider = {
  __typename?: 'Provider';
  acquirer?: Maybe<Acquirer>;
  configurable?: Maybe<Scalars['Boolean']>;
  enabled?: Maybe<Scalars['Boolean']>;
  id: Scalars['ID'];
  metadata?: Maybe<Array<MetadataItem>>;
  paymentMethod?: Maybe<PaymentMethods>;
  processor?: Maybe<Processor>;
  rules?: Maybe<ProviderRules>;
};

export type ProviderConfig = {
  __typename?: 'ProviderConfig';
  acquirers?: Maybe<Array<Acquirer>>;
  metadataConfig?: Maybe<Array<MetadataConfigItem>>;
  paymentMethod?: Maybe<PaymentMethods>;
  processor?: Maybe<Processor>;
};

export type ProviderRules = {
  __typename?: 'ProviderRules';
  descriptionLike?: Maybe<Scalars['String']>;
  maxAmount?: Maybe<Scalars['Int']>;
  minAmount?: Maybe<Scalars['Int']>;
  shopNameLike?: Maybe<Scalars['String']>;
};

export type ProviderRulesInput = {
  descriptionLike?: InputMaybe<Scalars['String']>;
  maxAmount?: InputMaybe<Scalars['Int']>;
  minAmount?: InputMaybe<Scalars['Int']>;
  shopNameLike?: InputMaybe<Scalars['String']>;
};

export type Query = {
  __typename?: 'Query';
  account: Account;
  accountInvoice?: Maybe<AccountInvoice>;
  accountInvoices: AccountInvoicesConnection;
  accountSettlement?: Maybe<AccountSettlement>;
  accountSettlements: AccountSettlementsConnection;
  authorizedUsers: AuthorizedUsersConnection;
  availablePaymentMethods?: Maybe<Array<AvailablePaymentMethod>>;
  availableProcessors?: Maybe<Array<AvailableProcessor>>;
  charge?: Maybe<Charge>;
  chargeEvents: ChargeEventsConnection;
  charges: ChargesConnection;
  chargesDateRangeKPI: ChargesDateRangeKpi;
  code?: Maybe<Code>;
  codeConfig?: Maybe<CodeConfig>;
  codes: Array<Code>;
  countries: Countries;
  events: ChargeEventsConnection;
  liveAccount: Account;
  paymentEmailPreview?: Maybe<Scalars['String']>;
  pointOfSale?: Maybe<PointOfSale>;
  pointsOfSale: PointsOfSaleConnection;
  provider?: Maybe<Provider>;
  providerConfig?: Maybe<ProviderConfig>;
  providers?: Maybe<Array<Provider>>;
  settlementEvents: SettlementEventsConnection;
  store?: Maybe<Store>;
  stores: StoresConnection;
  subscription?: Maybe<Subscription>;
  subscriptions: SubscriptionsConnection;
  userAccounts: UserAccountsConnection;
  userStore?: Maybe<Store>;
};


export type QueryAccountInvoiceArgs = {
  id: Scalars['ID'];
};


export type QueryAccountInvoicesArgs = {
  filter?: InputMaybe<SearchableAccountInvoiceFilterInput>;
  from?: InputMaybe<Scalars['Int']>;
  size?: InputMaybe<Scalars['Int']>;
};


export type QueryAccountSettlementArgs = {
  id: Scalars['ID'];
};


export type QueryAccountSettlementsArgs = {
  filter?: InputMaybe<SearchableAccountSettlementFilterInput>;
  from?: InputMaybe<Scalars['Int']>;
  size?: InputMaybe<Scalars['Int']>;
};


export type QueryAuthorizedUsersArgs = {
  pageSize?: InputMaybe<Scalars['Int']>;
  token?: InputMaybe<Scalars['String']>;
};


export type QueryAvailableProcessorsArgs = {
  paymentMethod: PaymentMethods;
};


export type QueryChargeArgs = {
  id: Scalars['ID'];
};


export type QueryChargeEventsArgs = {
  chargeId: Scalars['ID'];
  from?: InputMaybe<Scalars['Int']>;
  size?: InputMaybe<Scalars['Int']>;
};


export type QueryChargesArgs = {
  filter?: InputMaybe<SearchableChargeFilterInput>;
  from?: InputMaybe<Scalars['Int']>;
  size?: InputMaybe<Scalars['Int']>;
};


export type QueryChargesDateRangeKpiArgs = {
  end?: InputMaybe<Scalars['Int']>;
  interval?: InputMaybe<Interval>;
  start?: InputMaybe<Scalars['Int']>;
  storeId?: InputMaybe<Scalars['String']>;
  timezone?: InputMaybe<Scalars['String']>;
};


export type QueryCodeArgs = {
  id: Scalars['ID'];
};


export type QueryCodeConfigArgs = {
  codeType: CodeTypes;
};


export type QueryEventsArgs = {
  filter?: InputMaybe<SearchableEventFilterInput>;
  from?: InputMaybe<Scalars['Int']>;
  size?: InputMaybe<Scalars['Int']>;
};


export type QueryPaymentEmailPreviewArgs = {
  emailType?: InputMaybe<PaymentEmailType>;
  language?: InputMaybe<Scalars['String']>;
  paymentId: Scalars['ID'];
};


export type QueryPointOfSaleArgs = {
  id: Scalars['ID'];
};


export type QueryPointsOfSaleArgs = {
  pageSize?: InputMaybe<Scalars['Int']>;
  storeId?: InputMaybe<Scalars['ID']>;
  token?: InputMaybe<Scalars['String']>;
};


export type QueryProviderArgs = {
  id: Scalars['ID'];
};


export type QueryProviderConfigArgs = {
  paymentMethod: PaymentMethods;
  processor: Processor;
};


export type QuerySettlementEventsArgs = {
  from?: InputMaybe<Scalars['Int']>;
  settlementId: Scalars['ID'];
  size?: InputMaybe<Scalars['Int']>;
};


export type QueryStoreArgs = {
  id: Scalars['ID'];
};


export type QueryStoresArgs = {
  pageSize?: InputMaybe<Scalars['Int']>;
  token?: InputMaybe<Scalars['String']>;
};


export type QuerySubscriptionArgs = {
  id: Scalars['ID'];
};


export type QuerySubscriptionsArgs = {
  filter?: InputMaybe<SearchableSubscriptionFilterInput>;
  from?: InputMaybe<Scalars['Int']>;
  size?: InputMaybe<Scalars['Int']>;
};


export type QueryUserAccountsArgs = {
  pageSize?: InputMaybe<Scalars['Int']>;
  token?: InputMaybe<Scalars['String']>;
};

export type Redirect = {
  __typename?: 'Redirect';
  redirectUrl: Scalars['String'];
};

export type RefundPaymentInput = {
  amount: Scalars['Int'];
  paymentId: Scalars['ID'];
  refundReason?: InputMaybe<RefundReason>;
};

export enum RefundReason {
  DUPLICATED = 'duplicated',
  FRAUDULENT = 'fraudulent',
  REQUESTED_BY_CUSTOMER = 'requested_by_customer'
}

export type RegisterApplePayDomain = {
  __typename?: 'RegisterApplePayDomain';
  success: Scalars['Boolean'];
};

export type RegisterApplePayDomainInput = {
  domainName: Scalars['String'];
};

export type RegisterCodeInput = {
  codeId: Scalars['String'];
  pointOfSaleId?: InputMaybe<Scalars['String']>;
};

export type Representative = {
  __typename?: 'Representative';
  address?: Maybe<Address>;
  citizenship?: Maybe<Scalars['String']>;
  documentNumber?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  hasNoPublicActivity?: Maybe<Scalars['Boolean']>;
  lastName?: Maybe<Scalars['String']>;
  nationality?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  status?: Maybe<IdVerificationStatus>;
  statusCode?: Maybe<Scalars['Int']>;
  statusMessage?: Maybe<Scalars['String']>;
  stocksPercentage?: Maybe<Scalars['Float']>;
};

export type RepresentativeInput = {
  address?: InputMaybe<AddressInput>;
  citizenship?: InputMaybe<Scalars['String']>;
  documentNumber?: InputMaybe<Scalars['String']>;
  email?: InputMaybe<Scalars['String']>;
  firstName?: InputMaybe<Scalars['String']>;
  hasNoPublicActivity?: InputMaybe<Scalars['Boolean']>;
  lastName?: InputMaybe<Scalars['String']>;
  nationality?: InputMaybe<Scalars['String']>;
  phone?: InputMaybe<Scalars['String']>;
  stocksPercentage?: InputMaybe<Scalars['Float']>;
};

export type ResumeSubscriptionInput = {
  subscriptionId: Scalars['String'];
};

export type S3Object = {
  __typename?: 'S3Object';
  bucket: Scalars['String'];
  key?: Maybe<Scalars['String']>;
  region: Scalars['String'];
};

export type S3ObjectInput = {
  bucket: Scalars['String'];
  key?: InputMaybe<Scalars['String']>;
  region: Scalars['String'];
};

export type SearchableAccountInvoiceFilterInput = {
  createdAt?: InputMaybe<SearchableIntFilterInput>;
};

export type SearchableAccountSettlementFilterInput = {
  createdAt?: InputMaybe<SearchableIntFilterInput>;
};

export type SearchableBooleanFilterInput = {
  eq?: InputMaybe<Scalars['Boolean']>;
  ne?: InputMaybe<Scalars['Boolean']>;
};

export type SearchableChargeFilterInput = {
  amount?: InputMaybe<SearchableIntFilterInput>;
  and?: InputMaybe<Array<InputMaybe<SearchableChargeFilterInput>>>;
  authorizationCode?: InputMaybe<SearchableStringFilterInput>;
  checkoutId?: InputMaybe<SearchableIdFilterInput>;
  createdAt?: InputMaybe<SearchableIntFilterInput>;
  currency?: InputMaybe<SearchableStringFilterInput>;
  customerEmail?: InputMaybe<SearchableStringFilterInput>;
  customerName?: InputMaybe<SearchableStringFilterInput>;
  customerPhone?: InputMaybe<SearchableStringFilterInput>;
  description?: InputMaybe<SearchableStringFilterInput>;
  id?: InputMaybe<SearchableIdFilterInput>;
  not?: InputMaybe<SearchableChargeFilterInput>;
  or?: InputMaybe<Array<InputMaybe<SearchableChargeFilterInput>>>;
  orderId?: InputMaybe<SearchableStringFilterInput>;
  paymentMethodCardBrand?: InputMaybe<SearchableStringFilterInput>;
  paymentMethodCardLast4?: InputMaybe<SearchableStringFilterInput>;
  paymentMethodCardThreeDSecure?: InputMaybe<SearchableBooleanFilterInput>;
  paymentMethodCardThreeDSecureVersion?: InputMaybe<SearchableStringFilterInput>;
  paymentMethodCardTokenizationMethod?: InputMaybe<SearchableStringFilterInput>;
  paymentMethodCardType?: InputMaybe<SearchableStringFilterInput>;
  paymentMethodMethod?: InputMaybe<SearchableStringFilterInput>;
  pointOfSaleId?: InputMaybe<SearchableStringFilterInput>;
  providerInternalId?: InputMaybe<SearchableStringFilterInput>;
  providerReferenceId?: InputMaybe<SearchableStringFilterInput>;
  refundedAmount?: InputMaybe<SearchableIntFilterInput>;
  sequenceId?: InputMaybe<SearchableStringFilterInput>;
  sessionCountry?: InputMaybe<SearchableStringFilterInput>;
  sessionIp?: InputMaybe<SearchableStringFilterInput>;
  shopCountry?: InputMaybe<SearchableStringFilterInput>;
  shopName?: InputMaybe<SearchableStringFilterInput>;
  status?: InputMaybe<SearchableStringFilterInput>;
  statusCode?: InputMaybe<SearchableStringFilterInput>;
  statusMessage?: InputMaybe<SearchableStringFilterInput>;
  storeId?: InputMaybe<SearchableStringFilterInput>;
  subscriptionId?: InputMaybe<SearchableStringFilterInput>;
  updatedAt?: InputMaybe<SearchableIntFilterInput>;
};

export type SearchableEventFilterInput = {
  amount?: InputMaybe<SearchableIntFilterInput>;
  and?: InputMaybe<Array<InputMaybe<SearchableEventFilterInput>>>;
  authorizationCode?: InputMaybe<SearchableStringFilterInput>;
  checkoutId?: InputMaybe<SearchableIdFilterInput>;
  createdAt?: InputMaybe<SearchableIntFilterInput>;
  currency?: InputMaybe<SearchableStringFilterInput>;
  customerEmail?: InputMaybe<SearchableStringFilterInput>;
  customerName?: InputMaybe<SearchableStringFilterInput>;
  customerPhone?: InputMaybe<SearchableStringFilterInput>;
  description?: InputMaybe<SearchableStringFilterInput>;
  id?: InputMaybe<SearchableIdFilterInput>;
  not?: InputMaybe<SearchableEventFilterInput>;
  objectId?: InputMaybe<SearchableIdFilterInput>;
  or?: InputMaybe<Array<InputMaybe<SearchableEventFilterInput>>>;
  orderId?: InputMaybe<SearchableStringFilterInput>;
  paymentMethodCardBrand?: InputMaybe<SearchableStringFilterInput>;
  paymentMethodCardLast4?: InputMaybe<SearchableStringFilterInput>;
  paymentMethodCardThreeDSecure?: InputMaybe<SearchableBooleanFilterInput>;
  paymentMethodCardThreeDSecureVersion?: InputMaybe<SearchableStringFilterInput>;
  paymentMethodCardTokenizationMethod?: InputMaybe<SearchableStringFilterInput>;
  paymentMethodCardType?: InputMaybe<SearchableStringFilterInput>;
  paymentMethodMethod?: InputMaybe<SearchableStringFilterInput>;
  pointOfSaleId?: InputMaybe<SearchableStringFilterInput>;
  providerInternalId?: InputMaybe<SearchableStringFilterInput>;
  providerReferenceId?: InputMaybe<SearchableStringFilterInput>;
  refundedAmount?: InputMaybe<SearchableIntFilterInput>;
  sequenceId?: InputMaybe<SearchableStringFilterInput>;
  sessionCountry?: InputMaybe<SearchableStringFilterInput>;
  sessionIp?: InputMaybe<SearchableStringFilterInput>;
  shopCountry?: InputMaybe<SearchableStringFilterInput>;
  shopName?: InputMaybe<SearchableStringFilterInput>;
  status?: InputMaybe<SearchableStringFilterInput>;
  statusCode?: InputMaybe<SearchableStringFilterInput>;
  statusMessage?: InputMaybe<SearchableStringFilterInput>;
  storeId?: InputMaybe<SearchableStringFilterInput>;
  subscriptionId?: InputMaybe<SearchableStringFilterInput>;
  type?: InputMaybe<SearchableStringFilterInput>;
};

export type SearchableFloatFilterInput = {
  eq?: InputMaybe<Scalars['Float']>;
  gt?: InputMaybe<Scalars['Float']>;
  gte?: InputMaybe<Scalars['Float']>;
  lt?: InputMaybe<Scalars['Float']>;
  lte?: InputMaybe<Scalars['Float']>;
  ne?: InputMaybe<Scalars['Float']>;
  range?: InputMaybe<Array<InputMaybe<Scalars['Float']>>>;
};

export type SearchableIdFilterInput = {
  eq?: InputMaybe<Scalars['ID']>;
  exists?: InputMaybe<Scalars['Boolean']>;
  match?: InputMaybe<Scalars['ID']>;
  matchPhrase?: InputMaybe<Scalars['ID']>;
  matchPhrasePrefix?: InputMaybe<Scalars['ID']>;
  ne?: InputMaybe<Scalars['ID']>;
  regexp?: InputMaybe<Scalars['ID']>;
  wildcard?: InputMaybe<Scalars['ID']>;
};

export type SearchableIntFilterInput = {
  eq?: InputMaybe<Scalars['Int']>;
  gt?: InputMaybe<Scalars['Int']>;
  gte?: InputMaybe<Scalars['Int']>;
  lt?: InputMaybe<Scalars['Int']>;
  lte?: InputMaybe<Scalars['Int']>;
  ne?: InputMaybe<Scalars['Int']>;
  range?: InputMaybe<Array<InputMaybe<Scalars['Int']>>>;
};

export type SearchableStringFilterInput = {
  eq?: InputMaybe<Scalars['String']>;
  exists?: InputMaybe<Scalars['Boolean']>;
  match?: InputMaybe<Scalars['String']>;
  matchPhrase?: InputMaybe<Scalars['String']>;
  matchPhrasePrefix?: InputMaybe<Scalars['String']>;
  ne?: InputMaybe<Scalars['String']>;
  regexp?: InputMaybe<Scalars['String']>;
  wildcard?: InputMaybe<Scalars['String']>;
};

export type SearchableSubscriptionFilterInput = {
  amount?: InputMaybe<SearchableIntFilterInput>;
  and?: InputMaybe<Array<InputMaybe<SearchableSubscriptionFilterInput>>>;
  createdAt?: InputMaybe<SearchableIntFilterInput>;
  currency?: InputMaybe<SearchableStringFilterInput>;
  customerEmail?: InputMaybe<SearchableStringFilterInput>;
  customerName?: InputMaybe<SearchableStringFilterInput>;
  customerPhone?: InputMaybe<SearchableStringFilterInput>;
  description?: InputMaybe<SearchableStringFilterInput>;
  id?: InputMaybe<SearchableIdFilterInput>;
  interval?: InputMaybe<SearchableStringFilterInput>;
  intervalCount?: InputMaybe<SearchableIntFilterInput>;
  lastOrderId?: InputMaybe<SearchableStringFilterInput>;
  lastPaymentId?: InputMaybe<SearchableIdFilterInput>;
  lastPaymentStatus?: InputMaybe<SearchableStringFilterInput>;
  lastPaymentStatusCode?: InputMaybe<SearchableStringFilterInput>;
  masterAccountId?: InputMaybe<SearchableStringFilterInput>;
  not?: InputMaybe<SearchableSubscriptionFilterInput>;
  or?: InputMaybe<Array<InputMaybe<SearchableSubscriptionFilterInput>>>;
  paymentMethodCardBrand?: InputMaybe<SearchableStringFilterInput>;
  paymentMethodCardLast4?: InputMaybe<SearchableStringFilterInput>;
  paymentMethodCardThreeDSecure?: InputMaybe<SearchableBooleanFilterInput>;
  paymentMethodCardThreeDSecureFlow?: InputMaybe<SearchableStringFilterInput>;
  paymentMethodCardThreeDSecureVersion?: InputMaybe<SearchableStringFilterInput>;
  paymentMethodCardTokenizationMethod?: InputMaybe<SearchableStringFilterInput>;
  paymentMethodCardType?: InputMaybe<SearchableStringFilterInput>;
  paymentMethodMethod?: InputMaybe<SearchableStringFilterInput>;
  sequenceId?: InputMaybe<SearchableStringFilterInput>;
  status?: InputMaybe<SearchableStringFilterInput>;
  storeId?: InputMaybe<SearchableStringFilterInput>;
  traceDetailsSource?: InputMaybe<SearchableStringFilterInput>;
  updatedAt?: InputMaybe<SearchableIntFilterInput>;
};

export type SendPaymentMessageInput = {
  channel?: InputMaybe<MessageChannel>;
  customerEmail?: InputMaybe<Scalars['AWSEmail']>;
  customerPhone?: InputMaybe<Scalars['AWSPhone']>;
  language?: InputMaybe<Scalars['String']>;
  paymentId: Scalars['ID'];
};

export type SendPaymentRequestInput = {
  language?: InputMaybe<Scalars['String']>;
  paymentId: Scalars['ID'];
  phoneNumber: Scalars['String'];
};

export type SessionDetails = {
  __typename?: 'SessionDetails';
  browser?: Maybe<Scalars['String']>;
  browserAccept?: Maybe<Scalars['String']>;
  browserColorDepth?: Maybe<Scalars['String']>;
  browserScreenHeight?: Maybe<Scalars['String']>;
  browserScreenWidth?: Maybe<Scalars['String']>;
  browserTimezoneOffset?: Maybe<Scalars['String']>;
  browserVersion?: Maybe<Scalars['String']>;
  countryCode?: Maybe<Scalars['String']>;
  deviceModel?: Maybe<Scalars['String']>;
  deviceType?: Maybe<DeviceType>;
  ip?: Maybe<Scalars['AWSIPAddress']>;
  lang?: Maybe<Scalars['String']>;
  os?: Maybe<Scalars['String']>;
  osVersion?: Maybe<Scalars['String']>;
  source?: Maybe<Scalars['String']>;
  sourceVersion?: Maybe<Scalars['String']>;
  userAgent?: Maybe<Scalars['String']>;
};

export type SessionDetailsInput = {
  browser?: InputMaybe<Scalars['String']>;
  browserAccept?: InputMaybe<Scalars['String']>;
  browserColorDepth?: InputMaybe<Scalars['String']>;
  browserScreenHeight?: InputMaybe<Scalars['String']>;
  browserScreenWidth?: InputMaybe<Scalars['String']>;
  browserTimezoneOffset?: InputMaybe<Scalars['String']>;
  browserVersion?: InputMaybe<Scalars['String']>;
  countryCode?: InputMaybe<Scalars['String']>;
  deviceModel?: InputMaybe<Scalars['String']>;
  deviceType?: InputMaybe<DeviceType>;
  ip?: InputMaybe<Scalars['AWSIPAddress']>;
  lang?: InputMaybe<Scalars['String']>;
  os?: InputMaybe<Scalars['String']>;
  osVersion?: InputMaybe<Scalars['String']>;
  source?: InputMaybe<Scalars['String']>;
  sourceVersion?: InputMaybe<Scalars['String']>;
  userAgent?: InputMaybe<Scalars['String']>;
};

export type SettlementEvent = {
  __typename?: 'SettlementEvent';
  accountId: Scalars['ID'];
  createdAt: Scalars['AWSTimestamp'];
  id: Scalars['ID'];
  object: SettlementEventObject;
  objectId: Scalars['ID'];
  objectType: Scalars['String'];
  type: Scalars['String'];
};

export type SettlementEventObject = {
  __typename?: 'SettlementEventObject';
  accountId: Scalars['ID'];
  acquirerFees: Scalars['Int'];
  createdAt: Scalars['AWSTimestamp'];
  grossVolume: Scalars['Int'];
  iban?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  legalName?: Maybe<Scalars['String']>;
  netVolume: Scalars['Int'];
  payMethod?: Maybe<SettlementPayMethods>;
  period: Scalars['Int'];
  periodEndAt: Scalars['AWSTimestamp'];
  periodStartAt: Scalars['AWSTimestamp'];
  previousNegative?: Maybe<Scalars['Boolean']>;
  releasedAmount?: Maybe<Scalars['Int']>;
  reservePercent: Scalars['Int'];
  reservedAmount?: Maybe<Scalars['Int']>;
  scheduledAt: Scalars['AWSTimestamp'];
  status: SettlementStatus;
  updatedAt: Scalars['AWSTimestamp'];
};

export type SettlementEventsConnection = {
  __typename?: 'SettlementEventsConnection';
  items: Array<SettlementEvent>;
  total: Scalars['Int'];
};

export enum SettlementPayMethods {
  BIZUM = 'bizum',
  CARD = 'card'
}

export enum SettlementStatus {
  COMPLETED = 'COMPLETED',
  PENDING = 'PENDING',
  SUSPENDED = 'SUSPENDED'
}

export type SetupAccountInput = {
  billingPlan?: InputMaybe<BillingPlans>;
  business?: InputMaybe<BusinessDataInput>;
  iban?: InputMaybe<Scalars['String']>;
  language?: InputMaybe<Scalars['String']>;
  representatives?: InputMaybe<Array<RepresentativeInput>>;
};

export type Shop = {
  __typename?: 'Shop';
  country?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
};

export type ShopInput = {
  country?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
};

export type Store = {
  __typename?: 'Store';
  address?: Maybe<Address>;
  email?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  name?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  website?: Maybe<Scalars['String']>;
};

export type StoresConnection = {
  __typename?: 'StoresConnection';
  items: Array<Store>;
  nextToken?: Maybe<Scalars['String']>;
};

export type Subscription = {
  __typename?: 'Subscription';
  accountId: Scalars['String'];
  amount: Scalars['Int'];
  billingDetails?: Maybe<ContactDetails>;
  callbackUrl?: Maybe<Scalars['String']>;
  cancelAtPeriodEnd?: Maybe<Scalars['Boolean']>;
  createdAt?: Maybe<Scalars['Int']>;
  currency?: Maybe<Currencies>;
  currentPeriodEnd?: Maybe<Scalars['Int']>;
  currentPeriodStart?: Maybe<Scalars['Int']>;
  customer?: Maybe<Customer>;
  customerId?: Maybe<Scalars['String']>;
  defaultTokenId?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  interval: Interval;
  intervalCount: Scalars['Int'];
  lastInvoiceId?: Maybe<Scalars['String']>;
  lastOrderId?: Maybe<Scalars['String']>;
  lastPayment?: Maybe<LastPayment>;
  livemode: Scalars['Boolean'];
  masterAccountId?: Maybe<Scalars['String']>;
  metadata?: Maybe<Array<Maybe<MetadataItem>>>;
  nextPaymentAt?: Maybe<Scalars['Int']>;
  pauseAtPeriodEnd?: Maybe<Scalars['Boolean']>;
  pauseIntervalCount?: Maybe<Scalars['Int']>;
  paymentCallbackUrl?: Maybe<Scalars['String']>;
  paymentMethod?: Maybe<PaymentMethod>;
  planVariantId?: Maybe<Scalars['String']>;
  productId?: Maybe<Scalars['String']>;
  retryCount?: Maybe<Scalars['Int']>;
  sequenceId?: Maybe<Scalars['String']>;
  shippingDetails?: Maybe<ContactDetails>;
  status: SubscriptionStatus;
  storeId?: Maybe<Scalars['String']>;
  traceDetails?: Maybe<TraceDetails>;
  trialPeriodDays?: Maybe<Scalars['Int']>;
  trialPeriodEnd?: Maybe<Scalars['Int']>;
  updatedAt?: Maybe<Scalars['Int']>;
};

export enum SubscriptionStatus {
  ACTIVE = 'ACTIVE',
  CANCELED = 'CANCELED',
  EXPIRED = 'EXPIRED',
  PAST_DUE = 'PAST_DUE',
  PAUSED = 'PAUSED',
  PENDING = 'PENDING',
  TRIALING = 'TRIALING'
}

export type Subscriptions = {
  __typename?: 'Subscriptions';
  onUpdateAccount?: Maybe<AccountChange>;
  onUpdateAnalytics?: Maybe<AnalyticsChange>;
  onUpdateCharge?: Maybe<Charge>;
  onUpdateChargeEvents?: Maybe<ChargeEventsChange>;
  onUpdateCharges?: Maybe<ChargesChange>;
  onUpdateEvents?: Maybe<EventsChange>;
};


export type SubscriptionsOnUpdateAccountArgs = {
  accountId: Scalars['ID'];
};


export type SubscriptionsOnUpdateAnalyticsArgs = {
  accountId: Scalars['ID'];
};


export type SubscriptionsOnUpdateChargeArgs = {
  accountId: Scalars['ID'];
  id: Scalars['ID'];
};


export type SubscriptionsOnUpdateChargeEventsArgs = {
  accountId: Scalars['ID'];
  id: Scalars['ID'];
};


export type SubscriptionsOnUpdateChargesArgs = {
  accountId: Scalars['ID'];
};


export type SubscriptionsOnUpdateEventsArgs = {
  accountId: Scalars['ID'];
};

export type SubscriptionsConnection = {
  __typename?: 'SubscriptionsConnection';
  items: Array<Subscription>;
  total: Scalars['Int'];
};

export enum ThreeDSecureFlow {
  CHALLENGE = 'CHALLENGE',
  DIRECT = 'DIRECT',
  FRICTIONLESS = 'FRICTIONLESS',
  FRICTIONLESS_CHALLENGE = 'FRICTIONLESS_CHALLENGE'
}

export enum TokenizationMethods {
  APPLEPAY = 'applePay',
  CLICKTOPAY = 'clickToPay',
  GOOGLEPAY = 'googlePay'
}

export type TraceDetails = {
  __typename?: 'TraceDetails';
  browser?: Maybe<Scalars['String']>;
  browserAccept?: Maybe<Scalars['String']>;
  browserVersion?: Maybe<Scalars['String']>;
  countryCode?: Maybe<Scalars['String']>;
  deviceModel?: Maybe<Scalars['String']>;
  deviceType?: Maybe<DeviceType>;
  ip?: Maybe<Scalars['AWSIPAddress']>;
  lang?: Maybe<Scalars['String']>;
  os?: Maybe<Scalars['String']>;
  osVersion?: Maybe<Scalars['String']>;
  source?: Maybe<Scalars['String']>;
  sourceVersion?: Maybe<Scalars['String']>;
  userAgent?: Maybe<Scalars['String']>;
  userEmail?: Maybe<Scalars['String']>;
  userId?: Maybe<Scalars['String']>;
  userName?: Maybe<Scalars['String']>;
};

export type TraceDetailsInput = {
  browser?: InputMaybe<Scalars['String']>;
  browserAccept?: InputMaybe<Scalars['String']>;
  browserVersion?: InputMaybe<Scalars['String']>;
  countryCode?: InputMaybe<Scalars['String']>;
  deviceModel?: InputMaybe<Scalars['String']>;
  deviceType?: InputMaybe<DeviceType>;
  ip?: InputMaybe<Scalars['AWSIPAddress']>;
  lang?: InputMaybe<Scalars['String']>;
  os?: InputMaybe<Scalars['String']>;
  osVersion?: InputMaybe<Scalars['String']>;
  source?: InputMaybe<Scalars['String']>;
  sourceVersion?: InputMaybe<Scalars['String']>;
  userAgent?: InputMaybe<Scalars['String']>;
  userEmail?: InputMaybe<Scalars['String']>;
  userId?: InputMaybe<Scalars['String']>;
  userName?: InputMaybe<Scalars['String']>;
};

export enum TransactionTypes {
  AUTH = 'AUTH',
  CANCEL = 'CANCEL',
  CAPTURE = 'CAPTURE',
  REFUND = 'REFUND',
  SALE = 'SALE',
  VOID = 'VOID'
}

export enum UnitType {
  MONEY = 'money',
  PERCENT = 'percent'
}

export type UpdateAccountInput = {
  branding?: InputMaybe<AccountBrandingInput>;
  language?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  notifications?: InputMaybe<AccountNotificationsInput>;
  publicBusinessDetails?: InputMaybe<AccountPublicBusinessDetailsInput>;
  timezone?: InputMaybe<Scalars['String']>;
};

export type UpdateAuthorizedUserInput = {
  accessLevel?: InputMaybe<AuthorizedUserAccessLevel>;
  storeId?: InputMaybe<Scalars['String']>;
  userEmail: Scalars['String'];
};

export type UpdateChargeInput = {
  accountId: Scalars['ID'];
  amount?: InputMaybe<Scalars['Int']>;
  authorizationCode?: InputMaybe<Scalars['String']>;
  billingDetails?: InputMaybe<ContactDetailsInput>;
  billingPlan?: InputMaybe<BillingPlans>;
  cancellationReason?: InputMaybe<CancellationReason>;
  checkoutId: Scalars['ID'];
  createdAt?: InputMaybe<Scalars['AWSTimestamp']>;
  currency: Scalars['String'];
  customer?: InputMaybe<CustomerInput>;
  description?: InputMaybe<Scalars['String']>;
  id: Scalars['ID'];
  lastRefundAmount?: InputMaybe<Scalars['Int']>;
  lastRefundReason?: InputMaybe<RefundReason>;
  livemode?: InputMaybe<Scalars['Boolean']>;
  masterAccountId?: InputMaybe<Scalars['String']>;
  orderId?: InputMaybe<Scalars['String']>;
  pageOpenedAt?: InputMaybe<Scalars['AWSTimestamp']>;
  paymentMethod?: InputMaybe<PaymentMethodInput>;
  pointOfSaleId?: InputMaybe<Scalars['String']>;
  providerId?: InputMaybe<Scalars['ID']>;
  providerInternalId?: InputMaybe<Scalars['String']>;
  providerReferenceId?: InputMaybe<Scalars['String']>;
  refundedAmount?: InputMaybe<Scalars['Int']>;
  sequenceId?: InputMaybe<Scalars['String']>;
  sessionDetails?: InputMaybe<SessionDetailsInput>;
  shippingDetails?: InputMaybe<ContactDetailsInput>;
  shop?: InputMaybe<ShopInput>;
  status: ChargeStatus;
  statusCode?: InputMaybe<Scalars['String']>;
  statusMessage?: InputMaybe<Scalars['String']>;
  storeId?: InputMaybe<Scalars['String']>;
  subscriptionId?: InputMaybe<Scalars['String']>;
  traceDetails?: InputMaybe<TraceDetailsInput>;
  updatedAt?: InputMaybe<Scalars['AWSTimestamp']>;
};

export type UpdateCodeInput = {
  enabled?: InputMaybe<Scalars['Boolean']>;
  id: Scalars['ID'];
};

export type UpdatePointOfSaleInput = {
  id: Scalars['ID'];
  manualAmount?: InputMaybe<Scalars['Boolean']>;
  name?: InputMaybe<Scalars['String']>;
};

export type UpdateProviderInput = {
  acquirer?: InputMaybe<Acquirer>;
  enabled?: InputMaybe<Scalars['Boolean']>;
  id: Scalars['ID'];
  metadata?: InputMaybe<Array<MetadataItemInput>>;
  rules?: InputMaybe<ProviderRulesInput>;
};

export type UpdateStoreInput = {
  address?: InputMaybe<AddressInput>;
  email?: InputMaybe<Scalars['String']>;
  id: Scalars['ID'];
  name?: InputMaybe<Scalars['String']>;
  phone?: InputMaybe<Scalars['String']>;
  website?: InputMaybe<Scalars['String']>;
};

export type UpdateSubscriptionInput = {
  amount: Scalars['Int'];
  billingDetails?: InputMaybe<ContactDetailsInput>;
  callbackUrl?: InputMaybe<Scalars['String']>;
  cancelAtPeriodEnd?: InputMaybe<Scalars['Boolean']>;
  customer?: InputMaybe<CustomerInput>;
  description?: InputMaybe<Scalars['String']>;
  interval?: InputMaybe<Interval>;
  intervalCount?: InputMaybe<Scalars['Int']>;
  nextPaymentAt?: InputMaybe<Scalars['AWSTimestamp']>;
  pauseAtPeriodEnd?: InputMaybe<Scalars['Boolean']>;
  pauseIntervalCount?: InputMaybe<Scalars['Int']>;
  paymentCallbackUrl?: InputMaybe<Scalars['String']>;
  shippingDetails?: InputMaybe<ContactDetailsInput>;
  subscriptionId: Scalars['String'];
  trialPeriodEnd?: InputMaybe<Scalars['AWSTimestamp']>;
};

export type UserAccount = {
  __typename?: 'UserAccount';
  accessLevel: AuthorizedUserAccessLevel;
  accountId: Scalars['String'];
  accountName?: Maybe<Scalars['String']>;
};

export type UserAccountsConnection = {
  __typename?: 'UserAccountsConnection';
  items: Array<UserAccount>;
  nextToken?: Maybe<Scalars['String']>;
};

export type AccountFieldsFragment = { __typename?: 'Account', id: string, name?: string | null | undefined, timezone?: string | null | undefined, customDomain?: string | null | undefined, adminEmail?: string | null | undefined, status: AccountStatus, publicBusinessDetails?: { __typename?: 'AccountPublicBusinessDetails', companyName?: string | null | undefined, website?: string | null | undefined, phone?: string | null | undefined, supportWebsite?: string | null | undefined, supportEmail?: string | null | undefined, supportPhone?: string | null | undefined, address?: { __typename?: 'Address', city?: string | null | undefined, country?: string | null | undefined, line1?: string | null | undefined, line2?: string | null | undefined, zip?: string | null | undefined, state?: string | null | undefined } | null | undefined } | null | undefined, branding?: { __typename?: 'AccountBranding', accentColor?: string | null | undefined, bgColor?: string | null | undefined, logoUrl?: string | null | undefined, iconUrl?: string | null | undefined } | null | undefined, business?: { __typename?: 'BusinessData', companyName?: string | null | undefined, legalName?: string | null | undefined } | null | undefined };

export type AuthorizedUserFieldsFragment = { __typename?: 'AuthorizedUser', userEmail: string, id: string, accessLevel: AuthorizedUserAccessLevel, createdAt: string, updatedAt?: string | null | undefined };

export type AddressFieldsFragment = { __typename?: 'Address', city?: string | null | undefined, country?: string | null | undefined, line1?: string | null | undefined, line2?: string | null | undefined, zip?: string | null | undefined, state?: string | null | undefined };

export type SessionDetailsFieldsFragment = { __typename?: 'SessionDetails', ip?: any | null | undefined, userAgent?: string | null | undefined, countryCode?: string | null | undefined, lang?: string | null | undefined, deviceType?: DeviceType | null | undefined, deviceModel?: string | null | undefined, browser?: string | null | undefined, browserVersion?: string | null | undefined, browserAccept?: string | null | undefined, browserColorDepth?: string | null | undefined, browserScreenHeight?: string | null | undefined, browserScreenWidth?: string | null | undefined, browserTimezoneOffset?: string | null | undefined, os?: string | null | undefined, osVersion?: string | null | undefined, source?: string | null | undefined, sourceVersion?: string | null | undefined };

export type TraceDetailsFieldsFragment = { __typename?: 'TraceDetails', ip?: any | null | undefined, userAgent?: string | null | undefined, countryCode?: string | null | undefined, lang?: string | null | undefined, deviceType?: DeviceType | null | undefined, deviceModel?: string | null | undefined, browser?: string | null | undefined, browserVersion?: string | null | undefined, browserAccept?: string | null | undefined, os?: string | null | undefined, osVersion?: string | null | undefined, source?: string | null | undefined, sourceVersion?: string | null | undefined, userId?: string | null | undefined, userEmail?: string | null | undefined };

export type PaymentMethodFieldsFragment = { __typename?: 'PaymentMethod', method?: PaymentMethods | null | undefined, card?: { __typename?: 'PaymentMethodCard', brand?: CardBrand | null | undefined, country?: string | null | undefined, type?: CardType | null | undefined, threeDSecure?: boolean | null | undefined, threeDSecureVersion?: string | null | undefined, last4?: string | null | undefined, bank?: string | null | undefined } | null | undefined, bizum?: { __typename?: 'PaymentMethodBizum', phoneNumber?: string | null | undefined } | null | undefined, paypal?: { __typename?: 'PaymentMethodPaypal', orderId?: string | null | undefined, payerId?: string | null | undefined, email?: string | null | undefined, name?: string | null | undefined } | null | undefined };

export type ChargeFieldsFragment = { __typename?: 'Charge', id: string, createdAt?: any | null | undefined, amount?: number | null | undefined, currency: string, description?: string | null | undefined, orderId?: string | null | undefined, refundedAmount?: number | null | undefined, status: ChargeStatus, statusCode?: string | null | undefined, statusMessage?: string | null | undefined, customer?: { __typename?: 'Customer', email?: string | null | undefined, name?: string | null | undefined, phone?: string | null | undefined } | null | undefined, paymentMethod?: { __typename?: 'PaymentMethod', method?: PaymentMethods | null | undefined, card?: { __typename?: 'PaymentMethodCard', brand?: CardBrand | null | undefined, country?: string | null | undefined, type?: CardType | null | undefined, threeDSecure?: boolean | null | undefined, threeDSecureVersion?: string | null | undefined, tokenizationMethod?: TokenizationMethods | null | undefined, cardholderEmail?: string | null | undefined, cardholderName?: string | null | undefined, last4?: string | null | undefined, bank?: string | null | undefined } | null | undefined, bizum?: { __typename?: 'PaymentMethodBizum', phoneNumber?: string | null | undefined } | null | undefined, paypal?: { __typename?: 'PaymentMethodPaypal', orderId?: string | null | undefined, payerId?: string | null | undefined, email?: string | null | undefined, name?: string | null | undefined } | null | undefined } | null | undefined };

export type ChargeEventObjectFieldsFragment = { __typename?: 'ChargeEventObject', id: string, createdAt?: any | null | undefined, amount?: number | null | undefined, currency: string, description?: string | null | undefined, orderId?: string | null | undefined, status: ChargeStatus, statusCode?: string | null | undefined, statusMessage?: string | null | undefined, customer?: { __typename?: 'Customer', email?: string | null | undefined, name?: string | null | undefined, phone?: string | null | undefined } | null | undefined };

export type PaymentFieldsFragment = { __typename?: 'Payment', id: string, createdAt?: any | null | undefined, amount?: number | null | undefined, currency: string, description?: string | null | undefined, orderId?: string | null | undefined, refundedAmount?: number | null | undefined, status: PaymentStatus, statusCode?: string | null | undefined, statusMessage?: string | null | undefined, customer?: { __typename?: 'Customer', email?: string | null | undefined, name?: string | null | undefined, phone?: string | null | undefined } | null | undefined, paymentMethod?: { __typename?: 'PaymentMethod', bizum?: { __typename?: 'PaymentMethodBizum', phoneNumber?: string | null | undefined } | null | undefined } | null | undefined };

export type ProviderFieldsFragment = { __typename?: 'Provider', id: string, enabled?: boolean | null | undefined, paymentMethod?: PaymentMethods | null | undefined };

export type CodeFieldsFragment = { __typename?: 'Code', id: string, type: CodeTypes, enabled?: boolean | null | undefined };

export type UpdateAccountMutationVariables = Exact<{
  input: UpdateAccountInput;
}>;


export type UpdateAccountMutation = { __typename?: 'Mutation', updateAccount: { __typename?: 'Account', id: string, name?: string | null | undefined, timezone?: string | null | undefined, customDomain?: string | null | undefined, adminEmail?: string | null | undefined, status: AccountStatus, publicBusinessDetails?: { __typename?: 'AccountPublicBusinessDetails', companyName?: string | null | undefined, website?: string | null | undefined, phone?: string | null | undefined, supportWebsite?: string | null | undefined, supportEmail?: string | null | undefined, supportPhone?: string | null | undefined, address?: { __typename?: 'Address', city?: string | null | undefined, country?: string | null | undefined, line1?: string | null | undefined, line2?: string | null | undefined, zip?: string | null | undefined, state?: string | null | undefined } | null | undefined } | null | undefined, branding?: { __typename?: 'AccountBranding', accentColor?: string | null | undefined, bgColor?: string | null | undefined, logoUrl?: string | null | undefined, iconUrl?: string | null | undefined } | null | undefined, business?: { __typename?: 'BusinessData', companyName?: string | null | undefined, legalName?: string | null | undefined } | null | undefined } };

export type CreateApiKeyMutationVariables = Exact<{ [key: string]: never; }>;


export type CreateApiKeyMutation = { __typename?: 'Mutation', createApiKey: string };

export type DeleteApiKeyMutationVariables = Exact<{ [key: string]: never; }>;


export type DeleteApiKeyMutation = { __typename?: 'Mutation', deleteApiKey?: boolean | null | undefined };

export type CreateAuthorizedUserMutationVariables = Exact<{
  input: CreateAuthorizedUserInput;
}>;


export type CreateAuthorizedUserMutation = { __typename?: 'Mutation', createAuthorizedUser?: { __typename?: 'AuthorizedUser', userEmail: string, id: string, accessLevel: AuthorizedUserAccessLevel, createdAt: string, updatedAt?: string | null | undefined } | null | undefined };

export type DeleteAuthorizedUserMutationVariables = Exact<{
  userEmail: Scalars['String'];
}>;


export type DeleteAuthorizedUserMutation = { __typename?: 'Mutation', deleteAuthorizedUser?: { __typename?: 'AuthorizedUser', userEmail: string, id: string, accessLevel: AuthorizedUserAccessLevel, createdAt: string, updatedAt?: string | null | undefined } | null | undefined };

export type UpdateAuthorizedUserMutationVariables = Exact<{
  input: UpdateAuthorizedUserInput;
}>;


export type UpdateAuthorizedUserMutation = { __typename?: 'Mutation', updateAuthorizedUser?: { __typename?: 'AuthorizedUser', userEmail: string, id: string, accessLevel: AuthorizedUserAccessLevel, createdAt: string, updatedAt?: string | null | undefined } | null | undefined };

export type ExportChargesMutationVariables = Exact<{
  filter?: InputMaybe<SearchableChargeFilterInput>;
  columns?: InputMaybe<Array<Scalars['String']> | Scalars['String']>;
  timezone?: InputMaybe<Scalars['String']>;
}>;


export type ExportChargesMutation = { __typename?: 'Mutation', exportCharges: { __typename?: 'ExportCharges', downloadUrl: string, expiresAt: any } };

export type ExportChargesOldMutationVariables = Exact<{
  filter?: InputMaybe<SearchableChargeFilterInput>;
  timezone?: InputMaybe<Scalars['String']>;
}>;


export type ExportChargesOldMutation = { __typename?: 'Mutation', exportChargesOld: { __typename?: 'ExportCharges', downloadUrl: string, expiresAt: any } };

export type RefundPaymentMutationVariables = Exact<{
  input: RefundPaymentInput;
}>;


export type RefundPaymentMutation = { __typename?: 'Mutation', refundPayment?: { __typename?: 'Payment', id: string, createdAt?: any | null | undefined, amount?: number | null | undefined, currency: string, description?: string | null | undefined, orderId?: string | null | undefined, refundedAmount?: number | null | undefined, status: PaymentStatus, statusCode?: string | null | undefined, statusMessage?: string | null | undefined, customer?: { __typename?: 'Customer', email?: string | null | undefined, name?: string | null | undefined, phone?: string | null | undefined } | null | undefined, paymentMethod?: { __typename?: 'PaymentMethod', bizum?: { __typename?: 'PaymentMethodBizum', phoneNumber?: string | null | undefined } | null | undefined } | null | undefined } | null | undefined };

export type CreatePaymentMutationVariables = Exact<{
  input: CreatePaymentInput;
}>;


export type CreatePaymentMutation = { __typename?: 'Mutation', createPayment?: { __typename?: 'Payment', id: string, createdAt?: any | null | undefined, amount?: number | null | undefined, currency: string, description?: string | null | undefined, orderId?: string | null | undefined, refundedAmount?: number | null | undefined, status: PaymentStatus, statusCode?: string | null | undefined, statusMessage?: string | null | undefined, customer?: { __typename?: 'Customer', email?: string | null | undefined, name?: string | null | undefined, phone?: string | null | undefined } | null | undefined, paymentMethod?: { __typename?: 'PaymentMethod', bizum?: { __typename?: 'PaymentMethodBizum', phoneNumber?: string | null | undefined } | null | undefined } | null | undefined } | null | undefined };

export type CapturePaymentMutationVariables = Exact<{
  input: CapturePaymentInput;
}>;


export type CapturePaymentMutation = { __typename?: 'Mutation', capturePayment?: { __typename?: 'Payment', id: string, createdAt?: any | null | undefined, amount?: number | null | undefined, currency: string, description?: string | null | undefined, orderId?: string | null | undefined, refundedAmount?: number | null | undefined, status: PaymentStatus, statusCode?: string | null | undefined, statusMessage?: string | null | undefined, customer?: { __typename?: 'Customer', email?: string | null | undefined, name?: string | null | undefined, phone?: string | null | undefined } | null | undefined, paymentMethod?: { __typename?: 'PaymentMethod', bizum?: { __typename?: 'PaymentMethodBizum', phoneNumber?: string | null | undefined } | null | undefined } | null | undefined } | null | undefined };

export type CancelPaymentMutationVariables = Exact<{
  input: CancelPaymentInput;
}>;


export type CancelPaymentMutation = { __typename?: 'Mutation', cancelPayment?: { __typename?: 'Payment', id: string, createdAt?: any | null | undefined, amount?: number | null | undefined, currency: string, description?: string | null | undefined, orderId?: string | null | undefined, refundedAmount?: number | null | undefined, status: PaymentStatus, statusCode?: string | null | undefined, statusMessage?: string | null | undefined, customer?: { __typename?: 'Customer', email?: string | null | undefined, name?: string | null | undefined, phone?: string | null | undefined } | null | undefined, paymentMethod?: { __typename?: 'PaymentMethod', bizum?: { __typename?: 'PaymentMethodBizum', phoneNumber?: string | null | undefined } | null | undefined } | null | undefined } | null | undefined };

export type CreatePayPalLinkMutationVariables = Exact<{ [key: string]: never; }>;


export type CreatePayPalLinkMutation = { __typename?: 'Mutation', createPayPalLink: { __typename?: 'PayPalLink', link: string } };

export type UpdateProviderMutationVariables = Exact<{
  input: UpdateProviderInput;
}>;


export type UpdateProviderMutation = { __typename?: 'Mutation', updateProvider: { __typename?: 'Provider', id: string, enabled?: boolean | null | undefined, paymentMethod?: PaymentMethods | null | undefined } };

export type RequestToPayMutationVariables = Exact<{
  input: SendPaymentRequestInput;
}>;


export type RequestToPayMutation = { __typename?: 'Mutation', sendPaymentRequest?: { __typename?: 'Payment', id: string, createdAt?: any | null | undefined, amount?: number | null | undefined, currency: string, description?: string | null | undefined, orderId?: string | null | undefined, refundedAmount?: number | null | undefined, status: PaymentStatus, statusCode?: string | null | undefined, statusMessage?: string | null | undefined, customer?: { __typename?: 'Customer', email?: string | null | undefined, name?: string | null | undefined, phone?: string | null | undefined } | null | undefined, paymentMethod?: { __typename?: 'PaymentMethod', bizum?: { __typename?: 'PaymentMethodBizum', phoneNumber?: string | null | undefined } | null | undefined } | null | undefined } | null | undefined };

export type AccountQueryVariables = Exact<{ [key: string]: never; }>;


export type AccountQuery = { __typename?: 'Query', account: { __typename?: 'Account', id: string, name?: string | null | undefined, timezone?: string | null | undefined, customDomain?: string | null | undefined, adminEmail?: string | null | undefined, status: AccountStatus, publicBusinessDetails?: { __typename?: 'AccountPublicBusinessDetails', companyName?: string | null | undefined, website?: string | null | undefined, phone?: string | null | undefined, supportWebsite?: string | null | undefined, supportEmail?: string | null | undefined, supportPhone?: string | null | undefined, address?: { __typename?: 'Address', city?: string | null | undefined, country?: string | null | undefined, line1?: string | null | undefined, line2?: string | null | undefined, zip?: string | null | undefined, state?: string | null | undefined } | null | undefined } | null | undefined, branding?: { __typename?: 'AccountBranding', accentColor?: string | null | undefined, bgColor?: string | null | undefined, logoUrl?: string | null | undefined, iconUrl?: string | null | undefined } | null | undefined, business?: { __typename?: 'BusinessData', companyName?: string | null | undefined, legalName?: string | null | undefined } | null | undefined } };

export type LiveAccountQueryVariables = Exact<{ [key: string]: never; }>;


export type LiveAccountQuery = { __typename?: 'Query', liveAccount: { __typename?: 'Account', id: string, status: AccountStatus } };

export type AuthorizedUsersQueryVariables = Exact<{
  token?: InputMaybe<Scalars['String']>;
  pageSize?: InputMaybe<Scalars['Int']>;
}>;


export type AuthorizedUsersQuery = { __typename?: 'Query', authorizedUsers: { __typename?: 'AuthorizedUsersConnection', nextToken?: string | null | undefined, items: Array<{ __typename?: 'AuthorizedUser', userEmail: string, id: string, accessLevel: AuthorizedUserAccessLevel, createdAt: string, updatedAt?: string | null | undefined }> } };

export type ChargesQueryVariables = Exact<{
  filter?: InputMaybe<SearchableChargeFilterInput>;
  size?: InputMaybe<Scalars['Int']>;
  from?: InputMaybe<Scalars['Int']>;
}>;


export type ChargesQuery = { __typename?: 'Query', charges: { __typename?: 'ChargesConnection', total: number, items: Array<{ __typename?: 'Charge', id: string, createdAt?: any | null | undefined, amount?: number | null | undefined, currency: string, description?: string | null | undefined, orderId?: string | null | undefined, refundedAmount?: number | null | undefined, status: ChargeStatus, statusCode?: string | null | undefined, statusMessage?: string | null | undefined, customer?: { __typename?: 'Customer', email?: string | null | undefined, name?: string | null | undefined, phone?: string | null | undefined } | null | undefined, paymentMethod?: { __typename?: 'PaymentMethod', method?: PaymentMethods | null | undefined, card?: { __typename?: 'PaymentMethodCard', brand?: CardBrand | null | undefined, country?: string | null | undefined, type?: CardType | null | undefined, threeDSecure?: boolean | null | undefined, threeDSecureVersion?: string | null | undefined, tokenizationMethod?: TokenizationMethods | null | undefined, cardholderEmail?: string | null | undefined, cardholderName?: string | null | undefined, last4?: string | null | undefined, bank?: string | null | undefined } | null | undefined, bizum?: { __typename?: 'PaymentMethodBizum', phoneNumber?: string | null | undefined } | null | undefined, paypal?: { __typename?: 'PaymentMethodPaypal', orderId?: string | null | undefined, payerId?: string | null | undefined, email?: string | null | undefined, name?: string | null | undefined } | null | undefined } | null | undefined }> } };

export type ChargeQueryVariables = Exact<{
  id: Scalars['ID'];
}>;


export type ChargeQuery = { __typename?: 'Query', charge?: { __typename?: 'Charge', id: string, createdAt?: any | null | undefined, amount?: number | null | undefined, currency: string, description?: string | null | undefined, orderId?: string | null | undefined, refundedAmount?: number | null | undefined, status: ChargeStatus, statusCode?: string | null | undefined, statusMessage?: string | null | undefined, customer?: { __typename?: 'Customer', email?: string | null | undefined, name?: string | null | undefined, phone?: string | null | undefined } | null | undefined, paymentMethod?: { __typename?: 'PaymentMethod', method?: PaymentMethods | null | undefined, card?: { __typename?: 'PaymentMethodCard', brand?: CardBrand | null | undefined, country?: string | null | undefined, type?: CardType | null | undefined, threeDSecure?: boolean | null | undefined, threeDSecureVersion?: string | null | undefined, tokenizationMethod?: TokenizationMethods | null | undefined, cardholderEmail?: string | null | undefined, cardholderName?: string | null | undefined, last4?: string | null | undefined, bank?: string | null | undefined } | null | undefined, bizum?: { __typename?: 'PaymentMethodBizum', phoneNumber?: string | null | undefined } | null | undefined, paypal?: { __typename?: 'PaymentMethodPaypal', orderId?: string | null | undefined, payerId?: string | null | undefined, email?: string | null | undefined, name?: string | null | undefined } | null | undefined } | null | undefined } | null | undefined };

export type SucceededDateRangeKpiQueryVariables = Exact<{
  start?: InputMaybe<Scalars['Int']>;
  end?: InputMaybe<Scalars['Int']>;
  interval?: InputMaybe<Interval>;
  timezone?: InputMaybe<Scalars['String']>;
}>;


export type SucceededDateRangeKpiQuery = { __typename?: 'Query', chargesDateRangeKPI: { __typename?: 'ChargesDateRangeKPI', currency: string, total: { __typename?: 'ChargesDateRangeKPITotal', succeededAmount: any, succeededCount: any } } };

export type ChargesDateRangeKpiQueryVariables = Exact<{
  start?: InputMaybe<Scalars['Int']>;
  end?: InputMaybe<Scalars['Int']>;
  interval?: InputMaybe<Interval>;
  timezone?: InputMaybe<Scalars['String']>;
}>;


export type ChargesDateRangeKpiQuery = { __typename?: 'Query', chargesDateRangeKPI: { __typename?: 'ChargesDateRangeKPI', currency: string, total: { __typename?: 'ChargesDateRangeKPITotal', succeededAmount: any, succeededCount: any, refundedCount: any, failedCount: any } } };

export type ChargeEventsQueryVariables = Exact<{
  chargeId: Scalars['ID'];
  size?: InputMaybe<Scalars['Int']>;
  from?: InputMaybe<Scalars['Int']>;
}>;


export type ChargeEventsQuery = { __typename?: 'Query', chargeEvents: { __typename?: 'ChargeEventsConnection', total: number, items: Array<{ __typename?: 'ChargeEvent', id: string, type: string, accountId: string, livemode?: boolean | null | undefined, objectId: string, objectType: string, createdAt?: any | null | undefined, object: { __typename?: 'ChargeEventObject', id: string, createdAt?: any | null | undefined, amount?: number | null | undefined, currency: string, description?: string | null | undefined, orderId?: string | null | undefined, status: ChargeStatus, statusCode?: string | null | undefined, statusMessage?: string | null | undefined, customer?: { __typename?: 'Customer', email?: string | null | undefined, name?: string | null | undefined, phone?: string | null | undefined } | null | undefined } }> } };

export type EventsQueryVariables = Exact<{
  filter?: InputMaybe<SearchableEventFilterInput>;
  size?: InputMaybe<Scalars['Int']>;
  from?: InputMaybe<Scalars['Int']>;
}>;


export type EventsQuery = { __typename?: 'Query', events: { __typename?: 'ChargeEventsConnection', total: number, items: Array<{ __typename?: 'ChargeEvent', id: string, type: string, accountId: string, livemode?: boolean | null | undefined, objectId: string, objectType: string, createdAt?: any | null | undefined, object: { __typename?: 'ChargeEventObject', id: string, createdAt?: any | null | undefined, amount?: number | null | undefined, currency: string, description?: string | null | undefined, orderId?: string | null | undefined, status: ChargeStatus, statusCode?: string | null | undefined, statusMessage?: string | null | undefined, customer?: { __typename?: 'Customer', email?: string | null | undefined, name?: string | null | undefined, phone?: string | null | undefined } | null | undefined } }> } };

export type CountriesQueryVariables = Exact<{ [key: string]: never; }>;


export type CountriesQuery = { __typename?: 'Query', countries: { __typename?: 'Countries', currentCountryCode?: string | null | undefined, isSupported: boolean, supportedCountries: Array<{ __typename?: 'Country', name: string, phoneCode: string, code: string }>, currentCountry?: { __typename?: 'Country', name: string, phoneCode: string, code: string } | null | undefined } };

export type PaymentEmailPreviewQueryVariables = Exact<{
  paymentId: Scalars['ID'];
  emailType?: InputMaybe<PaymentEmailType>;
}>;


export type PaymentEmailPreviewQuery = { __typename?: 'Query', paymentEmailPreview?: string | null | undefined };

export type ProvidersQueryVariables = Exact<{ [key: string]: never; }>;


export type ProvidersQuery = { __typename?: 'Query', providers?: Array<{ __typename?: 'Provider', id: string, enabled?: boolean | null | undefined, paymentMethod?: PaymentMethods | null | undefined }> | null | undefined };

export type ProviderQueryVariables = Exact<{
  id: Scalars['ID'];
}>;


export type ProviderQuery = { __typename?: 'Query', provider?: { __typename?: 'Provider', id: string, enabled?: boolean | null | undefined, paymentMethod?: PaymentMethods | null | undefined } | null | undefined };

export type AvailablePaymentMethodsQueryVariables = Exact<{ [key: string]: never; }>;


export type AvailablePaymentMethodsQuery = { __typename?: 'Query', availablePaymentMethods?: Array<{ __typename?: 'AvailablePaymentMethod', paymentMethod?: PaymentMethods | null | undefined, configured?: boolean | null | undefined, enabled?: boolean | null | undefined }> | null | undefined };

export type StoresQueryVariables = Exact<{
  token?: InputMaybe<Scalars['String']>;
  pageSize?: InputMaybe<Scalars['Int']>;
}>;


export type StoresQuery = { __typename?: 'Query', stores: { __typename?: 'StoresConnection', nextToken?: string | null | undefined, items: Array<{ __typename?: 'Store', id: string, name?: string | null | undefined }> } };

export type PointsOfSaleQueryVariables = Exact<{
  storeId?: InputMaybe<Scalars['ID']>;
  token?: InputMaybe<Scalars['String']>;
  pageSize?: InputMaybe<Scalars['Int']>;
}>;


export type PointsOfSaleQuery = { __typename?: 'Query', pointsOfSale: { __typename?: 'PointsOfSaleConnection', nextToken?: string | null | undefined, items: Array<{ __typename?: 'PointOfSale', id: string, storeId: string, number: number, name?: string | null | undefined, currentPaymentId?: string | null | undefined, manualAmount?: boolean | null | undefined }> } };

export type CodesQueryVariables = Exact<{ [key: string]: never; }>;


export type CodesQuery = { __typename?: 'Query', codes: Array<{ __typename?: 'Code', id: string, type: CodeTypes, enabled?: boolean | null | undefined }> };

export type ThemeQueryVariables = Exact<{ [key: string]: never; }>;


export type ThemeQuery = { __typename?: 'Query', account: { __typename?: 'Account', branding?: { __typename?: 'AccountBranding', accentColor?: string | null | undefined, iconUrl?: string | null | undefined } | null | undefined } };

export type OnUpdateAnalyticsSubscriptionVariables = Exact<{
  accountId: Scalars['ID'];
}>;


export type OnUpdateAnalyticsSubscription = { __typename?: 'Subscriptions', onUpdateAnalytics?: { __typename?: 'AnalyticsChange', accountId?: string | null | undefined } | null | undefined };

export type OnUpdateEventsSubscriptionVariables = Exact<{
  accountId: Scalars['ID'];
}>;


export type OnUpdateEventsSubscription = { __typename?: 'Subscriptions', onUpdateEvents?: { __typename?: 'EventsChange', accountId?: string | null | undefined } | null | undefined };

export type OnUpdateChargesSubscriptionVariables = Exact<{
  accountId: Scalars['ID'];
}>;


export type OnUpdateChargesSubscription = { __typename?: 'Subscriptions', onUpdateCharges?: { __typename?: 'ChargesChange', accountId?: string | null | undefined } | null | undefined };

export type OnUpdateChargeSubscriptionVariables = Exact<{
  accountId: Scalars['ID'];
  id: Scalars['ID'];
}>;


export type OnUpdateChargeSubscription = { __typename?: 'Subscriptions', onUpdateCharge?: { __typename?: 'Charge', id: string, createdAt?: any | null | undefined, amount?: number | null | undefined, currency: string, description?: string | null | undefined, orderId?: string | null | undefined, refundedAmount?: number | null | undefined, status: ChargeStatus, statusCode?: string | null | undefined, statusMessage?: string | null | undefined, customer?: { __typename?: 'Customer', email?: string | null | undefined, name?: string | null | undefined, phone?: string | null | undefined } | null | undefined, paymentMethod?: { __typename?: 'PaymentMethod', method?: PaymentMethods | null | undefined, card?: { __typename?: 'PaymentMethodCard', brand?: CardBrand | null | undefined, country?: string | null | undefined, type?: CardType | null | undefined, threeDSecure?: boolean | null | undefined, threeDSecureVersion?: string | null | undefined, tokenizationMethod?: TokenizationMethods | null | undefined, cardholderEmail?: string | null | undefined, cardholderName?: string | null | undefined, last4?: string | null | undefined, bank?: string | null | undefined } | null | undefined, bizum?: { __typename?: 'PaymentMethodBizum', phoneNumber?: string | null | undefined } | null | undefined, paypal?: { __typename?: 'PaymentMethodPaypal', orderId?: string | null | undefined, payerId?: string | null | undefined, email?: string | null | undefined, name?: string | null | undefined } | null | undefined } | null | undefined } | null | undefined };

export type OnUpdateChargeEventsSubscriptionVariables = Exact<{
  accountId: Scalars['ID'];
  id: Scalars['ID'];
}>;


export type OnUpdateChargeEventsSubscription = { __typename?: 'Subscriptions', onUpdateChargeEvents?: { __typename?: 'ChargeEventsChange', accountId?: string | null | undefined, id?: string | null | undefined } | null | undefined };

export const AccountFieldsFragmentDoc = gql`
    fragment AccountFields on Account {
  id
  name
  timezone
  customDomain
  adminEmail
  publicBusinessDetails {
    companyName
    website
    phone
    address {
      city
      country
      line1
      line2
      zip
      state
    }
    supportWebsite
    supportEmail
    supportPhone
  }
  branding {
    accentColor
    bgColor
    logoUrl
    iconUrl
  }
  business {
    companyName
    legalName
  }
  status
}
    `;
export const AuthorizedUserFieldsFragmentDoc = gql`
    fragment AuthorizedUserFields on AuthorizedUser {
  userEmail
  id
  accessLevel
  createdAt
  updatedAt
}
    `;
export const AddressFieldsFragmentDoc = gql`
    fragment AddressFields on Address {
  city
  country
  line1
  line2
  zip
  state
}
    `;
export const SessionDetailsFieldsFragmentDoc = gql`
    fragment SessionDetailsFields on SessionDetails {
  ip
  userAgent
  countryCode
  lang
  deviceType
  deviceModel
  browser
  browserVersion
  browserAccept
  browserColorDepth
  browserScreenHeight
  browserScreenWidth
  browserTimezoneOffset
  os
  osVersion
  source
  sourceVersion
}
    `;
export const TraceDetailsFieldsFragmentDoc = gql`
    fragment TraceDetailsFields on TraceDetails {
  ip
  userAgent
  countryCode
  lang
  deviceType
  deviceModel
  browser
  browserVersion
  browserAccept
  os
  osVersion
  source
  sourceVersion
  userId
  userEmail
}
    `;
export const PaymentMethodFieldsFragmentDoc = gql`
    fragment PaymentMethodFields on PaymentMethod {
  method
  card {
    brand
    country
    type
    threeDSecure
    threeDSecureVersion
    last4
    bank
  }
  bizum {
    phoneNumber
  }
  paypal {
    orderId
    payerId
    email
    name
  }
}
    `;
export const ChargeFieldsFragmentDoc = gql`
    fragment ChargeFields on Charge {
  id
  createdAt
  amount
  currency
  customer {
    email
    name
    phone
  }
  paymentMethod {
    method
    card {
      brand
      country
      type
      threeDSecure
      threeDSecureVersion
      tokenizationMethod
      cardholderEmail
      cardholderName
      last4
      bank
    }
    bizum {
      phoneNumber
    }
    paypal {
      orderId
      payerId
      email
      name
    }
  }
  description
  orderId
  refundedAmount
  status
  statusCode
  statusMessage
}
    `;
export const ChargeEventObjectFieldsFragmentDoc = gql`
    fragment ChargeEventObjectFields on ChargeEventObject {
  id
  createdAt
  amount
  currency
  customer {
    email
    name
    phone
  }
  description
  orderId
  status
  statusCode
  statusMessage
}
    `;
export const PaymentFieldsFragmentDoc = gql`
    fragment PaymentFields on Payment {
  id
  createdAt
  amount
  currency
  customer {
    email
    name
    phone
  }
  paymentMethod {
    bizum {
      phoneNumber
    }
  }
  description
  orderId
  refundedAmount
  status
  statusCode
  statusMessage
}
    `;
export const ProviderFieldsFragmentDoc = gql`
    fragment ProviderFields on Provider {
  id
  enabled
  paymentMethod
}
    `;
export const CodeFieldsFragmentDoc = gql`
    fragment CodeFields on Code {
  id
  type
  enabled
}
    `;
export const UpdateAccountDocument = gql`
    mutation UpdateAccount($input: UpdateAccountInput!) {
  updateAccount(input: $input) {
    ...AccountFields
  }
}
    ${AccountFieldsFragmentDoc}`;

/**
 * __useUpdateAccountMutation__
 *
 * To run a mutation, you first call `useUpdateAccountMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateAccountMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateAccountMutation, { data, loading, error }] = useUpdateAccountMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateAccountMutation(baseOptions?: Apollo.MutationHookOptions<UpdateAccountMutation, UpdateAccountMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateAccountMutation, UpdateAccountMutationVariables>(UpdateAccountDocument, options);
      }
export type UpdateAccountMutationHookResult = ReturnType<typeof useUpdateAccountMutation>;
export type UpdateAccountMutationResult = Apollo.MutationResult<UpdateAccountMutation>;
export type UpdateAccountMutationOptions = Apollo.BaseMutationOptions<UpdateAccountMutation, UpdateAccountMutationVariables>;
export const CreateApiKeyDocument = gql`
    mutation CreateApiKey {
  createApiKey
}
    `;

/**
 * __useCreateApiKeyMutation__
 *
 * To run a mutation, you first call `useCreateApiKeyMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateApiKeyMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createApiKeyMutation, { data, loading, error }] = useCreateApiKeyMutation({
 *   variables: {
 *   },
 * });
 */
export function useCreateApiKeyMutation(baseOptions?: Apollo.MutationHookOptions<CreateApiKeyMutation, CreateApiKeyMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateApiKeyMutation, CreateApiKeyMutationVariables>(CreateApiKeyDocument, options);
      }
export type CreateApiKeyMutationHookResult = ReturnType<typeof useCreateApiKeyMutation>;
export type CreateApiKeyMutationResult = Apollo.MutationResult<CreateApiKeyMutation>;
export type CreateApiKeyMutationOptions = Apollo.BaseMutationOptions<CreateApiKeyMutation, CreateApiKeyMutationVariables>;
export const DeleteApiKeyDocument = gql`
    mutation DeleteApiKey {
  deleteApiKey
}
    `;

/**
 * __useDeleteApiKeyMutation__
 *
 * To run a mutation, you first call `useDeleteApiKeyMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteApiKeyMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteApiKeyMutation, { data, loading, error }] = useDeleteApiKeyMutation({
 *   variables: {
 *   },
 * });
 */
export function useDeleteApiKeyMutation(baseOptions?: Apollo.MutationHookOptions<DeleteApiKeyMutation, DeleteApiKeyMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteApiKeyMutation, DeleteApiKeyMutationVariables>(DeleteApiKeyDocument, options);
      }
export type DeleteApiKeyMutationHookResult = ReturnType<typeof useDeleteApiKeyMutation>;
export type DeleteApiKeyMutationResult = Apollo.MutationResult<DeleteApiKeyMutation>;
export type DeleteApiKeyMutationOptions = Apollo.BaseMutationOptions<DeleteApiKeyMutation, DeleteApiKeyMutationVariables>;
export const CreateAuthorizedUserDocument = gql`
    mutation CreateAuthorizedUser($input: CreateAuthorizedUserInput!) {
  createAuthorizedUser(input: $input) {
    ...AuthorizedUserFields
  }
}
    ${AuthorizedUserFieldsFragmentDoc}`;

/**
 * __useCreateAuthorizedUserMutation__
 *
 * To run a mutation, you first call `useCreateAuthorizedUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateAuthorizedUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createAuthorizedUserMutation, { data, loading, error }] = useCreateAuthorizedUserMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateAuthorizedUserMutation(baseOptions?: Apollo.MutationHookOptions<CreateAuthorizedUserMutation, CreateAuthorizedUserMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateAuthorizedUserMutation, CreateAuthorizedUserMutationVariables>(CreateAuthorizedUserDocument, options);
      }
export type CreateAuthorizedUserMutationHookResult = ReturnType<typeof useCreateAuthorizedUserMutation>;
export type CreateAuthorizedUserMutationResult = Apollo.MutationResult<CreateAuthorizedUserMutation>;
export type CreateAuthorizedUserMutationOptions = Apollo.BaseMutationOptions<CreateAuthorizedUserMutation, CreateAuthorizedUserMutationVariables>;
export const DeleteAuthorizedUserDocument = gql`
    mutation DeleteAuthorizedUser($userEmail: String!) {
  deleteAuthorizedUser(userEmail: $userEmail) {
    ...AuthorizedUserFields
  }
}
    ${AuthorizedUserFieldsFragmentDoc}`;

/**
 * __useDeleteAuthorizedUserMutation__
 *
 * To run a mutation, you first call `useDeleteAuthorizedUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteAuthorizedUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteAuthorizedUserMutation, { data, loading, error }] = useDeleteAuthorizedUserMutation({
 *   variables: {
 *      userEmail: // value for 'userEmail'
 *   },
 * });
 */
export function useDeleteAuthorizedUserMutation(baseOptions?: Apollo.MutationHookOptions<DeleteAuthorizedUserMutation, DeleteAuthorizedUserMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteAuthorizedUserMutation, DeleteAuthorizedUserMutationVariables>(DeleteAuthorizedUserDocument, options);
      }
export type DeleteAuthorizedUserMutationHookResult = ReturnType<typeof useDeleteAuthorizedUserMutation>;
export type DeleteAuthorizedUserMutationResult = Apollo.MutationResult<DeleteAuthorizedUserMutation>;
export type DeleteAuthorizedUserMutationOptions = Apollo.BaseMutationOptions<DeleteAuthorizedUserMutation, DeleteAuthorizedUserMutationVariables>;
export const UpdateAuthorizedUserDocument = gql`
    mutation UpdateAuthorizedUser($input: UpdateAuthorizedUserInput!) {
  updateAuthorizedUser(input: $input) {
    ...AuthorizedUserFields
  }
}
    ${AuthorizedUserFieldsFragmentDoc}`;

/**
 * __useUpdateAuthorizedUserMutation__
 *
 * To run a mutation, you first call `useUpdateAuthorizedUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateAuthorizedUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateAuthorizedUserMutation, { data, loading, error }] = useUpdateAuthorizedUserMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateAuthorizedUserMutation(baseOptions?: Apollo.MutationHookOptions<UpdateAuthorizedUserMutation, UpdateAuthorizedUserMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateAuthorizedUserMutation, UpdateAuthorizedUserMutationVariables>(UpdateAuthorizedUserDocument, options);
      }
export type UpdateAuthorizedUserMutationHookResult = ReturnType<typeof useUpdateAuthorizedUserMutation>;
export type UpdateAuthorizedUserMutationResult = Apollo.MutationResult<UpdateAuthorizedUserMutation>;
export type UpdateAuthorizedUserMutationOptions = Apollo.BaseMutationOptions<UpdateAuthorizedUserMutation, UpdateAuthorizedUserMutationVariables>;
export const ExportChargesDocument = gql`
    mutation ExportCharges($filter: SearchableChargeFilterInput, $columns: [String!], $timezone: String) {
  exportCharges(filter: $filter, columns: $columns, timezone: $timezone) {
    downloadUrl
    expiresAt
  }
}
    `;

/**
 * __useExportChargesMutation__
 *
 * To run a mutation, you first call `useExportChargesMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useExportChargesMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [exportChargesMutation, { data, loading, error }] = useExportChargesMutation({
 *   variables: {
 *      filter: // value for 'filter'
 *      columns: // value for 'columns'
 *      timezone: // value for 'timezone'
 *   },
 * });
 */
export function useExportChargesMutation(baseOptions?: Apollo.MutationHookOptions<ExportChargesMutation, ExportChargesMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ExportChargesMutation, ExportChargesMutationVariables>(ExportChargesDocument, options);
      }
export type ExportChargesMutationHookResult = ReturnType<typeof useExportChargesMutation>;
export type ExportChargesMutationResult = Apollo.MutationResult<ExportChargesMutation>;
export type ExportChargesMutationOptions = Apollo.BaseMutationOptions<ExportChargesMutation, ExportChargesMutationVariables>;
export const ExportChargesOldDocument = gql`
    mutation ExportChargesOld($filter: SearchableChargeFilterInput, $timezone: String) {
  exportChargesOld(filter: $filter, timezone: $timezone) {
    downloadUrl
    expiresAt
  }
}
    `;

/**
 * __useExportChargesOldMutation__
 *
 * To run a mutation, you first call `useExportChargesOldMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useExportChargesOldMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [exportChargesOldMutation, { data, loading, error }] = useExportChargesOldMutation({
 *   variables: {
 *      filter: // value for 'filter'
 *      timezone: // value for 'timezone'
 *   },
 * });
 */
export function useExportChargesOldMutation(baseOptions?: Apollo.MutationHookOptions<ExportChargesOldMutation, ExportChargesOldMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ExportChargesOldMutation, ExportChargesOldMutationVariables>(ExportChargesOldDocument, options);
      }
export type ExportChargesOldMutationHookResult = ReturnType<typeof useExportChargesOldMutation>;
export type ExportChargesOldMutationResult = Apollo.MutationResult<ExportChargesOldMutation>;
export type ExportChargesOldMutationOptions = Apollo.BaseMutationOptions<ExportChargesOldMutation, ExportChargesOldMutationVariables>;
export const RefundPaymentDocument = gql`
    mutation RefundPayment($input: RefundPaymentInput!) {
  refundPayment(input: $input) {
    ...PaymentFields
  }
}
    ${PaymentFieldsFragmentDoc}`;

/**
 * __useRefundPaymentMutation__
 *
 * To run a mutation, you first call `useRefundPaymentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRefundPaymentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [refundPaymentMutation, { data, loading, error }] = useRefundPaymentMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useRefundPaymentMutation(baseOptions?: Apollo.MutationHookOptions<RefundPaymentMutation, RefundPaymentMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<RefundPaymentMutation, RefundPaymentMutationVariables>(RefundPaymentDocument, options);
      }
export type RefundPaymentMutationHookResult = ReturnType<typeof useRefundPaymentMutation>;
export type RefundPaymentMutationResult = Apollo.MutationResult<RefundPaymentMutation>;
export type RefundPaymentMutationOptions = Apollo.BaseMutationOptions<RefundPaymentMutation, RefundPaymentMutationVariables>;
export const CreatePaymentDocument = gql`
    mutation CreatePayment($input: CreatePaymentInput!) {
  createPayment(input: $input) {
    ...PaymentFields
  }
}
    ${PaymentFieldsFragmentDoc}`;

/**
 * __useCreatePaymentMutation__
 *
 * To run a mutation, you first call `useCreatePaymentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreatePaymentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createPaymentMutation, { data, loading, error }] = useCreatePaymentMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreatePaymentMutation(baseOptions?: Apollo.MutationHookOptions<CreatePaymentMutation, CreatePaymentMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreatePaymentMutation, CreatePaymentMutationVariables>(CreatePaymentDocument, options);
      }
export type CreatePaymentMutationHookResult = ReturnType<typeof useCreatePaymentMutation>;
export type CreatePaymentMutationResult = Apollo.MutationResult<CreatePaymentMutation>;
export type CreatePaymentMutationOptions = Apollo.BaseMutationOptions<CreatePaymentMutation, CreatePaymentMutationVariables>;
export const CapturePaymentDocument = gql`
    mutation CapturePayment($input: CapturePaymentInput!) {
  capturePayment(input: $input) {
    ...PaymentFields
  }
}
    ${PaymentFieldsFragmentDoc}`;

/**
 * __useCapturePaymentMutation__
 *
 * To run a mutation, you first call `useCapturePaymentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCapturePaymentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [capturePaymentMutation, { data, loading, error }] = useCapturePaymentMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCapturePaymentMutation(baseOptions?: Apollo.MutationHookOptions<CapturePaymentMutation, CapturePaymentMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CapturePaymentMutation, CapturePaymentMutationVariables>(CapturePaymentDocument, options);
      }
export type CapturePaymentMutationHookResult = ReturnType<typeof useCapturePaymentMutation>;
export type CapturePaymentMutationResult = Apollo.MutationResult<CapturePaymentMutation>;
export type CapturePaymentMutationOptions = Apollo.BaseMutationOptions<CapturePaymentMutation, CapturePaymentMutationVariables>;
export const CancelPaymentDocument = gql`
    mutation CancelPayment($input: CancelPaymentInput!) {
  cancelPayment(input: $input) {
    ...PaymentFields
  }
}
    ${PaymentFieldsFragmentDoc}`;

/**
 * __useCancelPaymentMutation__
 *
 * To run a mutation, you first call `useCancelPaymentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCancelPaymentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [cancelPaymentMutation, { data, loading, error }] = useCancelPaymentMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCancelPaymentMutation(baseOptions?: Apollo.MutationHookOptions<CancelPaymentMutation, CancelPaymentMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CancelPaymentMutation, CancelPaymentMutationVariables>(CancelPaymentDocument, options);
      }
export type CancelPaymentMutationHookResult = ReturnType<typeof useCancelPaymentMutation>;
export type CancelPaymentMutationResult = Apollo.MutationResult<CancelPaymentMutation>;
export type CancelPaymentMutationOptions = Apollo.BaseMutationOptions<CancelPaymentMutation, CancelPaymentMutationVariables>;
export const CreatePayPalLinkDocument = gql`
    mutation CreatePayPalLink {
  createPayPalLink {
    link
  }
}
    `;

/**
 * __useCreatePayPalLinkMutation__
 *
 * To run a mutation, you first call `useCreatePayPalLinkMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreatePayPalLinkMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createPayPalLinkMutation, { data, loading, error }] = useCreatePayPalLinkMutation({
 *   variables: {
 *   },
 * });
 */
export function useCreatePayPalLinkMutation(baseOptions?: Apollo.MutationHookOptions<CreatePayPalLinkMutation, CreatePayPalLinkMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreatePayPalLinkMutation, CreatePayPalLinkMutationVariables>(CreatePayPalLinkDocument, options);
      }
export type CreatePayPalLinkMutationHookResult = ReturnType<typeof useCreatePayPalLinkMutation>;
export type CreatePayPalLinkMutationResult = Apollo.MutationResult<CreatePayPalLinkMutation>;
export type CreatePayPalLinkMutationOptions = Apollo.BaseMutationOptions<CreatePayPalLinkMutation, CreatePayPalLinkMutationVariables>;
export const UpdateProviderDocument = gql`
    mutation UpdateProvider($input: UpdateProviderInput!) {
  updateProvider(input: $input) {
    ...ProviderFields
  }
}
    ${ProviderFieldsFragmentDoc}`;

/**
 * __useUpdateProviderMutation__
 *
 * To run a mutation, you first call `useUpdateProviderMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateProviderMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateProviderMutation, { data, loading, error }] = useUpdateProviderMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateProviderMutation(baseOptions?: Apollo.MutationHookOptions<UpdateProviderMutation, UpdateProviderMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateProviderMutation, UpdateProviderMutationVariables>(UpdateProviderDocument, options);
      }
export type UpdateProviderMutationHookResult = ReturnType<typeof useUpdateProviderMutation>;
export type UpdateProviderMutationResult = Apollo.MutationResult<UpdateProviderMutation>;
export type UpdateProviderMutationOptions = Apollo.BaseMutationOptions<UpdateProviderMutation, UpdateProviderMutationVariables>;
export const RequestToPayDocument = gql`
    mutation RequestToPay($input: SendPaymentRequestInput!) {
  sendPaymentRequest(input: $input) {
    ...PaymentFields
  }
}
    ${PaymentFieldsFragmentDoc}`;

/**
 * __useRequestToPayMutation__
 *
 * To run a mutation, you first call `useRequestToPayMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRequestToPayMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [requestToPayMutation, { data, loading, error }] = useRequestToPayMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useRequestToPayMutation(baseOptions?: Apollo.MutationHookOptions<RequestToPayMutation, RequestToPayMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<RequestToPayMutation, RequestToPayMutationVariables>(RequestToPayDocument, options);
      }
export type RequestToPayMutationHookResult = ReturnType<typeof useRequestToPayMutation>;
export type RequestToPayMutationResult = Apollo.MutationResult<RequestToPayMutation>;
export type RequestToPayMutationOptions = Apollo.BaseMutationOptions<RequestToPayMutation, RequestToPayMutationVariables>;
export const AccountDocument = gql`
    query Account {
  account {
    ...AccountFields
  }
}
    ${AccountFieldsFragmentDoc}`;

/**
 * __useAccountQuery__
 *
 * To run a query within a React component, call `useAccountQuery` and pass it any options that fit your needs.
 * When your component renders, `useAccountQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAccountQuery({
 *   variables: {
 *   },
 * });
 */
export function useAccountQuery(baseOptions?: Apollo.QueryHookOptions<AccountQuery, AccountQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<AccountQuery, AccountQueryVariables>(AccountDocument, options);
      }
export function useAccountLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<AccountQuery, AccountQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<AccountQuery, AccountQueryVariables>(AccountDocument, options);
        }
export type AccountQueryHookResult = ReturnType<typeof useAccountQuery>;
export type AccountLazyQueryHookResult = ReturnType<typeof useAccountLazyQuery>;
export type AccountQueryResult = Apollo.QueryResult<AccountQuery, AccountQueryVariables>;
export const LiveAccountDocument = gql`
    query LiveAccount {
  liveAccount {
    id
    status
  }
}
    `;

/**
 * __useLiveAccountQuery__
 *
 * To run a query within a React component, call `useLiveAccountQuery` and pass it any options that fit your needs.
 * When your component renders, `useLiveAccountQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useLiveAccountQuery({
 *   variables: {
 *   },
 * });
 */
export function useLiveAccountQuery(baseOptions?: Apollo.QueryHookOptions<LiveAccountQuery, LiveAccountQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<LiveAccountQuery, LiveAccountQueryVariables>(LiveAccountDocument, options);
      }
export function useLiveAccountLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<LiveAccountQuery, LiveAccountQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<LiveAccountQuery, LiveAccountQueryVariables>(LiveAccountDocument, options);
        }
export type LiveAccountQueryHookResult = ReturnType<typeof useLiveAccountQuery>;
export type LiveAccountLazyQueryHookResult = ReturnType<typeof useLiveAccountLazyQuery>;
export type LiveAccountQueryResult = Apollo.QueryResult<LiveAccountQuery, LiveAccountQueryVariables>;
export const AuthorizedUsersDocument = gql`
    query AuthorizedUsers($token: String, $pageSize: Int) {
  authorizedUsers(token: $token, pageSize: $pageSize) {
    items {
      ...AuthorizedUserFields
    }
    nextToken
  }
}
    ${AuthorizedUserFieldsFragmentDoc}`;

/**
 * __useAuthorizedUsersQuery__
 *
 * To run a query within a React component, call `useAuthorizedUsersQuery` and pass it any options that fit your needs.
 * When your component renders, `useAuthorizedUsersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAuthorizedUsersQuery({
 *   variables: {
 *      token: // value for 'token'
 *      pageSize: // value for 'pageSize'
 *   },
 * });
 */
export function useAuthorizedUsersQuery(baseOptions?: Apollo.QueryHookOptions<AuthorizedUsersQuery, AuthorizedUsersQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<AuthorizedUsersQuery, AuthorizedUsersQueryVariables>(AuthorizedUsersDocument, options);
      }
export function useAuthorizedUsersLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<AuthorizedUsersQuery, AuthorizedUsersQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<AuthorizedUsersQuery, AuthorizedUsersQueryVariables>(AuthorizedUsersDocument, options);
        }
export type AuthorizedUsersQueryHookResult = ReturnType<typeof useAuthorizedUsersQuery>;
export type AuthorizedUsersLazyQueryHookResult = ReturnType<typeof useAuthorizedUsersLazyQuery>;
export type AuthorizedUsersQueryResult = Apollo.QueryResult<AuthorizedUsersQuery, AuthorizedUsersQueryVariables>;
export const ChargesDocument = gql`
    query Charges($filter: SearchableChargeFilterInput, $size: Int, $from: Int) {
  charges(filter: $filter, size: $size, from: $from) {
    items {
      ...ChargeFields
    }
    total
  }
}
    ${ChargeFieldsFragmentDoc}`;

/**
 * __useChargesQuery__
 *
 * To run a query within a React component, call `useChargesQuery` and pass it any options that fit your needs.
 * When your component renders, `useChargesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useChargesQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *      size: // value for 'size'
 *      from: // value for 'from'
 *   },
 * });
 */
export function useChargesQuery(baseOptions?: Apollo.QueryHookOptions<ChargesQuery, ChargesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ChargesQuery, ChargesQueryVariables>(ChargesDocument, options);
      }
export function useChargesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ChargesQuery, ChargesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ChargesQuery, ChargesQueryVariables>(ChargesDocument, options);
        }
export type ChargesQueryHookResult = ReturnType<typeof useChargesQuery>;
export type ChargesLazyQueryHookResult = ReturnType<typeof useChargesLazyQuery>;
export type ChargesQueryResult = Apollo.QueryResult<ChargesQuery, ChargesQueryVariables>;
export const ChargeDocument = gql`
    query Charge($id: ID!) {
  charge(id: $id) {
    ...ChargeFields
  }
}
    ${ChargeFieldsFragmentDoc}`;

/**
 * __useChargeQuery__
 *
 * To run a query within a React component, call `useChargeQuery` and pass it any options that fit your needs.
 * When your component renders, `useChargeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useChargeQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useChargeQuery(baseOptions: Apollo.QueryHookOptions<ChargeQuery, ChargeQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ChargeQuery, ChargeQueryVariables>(ChargeDocument, options);
      }
export function useChargeLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ChargeQuery, ChargeQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ChargeQuery, ChargeQueryVariables>(ChargeDocument, options);
        }
export type ChargeQueryHookResult = ReturnType<typeof useChargeQuery>;
export type ChargeLazyQueryHookResult = ReturnType<typeof useChargeLazyQuery>;
export type ChargeQueryResult = Apollo.QueryResult<ChargeQuery, ChargeQueryVariables>;
export const SucceededDateRangeKpiDocument = gql`
    query SucceededDateRangeKpi($start: Int, $end: Int, $interval: Interval, $timezone: String) {
  chargesDateRangeKPI(
    start: $start
    end: $end
    interval: $interval
    timezone: $timezone
  ) {
    currency
    total {
      succeededAmount
      succeededCount
    }
  }
}
    `;

/**
 * __useSucceededDateRangeKpiQuery__
 *
 * To run a query within a React component, call `useSucceededDateRangeKpiQuery` and pass it any options that fit your needs.
 * When your component renders, `useSucceededDateRangeKpiQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSucceededDateRangeKpiQuery({
 *   variables: {
 *      start: // value for 'start'
 *      end: // value for 'end'
 *      interval: // value for 'interval'
 *      timezone: // value for 'timezone'
 *   },
 * });
 */
export function useSucceededDateRangeKpiQuery(baseOptions?: Apollo.QueryHookOptions<SucceededDateRangeKpiQuery, SucceededDateRangeKpiQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<SucceededDateRangeKpiQuery, SucceededDateRangeKpiQueryVariables>(SucceededDateRangeKpiDocument, options);
      }
export function useSucceededDateRangeKpiLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<SucceededDateRangeKpiQuery, SucceededDateRangeKpiQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<SucceededDateRangeKpiQuery, SucceededDateRangeKpiQueryVariables>(SucceededDateRangeKpiDocument, options);
        }
export type SucceededDateRangeKpiQueryHookResult = ReturnType<typeof useSucceededDateRangeKpiQuery>;
export type SucceededDateRangeKpiLazyQueryHookResult = ReturnType<typeof useSucceededDateRangeKpiLazyQuery>;
export type SucceededDateRangeKpiQueryResult = Apollo.QueryResult<SucceededDateRangeKpiQuery, SucceededDateRangeKpiQueryVariables>;
export const ChargesDateRangeKpiDocument = gql`
    query ChargesDateRangeKpi($start: Int, $end: Int, $interval: Interval, $timezone: String) {
  chargesDateRangeKPI(
    start: $start
    end: $end
    interval: $interval
    timezone: $timezone
  ) {
    currency
    total {
      succeededAmount
      succeededCount
      refundedCount
      failedCount
    }
  }
}
    `;

/**
 * __useChargesDateRangeKpiQuery__
 *
 * To run a query within a React component, call `useChargesDateRangeKpiQuery` and pass it any options that fit your needs.
 * When your component renders, `useChargesDateRangeKpiQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useChargesDateRangeKpiQuery({
 *   variables: {
 *      start: // value for 'start'
 *      end: // value for 'end'
 *      interval: // value for 'interval'
 *      timezone: // value for 'timezone'
 *   },
 * });
 */
export function useChargesDateRangeKpiQuery(baseOptions?: Apollo.QueryHookOptions<ChargesDateRangeKpiQuery, ChargesDateRangeKpiQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ChargesDateRangeKpiQuery, ChargesDateRangeKpiQueryVariables>(ChargesDateRangeKpiDocument, options);
      }
export function useChargesDateRangeKpiLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ChargesDateRangeKpiQuery, ChargesDateRangeKpiQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ChargesDateRangeKpiQuery, ChargesDateRangeKpiQueryVariables>(ChargesDateRangeKpiDocument, options);
        }
export type ChargesDateRangeKpiQueryHookResult = ReturnType<typeof useChargesDateRangeKpiQuery>;
export type ChargesDateRangeKpiLazyQueryHookResult = ReturnType<typeof useChargesDateRangeKpiLazyQuery>;
export type ChargesDateRangeKpiQueryResult = Apollo.QueryResult<ChargesDateRangeKpiQuery, ChargesDateRangeKpiQueryVariables>;
export const ChargeEventsDocument = gql`
    query ChargeEvents($chargeId: ID!, $size: Int, $from: Int) {
  chargeEvents(chargeId: $chargeId, size: $size, from: $from) {
    items {
      id
      type
      accountId
      livemode
      objectId
      objectType
      createdAt
      object {
        ...ChargeEventObjectFields
      }
    }
    total
  }
}
    ${ChargeEventObjectFieldsFragmentDoc}`;

/**
 * __useChargeEventsQuery__
 *
 * To run a query within a React component, call `useChargeEventsQuery` and pass it any options that fit your needs.
 * When your component renders, `useChargeEventsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useChargeEventsQuery({
 *   variables: {
 *      chargeId: // value for 'chargeId'
 *      size: // value for 'size'
 *      from: // value for 'from'
 *   },
 * });
 */
export function useChargeEventsQuery(baseOptions: Apollo.QueryHookOptions<ChargeEventsQuery, ChargeEventsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ChargeEventsQuery, ChargeEventsQueryVariables>(ChargeEventsDocument, options);
      }
export function useChargeEventsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ChargeEventsQuery, ChargeEventsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ChargeEventsQuery, ChargeEventsQueryVariables>(ChargeEventsDocument, options);
        }
export type ChargeEventsQueryHookResult = ReturnType<typeof useChargeEventsQuery>;
export type ChargeEventsLazyQueryHookResult = ReturnType<typeof useChargeEventsLazyQuery>;
export type ChargeEventsQueryResult = Apollo.QueryResult<ChargeEventsQuery, ChargeEventsQueryVariables>;
export const EventsDocument = gql`
    query Events($filter: SearchableEventFilterInput, $size: Int, $from: Int) {
  events(filter: $filter, size: $size, from: $from) {
    items {
      id
      type
      accountId
      livemode
      objectId
      objectType
      createdAt
      object {
        ...ChargeEventObjectFields
      }
    }
    total
  }
}
    ${ChargeEventObjectFieldsFragmentDoc}`;

/**
 * __useEventsQuery__
 *
 * To run a query within a React component, call `useEventsQuery` and pass it any options that fit your needs.
 * When your component renders, `useEventsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useEventsQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *      size: // value for 'size'
 *      from: // value for 'from'
 *   },
 * });
 */
export function useEventsQuery(baseOptions?: Apollo.QueryHookOptions<EventsQuery, EventsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<EventsQuery, EventsQueryVariables>(EventsDocument, options);
      }
export function useEventsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<EventsQuery, EventsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<EventsQuery, EventsQueryVariables>(EventsDocument, options);
        }
export type EventsQueryHookResult = ReturnType<typeof useEventsQuery>;
export type EventsLazyQueryHookResult = ReturnType<typeof useEventsLazyQuery>;
export type EventsQueryResult = Apollo.QueryResult<EventsQuery, EventsQueryVariables>;
export const CountriesDocument = gql`
    query Countries {
  countries {
    supportedCountries {
      name
      phoneCode
      code
    }
    currentCountry {
      name
      phoneCode
      code
    }
    currentCountryCode
    isSupported
  }
}
    `;

/**
 * __useCountriesQuery__
 *
 * To run a query within a React component, call `useCountriesQuery` and pass it any options that fit your needs.
 * When your component renders, `useCountriesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCountriesQuery({
 *   variables: {
 *   },
 * });
 */
export function useCountriesQuery(baseOptions?: Apollo.QueryHookOptions<CountriesQuery, CountriesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<CountriesQuery, CountriesQueryVariables>(CountriesDocument, options);
      }
export function useCountriesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<CountriesQuery, CountriesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<CountriesQuery, CountriesQueryVariables>(CountriesDocument, options);
        }
export type CountriesQueryHookResult = ReturnType<typeof useCountriesQuery>;
export type CountriesLazyQueryHookResult = ReturnType<typeof useCountriesLazyQuery>;
export type CountriesQueryResult = Apollo.QueryResult<CountriesQuery, CountriesQueryVariables>;
export const PaymentEmailPreviewDocument = gql`
    query PaymentEmailPreview($paymentId: ID!, $emailType: PaymentEmailType) {
  paymentEmailPreview(paymentId: $paymentId, emailType: $emailType)
}
    `;

/**
 * __usePaymentEmailPreviewQuery__
 *
 * To run a query within a React component, call `usePaymentEmailPreviewQuery` and pass it any options that fit your needs.
 * When your component renders, `usePaymentEmailPreviewQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePaymentEmailPreviewQuery({
 *   variables: {
 *      paymentId: // value for 'paymentId'
 *      emailType: // value for 'emailType'
 *   },
 * });
 */
export function usePaymentEmailPreviewQuery(baseOptions: Apollo.QueryHookOptions<PaymentEmailPreviewQuery, PaymentEmailPreviewQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<PaymentEmailPreviewQuery, PaymentEmailPreviewQueryVariables>(PaymentEmailPreviewDocument, options);
      }
export function usePaymentEmailPreviewLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<PaymentEmailPreviewQuery, PaymentEmailPreviewQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<PaymentEmailPreviewQuery, PaymentEmailPreviewQueryVariables>(PaymentEmailPreviewDocument, options);
        }
export type PaymentEmailPreviewQueryHookResult = ReturnType<typeof usePaymentEmailPreviewQuery>;
export type PaymentEmailPreviewLazyQueryHookResult = ReturnType<typeof usePaymentEmailPreviewLazyQuery>;
export type PaymentEmailPreviewQueryResult = Apollo.QueryResult<PaymentEmailPreviewQuery, PaymentEmailPreviewQueryVariables>;
export const ProvidersDocument = gql`
    query Providers {
  providers {
    ...ProviderFields
  }
}
    ${ProviderFieldsFragmentDoc}`;

/**
 * __useProvidersQuery__
 *
 * To run a query within a React component, call `useProvidersQuery` and pass it any options that fit your needs.
 * When your component renders, `useProvidersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useProvidersQuery({
 *   variables: {
 *   },
 * });
 */
export function useProvidersQuery(baseOptions?: Apollo.QueryHookOptions<ProvidersQuery, ProvidersQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ProvidersQuery, ProvidersQueryVariables>(ProvidersDocument, options);
      }
export function useProvidersLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ProvidersQuery, ProvidersQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ProvidersQuery, ProvidersQueryVariables>(ProvidersDocument, options);
        }
export type ProvidersQueryHookResult = ReturnType<typeof useProvidersQuery>;
export type ProvidersLazyQueryHookResult = ReturnType<typeof useProvidersLazyQuery>;
export type ProvidersQueryResult = Apollo.QueryResult<ProvidersQuery, ProvidersQueryVariables>;
export const ProviderDocument = gql`
    query Provider($id: ID!) {
  provider(id: $id) {
    ...ProviderFields
  }
}
    ${ProviderFieldsFragmentDoc}`;

/**
 * __useProviderQuery__
 *
 * To run a query within a React component, call `useProviderQuery` and pass it any options that fit your needs.
 * When your component renders, `useProviderQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useProviderQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useProviderQuery(baseOptions: Apollo.QueryHookOptions<ProviderQuery, ProviderQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ProviderQuery, ProviderQueryVariables>(ProviderDocument, options);
      }
export function useProviderLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ProviderQuery, ProviderQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ProviderQuery, ProviderQueryVariables>(ProviderDocument, options);
        }
export type ProviderQueryHookResult = ReturnType<typeof useProviderQuery>;
export type ProviderLazyQueryHookResult = ReturnType<typeof useProviderLazyQuery>;
export type ProviderQueryResult = Apollo.QueryResult<ProviderQuery, ProviderQueryVariables>;
export const AvailablePaymentMethodsDocument = gql`
    query AvailablePaymentMethods {
  availablePaymentMethods {
    paymentMethod
    configured
    enabled
  }
}
    `;

/**
 * __useAvailablePaymentMethodsQuery__
 *
 * To run a query within a React component, call `useAvailablePaymentMethodsQuery` and pass it any options that fit your needs.
 * When your component renders, `useAvailablePaymentMethodsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAvailablePaymentMethodsQuery({
 *   variables: {
 *   },
 * });
 */
export function useAvailablePaymentMethodsQuery(baseOptions?: Apollo.QueryHookOptions<AvailablePaymentMethodsQuery, AvailablePaymentMethodsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<AvailablePaymentMethodsQuery, AvailablePaymentMethodsQueryVariables>(AvailablePaymentMethodsDocument, options);
      }
export function useAvailablePaymentMethodsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<AvailablePaymentMethodsQuery, AvailablePaymentMethodsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<AvailablePaymentMethodsQuery, AvailablePaymentMethodsQueryVariables>(AvailablePaymentMethodsDocument, options);
        }
export type AvailablePaymentMethodsQueryHookResult = ReturnType<typeof useAvailablePaymentMethodsQuery>;
export type AvailablePaymentMethodsLazyQueryHookResult = ReturnType<typeof useAvailablePaymentMethodsLazyQuery>;
export type AvailablePaymentMethodsQueryResult = Apollo.QueryResult<AvailablePaymentMethodsQuery, AvailablePaymentMethodsQueryVariables>;
export const StoresDocument = gql`
    query Stores($token: String, $pageSize: Int) {
  stores(token: $token, pageSize: $pageSize) {
    items {
      id
      name
    }
    nextToken
  }
}
    `;

/**
 * __useStoresQuery__
 *
 * To run a query within a React component, call `useStoresQuery` and pass it any options that fit your needs.
 * When your component renders, `useStoresQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useStoresQuery({
 *   variables: {
 *      token: // value for 'token'
 *      pageSize: // value for 'pageSize'
 *   },
 * });
 */
export function useStoresQuery(baseOptions?: Apollo.QueryHookOptions<StoresQuery, StoresQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<StoresQuery, StoresQueryVariables>(StoresDocument, options);
      }
export function useStoresLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<StoresQuery, StoresQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<StoresQuery, StoresQueryVariables>(StoresDocument, options);
        }
export type StoresQueryHookResult = ReturnType<typeof useStoresQuery>;
export type StoresLazyQueryHookResult = ReturnType<typeof useStoresLazyQuery>;
export type StoresQueryResult = Apollo.QueryResult<StoresQuery, StoresQueryVariables>;
export const PointsOfSaleDocument = gql`
    query PointsOfSale($storeId: ID, $token: String, $pageSize: Int) {
  pointsOfSale(storeId: $storeId, token: $token, pageSize: $pageSize) {
    items {
      id
      storeId
      number
      name
      currentPaymentId
      manualAmount
    }
    nextToken
  }
}
    `;

/**
 * __usePointsOfSaleQuery__
 *
 * To run a query within a React component, call `usePointsOfSaleQuery` and pass it any options that fit your needs.
 * When your component renders, `usePointsOfSaleQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePointsOfSaleQuery({
 *   variables: {
 *      storeId: // value for 'storeId'
 *      token: // value for 'token'
 *      pageSize: // value for 'pageSize'
 *   },
 * });
 */
export function usePointsOfSaleQuery(baseOptions?: Apollo.QueryHookOptions<PointsOfSaleQuery, PointsOfSaleQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<PointsOfSaleQuery, PointsOfSaleQueryVariables>(PointsOfSaleDocument, options);
      }
export function usePointsOfSaleLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<PointsOfSaleQuery, PointsOfSaleQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<PointsOfSaleQuery, PointsOfSaleQueryVariables>(PointsOfSaleDocument, options);
        }
export type PointsOfSaleQueryHookResult = ReturnType<typeof usePointsOfSaleQuery>;
export type PointsOfSaleLazyQueryHookResult = ReturnType<typeof usePointsOfSaleLazyQuery>;
export type PointsOfSaleQueryResult = Apollo.QueryResult<PointsOfSaleQuery, PointsOfSaleQueryVariables>;
export const CodesDocument = gql`
    query Codes {
  codes {
    ...CodeFields
  }
}
    ${CodeFieldsFragmentDoc}`;

/**
 * __useCodesQuery__
 *
 * To run a query within a React component, call `useCodesQuery` and pass it any options that fit your needs.
 * When your component renders, `useCodesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCodesQuery({
 *   variables: {
 *   },
 * });
 */
export function useCodesQuery(baseOptions?: Apollo.QueryHookOptions<CodesQuery, CodesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<CodesQuery, CodesQueryVariables>(CodesDocument, options);
      }
export function useCodesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<CodesQuery, CodesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<CodesQuery, CodesQueryVariables>(CodesDocument, options);
        }
export type CodesQueryHookResult = ReturnType<typeof useCodesQuery>;
export type CodesLazyQueryHookResult = ReturnType<typeof useCodesLazyQuery>;
export type CodesQueryResult = Apollo.QueryResult<CodesQuery, CodesQueryVariables>;
export const ThemeDocument = gql`
    query Theme {
  account {
    branding {
      accentColor
      iconUrl
    }
  }
}
    `;

/**
 * __useThemeQuery__
 *
 * To run a query within a React component, call `useThemeQuery` and pass it any options that fit your needs.
 * When your component renders, `useThemeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useThemeQuery({
 *   variables: {
 *   },
 * });
 */
export function useThemeQuery(baseOptions?: Apollo.QueryHookOptions<ThemeQuery, ThemeQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ThemeQuery, ThemeQueryVariables>(ThemeDocument, options);
      }
export function useThemeLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ThemeQuery, ThemeQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ThemeQuery, ThemeQueryVariables>(ThemeDocument, options);
        }
export type ThemeQueryHookResult = ReturnType<typeof useThemeQuery>;
export type ThemeLazyQueryHookResult = ReturnType<typeof useThemeLazyQuery>;
export type ThemeQueryResult = Apollo.QueryResult<ThemeQuery, ThemeQueryVariables>;
export const OnUpdateAnalyticsDocument = gql`
    subscription OnUpdateAnalytics($accountId: ID!) {
  onUpdateAnalytics(accountId: $accountId) {
    accountId
  }
}
    `;

/**
 * __useOnUpdateAnalyticsSubscription__
 *
 * To run a query within a React component, call `useOnUpdateAnalyticsSubscription` and pass it any options that fit your needs.
 * When your component renders, `useOnUpdateAnalyticsSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOnUpdateAnalyticsSubscription({
 *   variables: {
 *      accountId: // value for 'accountId'
 *   },
 * });
 */
export function useOnUpdateAnalyticsSubscription(baseOptions: Apollo.SubscriptionHookOptions<OnUpdateAnalyticsSubscription, OnUpdateAnalyticsSubscriptionVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useSubscription<OnUpdateAnalyticsSubscription, OnUpdateAnalyticsSubscriptionVariables>(OnUpdateAnalyticsDocument, options);
      }
export type OnUpdateAnalyticsSubscriptionHookResult = ReturnType<typeof useOnUpdateAnalyticsSubscription>;
export type OnUpdateAnalyticsSubscriptionResult = Apollo.SubscriptionResult<OnUpdateAnalyticsSubscription>;
export const OnUpdateEventsDocument = gql`
    subscription OnUpdateEvents($accountId: ID!) {
  onUpdateEvents(accountId: $accountId) {
    accountId
  }
}
    `;

/**
 * __useOnUpdateEventsSubscription__
 *
 * To run a query within a React component, call `useOnUpdateEventsSubscription` and pass it any options that fit your needs.
 * When your component renders, `useOnUpdateEventsSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOnUpdateEventsSubscription({
 *   variables: {
 *      accountId: // value for 'accountId'
 *   },
 * });
 */
export function useOnUpdateEventsSubscription(baseOptions: Apollo.SubscriptionHookOptions<OnUpdateEventsSubscription, OnUpdateEventsSubscriptionVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useSubscription<OnUpdateEventsSubscription, OnUpdateEventsSubscriptionVariables>(OnUpdateEventsDocument, options);
      }
export type OnUpdateEventsSubscriptionHookResult = ReturnType<typeof useOnUpdateEventsSubscription>;
export type OnUpdateEventsSubscriptionResult = Apollo.SubscriptionResult<OnUpdateEventsSubscription>;
export const OnUpdateChargesDocument = gql`
    subscription OnUpdateCharges($accountId: ID!) {
  onUpdateCharges(accountId: $accountId) {
    accountId
  }
}
    `;

/**
 * __useOnUpdateChargesSubscription__
 *
 * To run a query within a React component, call `useOnUpdateChargesSubscription` and pass it any options that fit your needs.
 * When your component renders, `useOnUpdateChargesSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOnUpdateChargesSubscription({
 *   variables: {
 *      accountId: // value for 'accountId'
 *   },
 * });
 */
export function useOnUpdateChargesSubscription(baseOptions: Apollo.SubscriptionHookOptions<OnUpdateChargesSubscription, OnUpdateChargesSubscriptionVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useSubscription<OnUpdateChargesSubscription, OnUpdateChargesSubscriptionVariables>(OnUpdateChargesDocument, options);
      }
export type OnUpdateChargesSubscriptionHookResult = ReturnType<typeof useOnUpdateChargesSubscription>;
export type OnUpdateChargesSubscriptionResult = Apollo.SubscriptionResult<OnUpdateChargesSubscription>;
export const OnUpdateChargeDocument = gql`
    subscription OnUpdateCharge($accountId: ID!, $id: ID!) {
  onUpdateCharge(accountId: $accountId, id: $id) {
    ...ChargeFields
  }
}
    ${ChargeFieldsFragmentDoc}`;

/**
 * __useOnUpdateChargeSubscription__
 *
 * To run a query within a React component, call `useOnUpdateChargeSubscription` and pass it any options that fit your needs.
 * When your component renders, `useOnUpdateChargeSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOnUpdateChargeSubscription({
 *   variables: {
 *      accountId: // value for 'accountId'
 *      id: // value for 'id'
 *   },
 * });
 */
export function useOnUpdateChargeSubscription(baseOptions: Apollo.SubscriptionHookOptions<OnUpdateChargeSubscription, OnUpdateChargeSubscriptionVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useSubscription<OnUpdateChargeSubscription, OnUpdateChargeSubscriptionVariables>(OnUpdateChargeDocument, options);
      }
export type OnUpdateChargeSubscriptionHookResult = ReturnType<typeof useOnUpdateChargeSubscription>;
export type OnUpdateChargeSubscriptionResult = Apollo.SubscriptionResult<OnUpdateChargeSubscription>;
export const OnUpdateChargeEventsDocument = gql`
    subscription OnUpdateChargeEvents($accountId: ID!, $id: ID!) {
  onUpdateChargeEvents(accountId: $accountId, id: $id) {
    accountId
    id
  }
}
    `;

/**
 * __useOnUpdateChargeEventsSubscription__
 *
 * To run a query within a React component, call `useOnUpdateChargeEventsSubscription` and pass it any options that fit your needs.
 * When your component renders, `useOnUpdateChargeEventsSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOnUpdateChargeEventsSubscription({
 *   variables: {
 *      accountId: // value for 'accountId'
 *      id: // value for 'id'
 *   },
 * });
 */
export function useOnUpdateChargeEventsSubscription(baseOptions: Apollo.SubscriptionHookOptions<OnUpdateChargeEventsSubscription, OnUpdateChargeEventsSubscriptionVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useSubscription<OnUpdateChargeEventsSubscription, OnUpdateChargeEventsSubscriptionVariables>(OnUpdateChargeEventsDocument, options);
      }
export type OnUpdateChargeEventsSubscriptionHookResult = ReturnType<typeof useOnUpdateChargeEventsSubscription>;
export type OnUpdateChargeEventsSubscriptionResult = Apollo.SubscriptionResult<OnUpdateChargeEventsSubscription>;