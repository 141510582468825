import {Box, Button, CloseIcon} from 'native-base';
import React from 'react';
import {Ionicons} from '@expo/vector-icons';
import {t} from 'react-native-tailwindcss';
import {Platform} from 'react-native';

type Props = {onPress?: () => void} & (
  | {
      icon?: keyof typeof Ionicons.glyphMap;
      displayDot?: boolean;
      text?: never;
    }
  | {
      text?: string;
      icon?: never;
      displayDot?: never;
    }
);

export function HeaderButton({onPress, icon, text, displayDot, left}: Props & {left?: boolean}) {
  const margin = Platform.OS === 'web' || left === undefined ? undefined : left ? t._mL2 : t._mR2;

  return (
    <Button variant="unstyled" onPress={onPress} style={margin}>
      {icon &&
        (icon === 'close' ? (
          <CloseIcon size="5" color="black" />
        ) : (
          <Ionicons name={icon} size={24} />
        ))}
      {text ? text : undefined}
      {displayDot && (
        <Box
          style={[
            {height: 10, width: 10, borderRadius: 5, top: 0, right: -4},
            t.bgGreen600,
            t.absolute
          ]}
        />
      )}
    </Button>
  );
}

export function HeaderButtonRight(props: Props) {
  return <HeaderButton {...props} left={false} />;
}

export function HeaderButtonLeft(props: Props) {
  return <HeaderButton {...props} left />;
}
