import {NavigationProp, useNavigation} from '@react-navigation/native';
import {Text, Box, HStack, Flex, ShareIcon, Pressable, useTheme} from 'native-base';
import React, {useEffect, useMemo, useRef, useState} from 'react';
import {GestureResponderEvent, View} from 'react-native';
import {Swipeable} from 'react-native-gesture-handler';
import {t} from 'react-native-tailwindcss';
import {ChargeFieldsFragment, ChargeStatus} from '../api/generated';
import {usePaymentUrl} from '../hooks/usePaymentUrl';
import {formatMoney, formatPhone} from '../lib/formatters';
import moment from '../lib/moment';
import {canBeRefunded} from '../lib/paymentUtils';
import {getStatusStyle, shareUrl} from '../lib/utils';
import {Nav, NavParams} from '../screens/navigation';
import {PaymentStatusIcon} from './PaymentStatusIcon';
import {Ionicons} from '@expo/vector-icons';
import {useOutsideClick} from '../hooks/useOutsideClick';
import {useTranslation} from 'react-i18next';

type Props = {
  item: ChargeFieldsFragment;
  onPress?: (event: GestureResponderEvent) => void;
};

const getTitle = (item: ChargeFieldsFragment) => {
  if (item.customer?.email) {
    return item.customer.email;
  }
  if (item.customer?.phone) {
    return formatPhone(item.customer.phone);
  }
  if (item.paymentMethod?.bizum?.phoneNumber) {
    return formatPhone(item.paymentMethod.bizum.phoneNumber);
  }
  if (item.paymentMethod?.card?.cardholderName) {
    return item.paymentMethod.card.cardholderName;
  }
  if (item.paymentMethod?.card?.cardholderEmail) {
    return item.paymentMethod.card.cardholderEmail;
  }
  return item.orderId;
};

const ICON_SIZE = 30;

export const PaymentItem: React.FC<Props> = ({item, onPress}) => {
  const isPending = item.status === ChargeStatus.PENDING;
  const navigation = useNavigation<NavigationProp<NavParams>>();
  const paymentUrl = usePaymentUrl(item.id);
  const canRefund = canBeRefunded(item);
  const [open, setOpen] = useState(false);
  const {colors} = useTheme();
  const ref = useRef<Swipeable>(null);
  const containerRef = useRef<View>(null);
  const {setCloseItemCallback, resetClosableItem} = useOutsideClick();
  const i18n = useTranslation();

  const swipeOptions = useMemo(() => {
    const options = [
      {
        key: 'share',
        label: i18n.t('share'),
        color: colors.blue[600],
        icon: <ShareIcon color={colors.white} style={t.mR2} size={ICON_SIZE} />,
        onPress: () => {
          shareUrl(isPending ? paymentUrl : `${paymentUrl}/receipt`);
        }
      }
    ];

    if (canRefund) {
      options.unshift({
        key: 'refund',
        label: i18n.t('refund'),
        color: colors.gray[600],
        icon: <Ionicons color={colors.white} size={ICON_SIZE} name="arrow-undo-circle-outline" />,
        onPress: () => {
          navigation.navigate(Nav.RefundScreen, {paymentId: item.id});
        }
      });
    }

    if (isPending) {
      options.push({
        key: 'qr',
        label: i18n.t('QRCode'),
        color: colors.green[600],
        icon: <Ionicons color={colors.white} size={ICON_SIZE} name="qr-code" />,
        onPress: () => {
          navigation.navigate(Nav.PaymentQRScreen, {paymentId: item.id});
        }
      });
    }

    return options;
  }, [isPending, navigation, canRefund, item.id, paymentUrl, colors, i18n]);

  return (
    <View ref={containerRef}>
      <Swipeable
        ref={ref}
        onSwipeableWillClose={() => {
          setOpen(false);
          resetClosableItem();
        }}
        onSwipeableWillOpen={() => {
          if (!open) {
            setOpen(true);
            setCloseItemCallback(() => ref.current?.close(), containerRef);
          }
        }}
        overshootRight={false}
        renderRightActions={() => (
          <HStack>
            {swipeOptions.map((swipeOption) => (
              <Pressable
                key={swipeOption.key}
                onPress={() => {
                  swipeOption.onPress();
                  ref.current?.close();
                }}>
                {({isPressed}) => (
                  <Box
                    opacity={isPressed ? 0.5 : 1}
                    bgColor={swipeOption.color}
                    style={[t.p1]}
                    minWidth={90}
                    height={'100%'}
                    justifyContent="center"
                    borderBottomWidth="1"
                    borderColor="gray.200"
                    alignItems={'center'}>
                    {
                      <>
                        {swipeOption.icon}
                        <Text color={colors.white}>{swipeOption.label}</Text>
                      </>
                    }
                  </Box>
                )}
              </Pressable>
            ))}
          </HStack>
        )}>
        <Pressable
          onPress={(e) => {
            if (open) return; // prevents press when swiping (web)
            onPress?.(e);
          }}>
          <Flex
            flex="1"
            direction="row"
            borderColor="gray.200"
            key={item.id}
            style={[t.pT3, t.pL5, t.bgWhite, t.itemsCenter]}>
            <PaymentStatusIcon
              status={item.status}
              style={[t.text4xl, t.pB1, getStatusStyle(item.status)]}
            />
            <Flex
              flex="1"
              direction="row"
              borderBottomWidth="1"
              borderColor="gray.200"
              style={[t.mL4, t.pB3, t.pR5]}
              justifyContent="space-between"
              alignItems="center">
              <Box flex="1">
                <Text
                  bold
                  isTruncated
                  numberOfLines={1}
                  style={[t.textLg, t.textBlack, {minHeight: 22}]}>
                  {getTitle(item)}
                </Text>
                <Text style={[t.textGray600]}>{moment.unix(item.createdAt).format('lll')}</Text>
              </Box>
              <Text style={[t.textXl, t.fontBold, getStatusStyle(item.status)]}>
                {formatMoney(item.amount! / 100, item.currency)}
              </Text>
            </Flex>
          </Flex>
        </Pressable>
      </Swipeable>
    </View>
  );
};
