import AsyncStorage from '@react-native-async-storage/async-storage';
import {
  authenticateAsync,
  AuthenticationType,
  isEnrolledAsync,
  supportedAuthenticationTypesAsync
} from 'expo-local-authentication';
import {Platform} from 'react-native';
import i18n from '../lib/i18n';
import {ASYNC_STORAGE} from '../storage/storageKeys';

export async function promptBiometry(message: string) {
  if (Platform.OS === 'web') return;
  const result = await authenticateAsync({
    promptMessage: message,
    disableDeviceFallback: true,
    cancelLabel: i18n.t('cancel')
  });
  return !!result.success;
}

export async function hasEnrolledBiometry() {
  return Platform.OS !== 'web' && (await isEnrolledAsync());
}

export async function canAuthenticateUsingBiometry() {
  if (Platform.OS === 'web') return false;
  try {
    const settings = await AsyncStorage.getItem(ASYNC_STORAGE.APP_SETTINGS);
    const useBiometrics = settings ? JSON.parse(settings)?.useBiometricAuth : false;
    if (useBiometrics) {
      const isEnrolled = await isEnrolledAsync();
      if (isEnrolled) {
        return true;
      }
    }
  } catch {
    // do nothing
  }
  return false;
}

export enum BiometryType {
  FINGERPRINT = 'FINGERPRINT',
  BIOMETRICS = 'BIOMETRICS',
  TOUCH_ID = 'TOUCH_ID',
  FACE_ID = 'FACE_ID'
}

export async function getBiometryType() {
  if (Platform.OS === 'web') return undefined;
  if (Platform.OS === 'android') {
    // prior to Android 29 there were only fingerprint devices.
    // After that Android uses new API, which doesn't let you know which biometry method is available on the device (face recognition/ fingerprint/ iris scanner)
    if (Platform.Version < 29) return BiometryType.FINGERPRINT;
    return BiometryType.BIOMETRICS;
  } else {
    try {
      const types = await supportedAuthenticationTypesAsync();
      if (types.includes(AuthenticationType.FACIAL_RECOGNITION)) {
        return BiometryType.FACE_ID;
      }
      if (types.includes(AuthenticationType.FINGERPRINT)) {
        return BiometryType.TOUCH_ID;
      }
    } catch {
      return undefined;
    }
  }
}

export function getBiometryTypeString(biometryType?: BiometryType) {
  switch (biometryType) {
    case BiometryType.FACE_ID:
      return 'Face ID';
    case BiometryType.TOUCH_ID:
      return 'Touch ID';
    case BiometryType.FINGERPRINT:
      return i18n.t('fingerprint');
    case BiometryType.BIOMETRICS:
      return i18n.t('biometry');
    default:
      return undefined;
  }
}
