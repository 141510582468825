import * as Font from 'expo-font';
import {Ionicons} from '@expo/vector-icons';

export const loadFonts = async () => {
  try {
    await Font.loadAsync({
      Roboto: require('../../assets/fonts/Roboto.ttf'),
      Roboto_medium: require('../../assets/fonts/Roboto_medium.ttf'),
      ...Ionicons.font
    });
  } catch (error) {
    console.error(error);
  }
};
