import React, {useEffect, useRef, useState} from 'react';
import {Platform, ScrollView, TextInput} from 'react-native';
import {SafeAreaView} from 'react-native-safe-area-context';
import {t} from 'react-native-tailwindcss';
import {Box, HStack, useTheme, Checkbox} from 'native-base';
import i18n from '../../lib/i18n';
import {
  BiometryType,
  getBiometryType,
  getBiometryTypeString,
  hasEnrolledBiometry,
  promptBiometry
} from '../../lib/biometryUtils';
import Logo from '../../components/Logo';
import {useSettings} from '../../hooks/useSettings';
import {PinInput} from '../../components/PinInput';
import {useLockscreen} from '../../hooks/useLockcreen';
import {KeyboardAvoidingView} from '../../components/KeyboardAvoidingView';
import {useTranslation} from 'react-i18next';

export const CreatePasscode = () => {
  const i18n = useTranslation();
  const [pin, setPin] = useState<string>('');
  const [confirmPin, setConfirmPin] = useState<string>('');
  const [isConfirmPinVisible, setIsConfirmPinVisible] = useState(false);
  const [biometryEntryVisible, setBiometryEntryVisible] = useState(false);
  const [biometryEnabled, setBiometryEnabled] = useState(false);
  const [biometryType, setBiometryType] = useState<BiometryType | undefined>();
  const [settings, setSettings] = useSettings();
  const {storePinInSecureStore} = useLockscreen();
  const [errorMessage, setErrorMessage] = useState<string>('');

  useEffect(() => {
    (async () => {
      if (await hasEnrolledBiometry()) {
        setBiometryEntryVisible(true);
        const biometryMethod = await getBiometryType();
        setBiometryType(biometryMethod);
      }
    })();
  }, []);

  useEffect(() => {
    setIsConfirmPinVisible(pin.length === 4);
  }, [pin, confirmPin]);

  const biometryAuthentication = async () => {
    if (await hasEnrolledBiometry()) {
      const authenticated = await promptBiometry(i18n.t('biometrySetPrompt'));
      if (authenticated) {
        setSettings({...settings, useBiometricAuth: true});
      } else {
        setBiometryEnabled(false);
      }
    }
  };

  const onChangeText = async (text: string) => {
    setPin(text);
    if (errorMessage) {
      setErrorMessage('');
    }
  };

  const onChangeTextConfirm = async (text: string) => {
    setConfirmPin(text);
    if (text.length === 4 && pin.length === 4) {
      if (text === pin) {
        await storePinInSecureStore(text);
      } else {
        setTimeout(() => {
          setPin('');
          setConfirmPin('');
          setErrorMessage(i18n.t('passcodeDoesNotMatch'));
        });
      }
    }
  };

  const toggleUseBiometricAuth = (newValue: boolean) => {
    setBiometryEnabled(newValue);
    if (newValue) {
      biometryAuthentication();
    } else {
      setSettings({...settings, useBiometricAuth: false});
    }
  };

  return (
    <SafeAreaView style={[t.flex1, t.bgWhite]} edges={['bottom']}>
      <KeyboardAvoidingView>
        <ScrollView
          contentContainerStyle={[t.flex, t.flex1, t.flexCol, t.justifyCenter]}
          keyboardShouldPersistTaps="handled">
          <Box style={[t.p6, t.itemsCenter]}>
            <Box style={[t.mB4, {alignItems: 'center', justifyContent: 'center'}]}>
              <Logo width="250" height="50" style={[t.m4]} />
            </Box>
            <Box style={t.h5}>
              {isConfirmPinVisible ? (
                <PinInput
                  label={i18n.t('confirmPasscode')}
                  autoFocus
                  value={confirmPin}
                  onChange={onChangeTextConfirm}
                />
              ) : (
                <PinInput
                  autoFocus
                  label={i18n.t('createPasscode')}
                  value={pin}
                  errorMessage={errorMessage}
                  onChange={onChangeText}
                />
              )}
            </Box>
          </Box>
        </ScrollView>
        {biometryEntryVisible && !!biometryType && (
          <Box style={[t.itemsCenter, t.mB6]}>
            <Checkbox
              value={'use-biometry'}
              isChecked={biometryEnabled}
              onChange={(value) => toggleUseBiometricAuth(value)}>
              {i18n.t('useBiometricAuth', {
                biometryType: getBiometryTypeString(biometryType)
              })}
            </Checkbox>
          </Box>
        )}
      </KeyboardAvoidingView>
    </SafeAreaView>
  );
};
