import {useCallback, useState} from 'react';

type AsyncAction = (...args: any[]) => Promise<any>;

/**
 * A hook that returns an async action and a flag indicating progress
 * @param func - async action
 * @param canUnmount - set to true if async action can result in unmounting component
 * this is needed to prevent "Can't perform a React state update on an unmounted component" error
 */
export const useAsyncAction = <T extends AsyncAction>(
  func: T,
  canUnmount?: boolean
): [T, boolean] => {
  const [isLoading, setLoading] = useState(false);
  const asyncAction = useCallback(
    async (...args: any[]) => {
      setLoading(true);
      try {
        const result = await func(...args);
        if (!canUnmount) setLoading(false);
        return result;
      } catch (error) {
        setLoading(false);
        throw error;
      }
    },
    [canUnmount, func]
  );
  return [asyncAction as T, isLoading];
};
