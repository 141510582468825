import {Platform, Pressable} from 'react-native';
import React, {ReactNode} from 'react';
import {Box, useTheme} from 'native-base';

interface Props {
  onPress?: () => void;
  children: ReactNode;
}

export const PressableFeedback = React.forwardRef<any, Props>(({onPress, children}, ref) => {
  const {colors} = useTheme();

  if (!onPress) {
    return <Box>{children}</Box>;
  }

  return (
    <Pressable
      onPress={onPress}
      ref={ref}
      android_ripple={{
        color: colors.primary[200],
        borderless: false,
        foreground: true
      }}>
      {({pressed}) => (
        <Box style={{opacity: pressed && Platform.OS !== 'android' ? 0.5 : 1}}>{children}</Box>
      )}
    </Pressable>
  );
});
