import {
  accountFields,
  authorizedUserFields,
  chargeEventObjectFields,
  chargeFields,
  codeFields,
  providerFields
} from './fragments';
import gql from 'graphql-tag';

export const accountQuery = gql`
  query Account {
    account {
      ...AccountFields
    }
  }
  ${accountFields}
`;

export const liveAccountQuery = gql`
  query LiveAccount {
    liveAccount {
      id
      status
    }
  }
`;

export const authorizedUsersQuery = gql`
  query AuthorizedUsers($token: String, $pageSize: Int) {
    authorizedUsers(token: $token, pageSize: $pageSize) {
      items {
        ...AuthorizedUserFields
      }
      nextToken
    }
  }
  ${authorizedUserFields}
`;

export const chargesQuery = gql`
  query Charges($filter: SearchableChargeFilterInput, $size: Int, $from: Int) {
    charges(filter: $filter, size: $size, from: $from) {
      items {
        ...ChargeFields
      }
      total
    }
  }
  ${chargeFields}
`;

export const chargeQuery = gql`
  query Charge($id: ID!) {
    charge(id: $id) {
      ...ChargeFields
    }
  }
  ${chargeFields}
`;

export const succeededDateRangeKpiQuery = gql`
  query SucceededDateRangeKpi($start: Int, $end: Int, $interval: Interval, $timezone: String) {
    chargesDateRangeKPI(start: $start, end: $end, interval: $interval, timezone: $timezone) {
      currency
      total {
        succeededAmount
        succeededCount
      }
    }
  }
`;

export const chargesDateRangeKpiQuery = gql`
  query ChargesDateRangeKpi($start: Int, $end: Int, $interval: Interval, $timezone: String) {
    chargesDateRangeKPI(start: $start, end: $end, interval: $interval, timezone: $timezone) {
      currency
      total {
        succeededAmount
        succeededCount
        refundedCount
        failedCount
      }
    }
  }
`;

export const chargeEventsQuery = gql`
  query ChargeEvents($chargeId: ID!, $size: Int, $from: Int) {
    chargeEvents(chargeId: $chargeId, size: $size, from: $from) {
      items {
        id
        type
        accountId
        livemode
        objectId
        objectType
        createdAt
        object {
          ...ChargeEventObjectFields
        }
      }
      total
    }
  }
  ${chargeEventObjectFields}
`;

export const eventsQuery = gql`
  query Events($filter: SearchableEventFilterInput, $size: Int, $from: Int) {
    events(filter: $filter, size: $size, from: $from) {
      items {
        id
        type
        accountId
        livemode
        objectId
        objectType
        createdAt
        object {
          ...ChargeEventObjectFields
        }
      }
      total
    }
  }
  ${chargeEventObjectFields}
`;

export const countriesQuery = gql`
  query Countries {
    countries {
      supportedCountries {
        name
        phoneCode
        code
      }
      currentCountry {
        name
        phoneCode
        code
      }
      currentCountryCode
      isSupported
    }
  }
`;

export const paymentEmailPreviewQuery = gql`
  query PaymentEmailPreview($paymentId: ID!, $emailType: PaymentEmailType) {
    paymentEmailPreview(paymentId: $paymentId, emailType: $emailType)
  }
`;

export const providersQuery = gql`
  query Providers {
    providers {
      ...ProviderFields
    }
  }
  ${providerFields}
`;

export const providerQuery = gql`
  query Provider($id: ID!) {
    provider(id: $id) {
      ...ProviderFields
    }
  }
  ${providerFields}
`;

export const availablePaymentMethods = gql`
  query AvailablePaymentMethods {
    availablePaymentMethods {
      paymentMethod
      configured
      enabled
    }
  }
`;

export const storesQuery = gql`
  query Stores($token: String, $pageSize: Int) {
    stores(token: $token, pageSize: $pageSize) {
      items {
        id
        name
      }
      nextToken
    }
  }
`;

export const pointsOfSaleQuery = gql`
  query PointsOfSale($storeId: ID, $token: String, $pageSize: Int) {
    pointsOfSale(storeId: $storeId, token: $token, pageSize: $pageSize) {
      items {
        id
        storeId
        number
        name
        currentPaymentId
        manualAmount
      }
      nextToken
    }
  }
`;

export const codesQuery = gql`
  query Codes {
    codes {
      ...CodeFields
    }
  }
  ${codeFields}
`;

export const themeQuery = gql`
  query Theme {
    account {
      branding {
        accentColor
        iconUrl
      }
    }
  }
`;
