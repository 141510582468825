import {useFocusEffect} from '@react-navigation/native';
import {NativeStackScreenProps} from '@react-navigation/native-stack';
import {Box, Button, Text} from 'native-base';
import React, {useCallback, useEffect, useState} from 'react';
import {FlatList, Platform} from 'react-native';
import {SafeAreaView} from 'react-native-safe-area-context';
import {t} from 'react-native-tailwindcss';
import {
  AccountStatus,
  ChargeFieldsFragment,
  useAccountQuery,
  useChargesQuery,
  useOnUpdateChargesSubscription
} from '../api/generated';
import {AccountNotConfiguredBanner} from '../components/AccountNotConfiguredBanner';
import {LoadingEntriesSkeleton} from '../components/LoadingEntriesSkeleton';
import {PaymentItem} from '../components/PaymentItem';
import {TodaysPaymentSummary} from '../components/TodaysPaymentSummary';
import {useAccessLevelCheck} from '../hooks/useAccessLevelCheck';
import {useAppTheme} from '../hooks/useAppTheme';
import {useAsyncAction} from '../hooks/useAsyncAction';
import {useUserContext} from '../lib/authContext';
import moment from '../lib/moment';
import {Nav, NavParams} from './navigation';
import {useTranslation} from 'react-i18next';
import {NavCreatePayment} from './CreatePaymentScreens';

export const HomeScreen: React.FC<NativeStackScreenProps<NavParams, Nav.HomeScreen>> = ({
  navigation
}) => {
  const i18n = useTranslation();
  const {accountId} = useUserContext();
  const [firstFocus, setFirstFocus] = useState(false);
  const {setTheme} = useAppTheme();

  const {data: accountData, loading: loadingAccount, refetch: refetchAccount} = useAccountQuery();

  const isAccountActive = [AccountStatus.ACTIVE, AccountStatus.APPROVED].includes(
    accountData?.account.status as AccountStatus
  );

  const {
    data: paymentsData,
    refetch,
    loading: loadingPayments
  } = useChargesQuery({
    skip: !isAccountActive || (!navigation.isFocused() && Platform.OS === 'web'), // prevents firing the query when we refresh the page that is on top (e.g PaymentsScreen)
    variables: {
      size: 20,
      from: 0
    }
  });

  const [onRefresh, isRefreshing] = useAsyncAction(refetch);
  const [onRefetchAccount, isRefetchingAccount] = useAsyncAction(refetchAccount);
  const {hasWriteAccess} = useAccessLevelCheck();

  const primaryColor = accountData?.account.branding?.accentColor;

  useEffect(() => {
    if (primaryColor) {
      setTheme({primaryColor});
    }
  }, [primaryColor]);

  useFocusEffect(
    useCallback(() => {
      if (!firstFocus) {
        setFirstFocus(true);
      } else {
        if (isAccountActive) {
          void refetch();
        }
      }
    }, [refetch, isAccountActive])
  );

  useOnUpdateChargesSubscription({
    variables: {accountId},
    skip: !isAccountActive || !navigation.isFocused(),
    onData: () => refetch()
  });

  const payments = (paymentsData?.charges.items || [])
    .filter((p) => moment.unix(p.createdAt).isAfter(moment().startOf('day')))
    .slice(0, 6);

  const isLoadingAccountInfo = loadingAccount || isRefetchingAccount;
  const isLoading = loadingPayments || isLoadingAccountInfo;
  const accountIsNotActive = !isLoadingAccountInfo && !isAccountActive;

  const renderHeader = useCallback(
    () => (
      <>
        <TodaysPaymentSummary isPlaceholder={isLoadingAccountInfo} shouldRefetch={isRefreshing} />
        <Text style={[t.textLg, t.pX4, t.mB4]} bold>
          {i18n.t('lastPayments')}
        </Text>
      </>
    ),
    [isRefreshing, isLoadingAccountInfo]
  );

  return (
    <SafeAreaView style={[t.flex1, t.bgWhite]} edges={['bottom']}>
      <Box style={[t.flex1, t.mT2]}>
        {accountIsNotActive ? (
          <AccountNotConfiguredBanner onClose={onRefetchAccount} />
        ) : (
          <>
            <FlatList<ChargeFieldsFragment>
              data={payments}
              onRefresh={onRefresh}
              refreshing={isRefreshing}
              ListHeaderComponent={renderHeader()}
              ListFooterComponent={
                !isLoading
                  ? () => (
                      <Button
                        size="lg"
                        variant="link"
                        style={[t.mT4]}
                        onPress={() =>
                          navigation.navigate(Nav.PaymentsScreen, {filters: undefined})
                        }>
                        {i18n.t('showAllPayments')}
                      </Button>
                    )
                  : undefined
              }
              ListEmptyComponent={() =>
                isLoading ? (
                  <LoadingEntriesSkeleton isLoading numberOfEntries={6} />
                ) : (
                  <Text style={[t.textGray600, t.textCenter, t.pY6, t.pX4, t.flex1]}>
                    {i18n.t('noPayments')}
                  </Text>
                )
              }
              renderItem={({item}) => (
                <PaymentItem
                  item={item}
                  key={item.id}
                  onPress={() => navigation.navigate(Nav.PaymentScreen, {paymentId: item.id})}
                />
              )}
              keyExtractor={(item) => item.id}
            />
            {!isLoading && hasWriteAccess && (
              <Box style={[t.p4]}>
                <Button
                  onPress={() =>
                    navigation.navigate(Nav.CreatePaymentScreens, {
                      screen: NavCreatePayment.AmountScreen
                    })
                  }>
                  {i18n.t('createPayment')}
                </Button>
              </Box>
            )}
          </>
        )}
      </Box>
    </SafeAreaView>
  );
};
