import {CognitoUser} from 'amazon-cognito-identity-js';
import {createContext, useContext} from 'react';
import {isValidAccessLevel} from './userContext';

export interface IUser extends CognitoUser {
  attributes: {
    'custom:account_id': string;
    email: string;
    email_verified: boolean;
    phone_number: string;
    phone_number_verified: boolean;
    sub: string;
  };
  [key: string]: any;
}

export enum MfaType {
  SMS_MFA = 'SMS_MFA',
  SOFTWARE_TOKEN_MFA = 'SOFTWARE_TOKEN_MFA'
}

export interface IAuthState {
  currentUser: IUser | null;
  isAuthenticated: boolean;
  mfaDestination?: string;
  mfaType?: MfaType;
}

export interface IAuthContext extends IAuthState {
  setAuthState: (state: IAuthState) => void;
  signOut(): void;
}

export const AuthContext = createContext<IAuthContext>({
  currentUser: null,
  isAuthenticated: false,
  setAuthState() {},
  signOut() {}
});

export const getAccessLevels = (currentUser: IUser | null) => {
  if (!currentUser) return [];
  const session = currentUser?.getSignInUserSession()!;
  const {payload} = session.getIdToken();
  const cognitoGroups = payload['cognito:groups'];
  if (Array.isArray(cognitoGroups)) {
    return cognitoGroups.filter(isValidAccessLevel);
  }
  return [];
};

export const useUserContext = () => {
  const {currentUser, signOut} = useContext(AuthContext);
  return {
    userEmail: currentUser?.attributes.email!,
    userId: currentUser?.attributes.sub!,
    accountId: currentUser?.attributes['custom:account_id']!,
    accessLevels: getAccessLevels(currentUser),
    signOut
  };
};
