import {Input, useTheme, Select as NBSelect} from 'native-base';
import React, {useRef, useState} from 'react';
import {t} from 'react-native-tailwindcss';
import Menu from './Menu';
import {Ionicons} from '@expo/vector-icons';
import {Platform, TextInput, TouchableWithoutFeedback, View} from 'react-native';

interface Props {
  text: string;
  selectedValue: string;
  onSelected(newKey: string): void;
  nextInputRef?: React.MutableRefObject<TextInput | undefined>;
  options: Record<string, string>;
}

function SelectNative({onSelected, selectedValue, text, nextInputRef, options}: Props) {
  const [inputFocused, setInputFocused] = useState(false);
  const {colors} = useTheme();
  return (
    <Menu
      onSelected={(newKey) => {
        if (newKey !== selectedValue) {
          onSelected(newKey);
        }
      }}
      offset={0}
      placement="top left"
      options={options}
      onClose={() => {
        setInputFocused(false);
        nextInputRef?.current?.focus();
      }}
      value={selectedValue}
      trigger={(ref, onPress) => (
        <TouchableWithoutFeedback
          onPress={() => {
            setInputFocused(true);
            onPress();
          }}>
          <View ref={ref}>
            <Input
              variant={'unstyled'}
              pointerEvents="none"
              value={text}
              editable={false}
              isFocused={false}
              leftElement={
                <Ionicons
                  color={inputFocused ? colors.primary[600] : colors.gray[600]}
                  style={[t.pL2]}
                  name={inputFocused ? 'chevron-up-circle' : 'chevron-down-circle'}
                  size={24}
                />
              }
            />
          </View>
        </TouchableWithoutFeedback>
      )}
    />
  );
}

function SelectWeb({onSelected, selectedValue, text, options}: Props) {
  const [inputFocused] = useState(false);
  const {colors} = useTheme();

  return (
    <View>
      <Input
        variant={'unstyled'}
        pointerEvents="none"
        value={text}
        editable={false}
        isFocused={false}
        leftElement={
          <Ionicons
            color={inputFocused ? colors.primary[600] : colors.gray[600]}
            style={[t.pL2]}
            name={inputFocused ? 'chevron-up-circle' : 'chevron-down-circle'}
            size={24}
          />
        }
      />
      <select
        style={{width: '100%', height: '100%', position: 'absolute', opacity: 0, zIndex: 1}}
        onChange={(e) => {
          onSelected(e.target.value);
        }}
        value={selectedValue}>
        {Object.entries(options).map(([key, label]) => {
          return (
            <option key={key} value={key}>
              {label}
            </option>
          );
        })}
      </select>
    </View>
  );
}

export function Select(props: Props) {
  if (Platform.OS === 'web') {
    return <SelectWeb {...props} />;
  }
  return <SelectNative {...props} />;
}
