import gql from 'graphql-tag';

export const accountFields = gql`
  fragment AccountFields on Account {
    id
    name
    timezone
    customDomain
    adminEmail
    publicBusinessDetails {
      companyName
      website
      phone
      address {
        city
        country
        line1
        line2
        zip
        state
      }
      supportWebsite
      supportEmail
      supportPhone
    }
    branding {
      accentColor
      bgColor
      logoUrl
      iconUrl
    }
    business {
      companyName
      legalName
    }
    status
  }
`;

export const authorizedUserFields = gql`
  fragment AuthorizedUserFields on AuthorizedUser {
    userEmail
    id
    accessLevel
    createdAt
    updatedAt
  }
`;

export const addressFields = gql`
  fragment AddressFields on Address {
    city
    country
    line1
    line2
    zip
    state
  }
`;

export const sessionDetailsFields = gql`
  fragment SessionDetailsFields on SessionDetails {
    ip
    userAgent
    countryCode
    lang
    deviceType
    deviceModel
    browser
    browserVersion
    browserAccept
    browserColorDepth
    browserScreenHeight
    browserScreenWidth
    browserTimezoneOffset
    os
    osVersion
    source
    sourceVersion
  }
`;

export const traceDetailsFields = gql`
  fragment TraceDetailsFields on TraceDetails {
    ip
    userAgent
    countryCode
    lang
    deviceType
    deviceModel
    browser
    browserVersion
    browserAccept
    os
    osVersion
    source
    sourceVersion
    userId
    userEmail
  }
`;

export const paymentMethodFields = gql`
  fragment PaymentMethodFields on PaymentMethod {
    method
    card {
      brand
      country
      type
      threeDSecure
      threeDSecureVersion
      last4
      bank
    }
    bizum {
      phoneNumber
    }
    paypal {
      orderId
      payerId
      email
      name
    }
  }
`;

export const chargeFields = gql`
  fragment ChargeFields on Charge {
    id
    createdAt
    amount
    currency
    customer {
      email
      name
      phone
    }
    paymentMethod {
      method
      card {
        brand
        country
        type
        threeDSecure
        threeDSecureVersion
        tokenizationMethod
        cardholderEmail
        cardholderName
        last4
        bank
      }
      bizum {
        phoneNumber
      }
      paypal {
        orderId
        payerId
        email
        name
      }
    }
    description
    orderId
    refundedAmount
    status
    statusCode
    statusMessage
  }
`;

export const chargeEventObjectFields = gql`
  fragment ChargeEventObjectFields on ChargeEventObject {
    id
    createdAt
    amount
    currency
    customer {
      email
      name
      phone
    }
    description
    orderId
    status
    statusCode
    statusMessage
  }
`;

export const paymentFields = gql`
  fragment PaymentFields on Payment {
    id
    createdAt
    amount
    currency
    customer {
      email
      name
      phone
    }
    paymentMethod {
      bizum {
        phoneNumber
      }
    }
    description
    orderId
    refundedAmount
    status
    statusCode
    statusMessage
  }
`;

export const providerFields = gql`
  fragment ProviderFields on Provider {
    id
    enabled
    paymentMethod
  }
`;

export const codeFields = gql`
  fragment CodeFields on Code {
    id
    type
    enabled
  }
`;
