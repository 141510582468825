import React, {useCallback, useState} from 'react';
import {Button, ScrollView, Text, Box, FormControl} from 'native-base';
import {KeyboardAvoidingView} from '../components/KeyboardAvoidingView';
import {t} from 'react-native-tailwindcss';
import {SafeAreaView} from 'react-native-safe-area-context';
import {useRequestToPayMutation} from '../api/generated';
import {NativeStackScreenProps} from '@react-navigation/native-stack';
import {Nav, NavParams} from './navigation';
import {useTranslation} from 'react-i18next';
import {PhoneNumberInput} from '../components/PhoneNumberInput';
import {locale} from 'expo-localization';

export const RequestToPayScreen: React.FC<
  NativeStackScreenProps<NavParams, Nav.RequestToPayScreen>
> = ({route, navigation}) => {
  const {paymentId} = route.params;
  const [phoneNumber, setPhoneNumber] = useState('');
  const [phoneNumberIsValid, setPhoneNumberIsValid] = useState(false);
  const [requestToPayMutation, {loading}] = useRequestToPayMutation();
  const i18n = useTranslation();

  const onSendRequest = useCallback(async () => {
    if (!phoneNumberIsValid) return;
    try {
      await requestToPayMutation({
        variables: {input: {phoneNumber, paymentId, language: locale.split('-')[0]}}
      });
      const hasPaymentInHistory = navigation
        .getState()
        .routes.find((route) => route.name === Nav.PaymentScreen);
      if (hasPaymentInHistory) {
        // payment screen is in the history so we just navigate back to it
        navigation.navigate(Nav.PaymentScreen, {paymentId});
      } else {
        const hasPaymentQRInHistory = navigation
          .getState()
          .routes.find((route) => route.name === Nav.PaymentQRScreen);
        if (hasPaymentQRInHistory) {
          // paymentQR screen is in the history so we navigate back to it and then replace from there
          navigation.navigate(Nav.PaymentQRScreen, {paymentId});
        }
        navigation.replace(Nav.PaymentScreen, {paymentId});
      }
    } catch {
      // do nothing, error displayed by error link
    }
  }, [phoneNumber, paymentId, navigation, requestToPayMutation, phoneNumberIsValid]);

  return (
    <SafeAreaView style={[t.flex1, t.bgWhite]} edges={['bottom']}>
      <KeyboardAvoidingView offset={125}>
        <ScrollView style={[t.flex1]} contentContainerStyle={[t.m4, t.flexGrow]}>
          <Box style={[t.flex1]}>
            <FormControl>
              <FormControl.Label>{i18n.t('enterCustomerPhone')}</FormControl.Label>
              <PhoneNumberInput
                value={phoneNumber}
                autoFocus
                countryCode="ES"
                onChangeValidation={(isValid) => setPhoneNumberIsValid(isValid)}
                onChangeText={(newNumber) => setPhoneNumber(newNumber)}
              />
              <FormControl.HelperText>{i18n.t('requestToPayHelp')}</FormControl.HelperText>
            </FormControl>
          </Box>
          <Button onPress={onSendRequest} isLoading={loading} isDisabled={!phoneNumberIsValid}>
            {i18n.t('requestToPay')}
          </Button>
        </ScrollView>
      </KeyboardAvoidingView>
    </SafeAreaView>
  );
};
