import {ChargeFieldsFragment, ChargeStatus, Maybe, PaymentMethods} from '../api/generated';
import {cardBrandLabels, paymentMethodLabels} from './labels';
import moment from './moment';

export const canBeRefunded = (charge?: Maybe<ChargeFieldsFragment>) => {
  if (!charge) return false;
  return [ChargeStatus.SUCCEEDED, ChargeStatus.PARTIALLY_REFUNDED].includes(charge.status);
};

export const canBeCaptured = (charge?: Maybe<ChargeFieldsFragment>) => {
  if (!charge) return false;
  return [ChargeStatus.AUTHORIZED].includes(charge.status);
};

export const canBeCanceled = (charge?: Maybe<ChargeFieldsFragment>) => {
  if (!charge) return false;
  return [ChargeStatus.AUTHORIZED].includes(charge.status);
};

export const canSendPaymentLink = (charge?: Maybe<ChargeFieldsFragment>) => {
  if (!charge) return false;
  return [ChargeStatus.PENDING].includes(charge.status);
};

export const canSendPaymentReceipt = (charge?: Maybe<ChargeFieldsFragment>) => {
  if (!charge) return false;
  return [ChargeStatus.SUCCEEDED, ChargeStatus.REFUNDED, ChargeStatus.PARTIALLY_REFUNDED].includes(
    charge.status
  );
};

export const getPaymentMethod = (payment: ChargeFieldsFragment) => {
  if (!payment.paymentMethod?.method) return null;
  const method = paymentMethodLabels[payment.paymentMethod.method];

  // Bizum
  if (payment.paymentMethod.method === PaymentMethods.BIZUM) {
    if (payment.paymentMethod.bizum?.phoneNumber) {
      return `${method} - ${payment.paymentMethod.bizum.phoneNumber.slice(-4)}`;
    }
    return method;
  }

  // Paypal
  if (payment.paymentMethod.method === PaymentMethods.PAYPAL) {
    if (payment.paymentMethod.paypal?.orderId) {
      return `${method} - ${payment.paymentMethod.paypal.orderId}`;
    }
    return method;
  }

  // Cofidis
  if (payment.paymentMethod.method === PaymentMethods.COFIDIS) {
    return method;
  }

  // Card
  const brand = cardBrandLabels[payment.paymentMethod.card?.brand!];
  return `${brand} - ${payment.paymentMethod.card?.last4}`;
};

export const CURRENCY = 'EUR';

export enum TransactionExpiration {
  MINUTES_15 = 'MINUTES_15',
  HOUR_1 = 'HOUR_1',
  DAY_1 = 'DAY_1',
  WEEK_1 = 'WEEK_1',
  MONTH_1 = 'MONTH_1',
  MONTH_3 = 'MONTH_3'
}

function replaceArticles(timeWithArticles: string) {
  return timeWithArticles.replace(/^(a |an |un |una )/, '1 ');
}

export function getExpirationDate(expireAfter: TransactionExpiration | undefined) {
  switch (expireAfter) {
    case TransactionExpiration.MINUTES_15:
      return moment().add(15, 'minute').unix();
    case TransactionExpiration.HOUR_1:
      return moment().add(1, 'hour').unix();
    case TransactionExpiration.DAY_1:
      return moment().add(1, 'day').unix();
    case TransactionExpiration.WEEK_1:
      return moment().add(1, 'week').unix();
    case TransactionExpiration.MONTH_1:
      return moment().add(1, 'month').unix();
    case TransactionExpiration.MONTH_3:
      return moment().add(3, 'month').unix();
    default:
      return moment().add(15, 'minute').unix();
  }
}

export const TRANSACTION_EXPIRATION_MAP: Record<TransactionExpiration, string> = {
  [TransactionExpiration.MINUTES_15]: replaceArticles(moment.duration(15, 'minutes').humanize()),
  [TransactionExpiration.HOUR_1]: replaceArticles(moment.duration(1, 'hour').humanize()),
  [TransactionExpiration.DAY_1]: replaceArticles(moment.duration(1, 'day').humanize()),
  [TransactionExpiration.WEEK_1]: replaceArticles(moment.duration(1, 'week').humanize()),
  [TransactionExpiration.MONTH_1]: replaceArticles(moment.duration(1, 'month').humanize()),
  [TransactionExpiration.MONTH_3]: replaceArticles(moment.duration(3, 'months').humanize())
} as const;
