import {NativeStackScreenProps} from '@react-navigation/native-stack';
import React, {useEffect, useRef, useState} from 'react';
import {SafeAreaView} from 'react-native-safe-area-context';
import {t} from 'react-native-tailwindcss';
import {Nav, NavParams} from '../navigation';
import {Button, Box, Input} from 'native-base';
import {useCreatePayment} from '../../hooks/useCreatePayment';
import {useSettings} from '../../hooks/useSettings';
import {TextInput} from 'react-native';
import {triggerHapticFeedback} from '../../lib/hapticFeedback';
import {useTranslation} from 'react-i18next';
import {KeyboardAvoidingView} from '../../components/KeyboardAvoidingView';
import {CreatePaymentParams, NavCreatePayment} from '.';
import {CompositeScreenProps} from '@react-navigation/native';

export const CreatePaymentDescriptionScreen: React.FC<
  CompositeScreenProps<
    NativeStackScreenProps<CreatePaymentParams, NavCreatePayment.DescriptionScreen>,
    NativeStackScreenProps<NavParams, Nav.CreatePaymentScreens>
  >
> = ({navigation, route}) => {
  const i18n = useTranslation();
  const {amount} = route.params;
  const [description, setDescription] = useState('');
  const inputRef = useRef<TextInput>(null);
  const [settings] = useSettings();
  const createPayment = useCreatePayment();

  useEffect(() => {
    return navigation.addListener('transitionEnd', (nativeEvent) => {
      if (!nativeEvent.data.closing) {
        inputRef.current?.focus();
      }
    });
  }, [navigation]);

  const handleSubmit = () => {
    const {id} = createPayment(
      {amount, description: !description ? undefined : description},
      navigation.goBack
    );

    triggerHapticFeedback();
    if (settings.showQR) {
      navigation.replace(Nav.PaymentQRScreen, {
        paymentId: id
      });
    } else {
      navigation.replace(Nav.RequestToPayScreen, {paymentId: id});
    }
  };

  return (
    <SafeAreaView style={[t.flex1, t.bgWhite]} edges={['bottom']}>
      <KeyboardAvoidingView offset={125}>
        <Box style={[t.flex1, t.m4, t.pT4]}>
          <Input
            ref={inputRef}
            placeholder={i18n.t('descriptionPlaceholder')}
            onChangeText={setDescription}
            autoCorrect={false}
            autoCapitalize="none"
            onSubmitEditing={handleSubmit}
            returnKeyType="done"
          />
        </Box>
        <Box style={[t.p4]}>
          <Button onPress={handleSubmit}>{i18n.t('createPayment')}</Button>
        </Box>
      </KeyboardAvoidingView>
    </SafeAreaView>
  );
};
