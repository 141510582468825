import * as Localization from 'expo-localization';
import 'intl-pluralrules';
import i18n from 'i18next';
import {initReactI18next} from 'react-i18next';
import moment from './moment';
import en from '../../locales/en.json';
import es from '../../locales/es.json';
import ca from '../../locales/ca.json';

export type TranslationKeys = keyof typeof en;

const resources = {
  en: {translation: en},
  ca: {translation: ca},
  es: {translation: es}
};

i18n
  // pass the i18n instance to react-i18next.
  .use(initReactI18next)
  .init({
    resources,
    lng: Localization.locale,
    fallbackLng: 'en',
    interpolation: {
      escapeValue: false // not needed for react as it escapes by default
    }
  });

moment.locale(Localization.locale);

const i18nT = {
  t: (key: TranslationKeys, options?: object) => {
    return i18n.t(key, options);
  },
  language: i18n.language
};

export default i18nT;
