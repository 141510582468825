import React from 'react';
import {ChargeStatus} from '../api/generated';
import {Ionicons} from '@expo/vector-icons';

type Props = {
  status: ChargeStatus;
  style?: any;
};

export const PaymentStatusIcon: React.FC<Props> = ({status, style}) => {
  switch (status) {
    case ChargeStatus.SUCCEEDED:
    case ChargeStatus.AUTHORIZED: {
      return <Ionicons style={style} name="checkmark-circle-outline" />;
    }
    case ChargeStatus.PENDING:
    case ChargeStatus.PENDING_PROCESSING: {
      return <Ionicons style={style} name="timer-outline" />;
    }
    case ChargeStatus.FAILED: {
      return <Ionicons style={style} name="close-circle-outline" />;
    }
    case ChargeStatus.REFUNDED:
    case ChargeStatus.PARTIALLY_REFUNDED: {
      return <Ionicons style={style} name="arrow-undo-circle-outline" />;
    }
    case ChargeStatus.EXPIRED:
    case ChargeStatus.CANCELED: {
      return <Ionicons style={style} name="close-circle-outline" />;
    }
  }
  return null;
};
