import Intl from 'intl';
import 'intl/locale-data/jsonp/en.js';
import {parsePhoneNumberFromString} from 'libphonenumber-js';
import {CountryCode} from 'libphonenumber-js/types';
import {Text} from 'native-base';
import React from 'react';
import {Anchor} from '../components/Anchor';
import {CURRENCY} from './paymentUtils';

export const formatPhone = (
  phone?: string | null,
  countryCode?: string | null,
  link: boolean = false
) => {
  if (!phone) return null;
  const formattedPhone = parsePhoneNumberFromString(
    phone,
    countryCode as CountryCode
  )?.formatInternational();
  if (!formattedPhone) return null;
  if (link) {
    return <Anchor href={`tel:${formattedPhone}`}>{formattedPhone}</Anchor>;
  }
  return formattedPhone;
};

export const moneyFormatter = (currency?: string) => {
  return new Intl.NumberFormat('en', {
    style: 'currency',
    currency
  });
};

export const formatMoney = (amount: number, currency?: string) => {
  return moneyFormatter(currency).format(amount);
};

export const formatIntAmount = (amount: number | null | undefined) => {
  if (!amount) return null;
  return formatMoney(amount / 100, CURRENCY);
};

const getDomain = (url: string) => {
  let result;
  let match;
  if ((match = url.match(/^(?:https?:\/\/)?(?:[^@\n]+@)?(?:www\.)?([^:\/\n?=]+)/im))) {
    result = match[1];
    if ((match = result.match(/^[^.]+\.(.+\..+)$/))) {
      result = match[1];
    }
  }
  return result;
};

export const formatWebsite = (website?: string | null) => {
  if (!website) return null;
  return (
    <Text>
      <Anchor href={website}>{getDomain(website)}</Anchor>
    </Text>
  );
};

export const formatEmail = (email?: string | null) => {
  if (!email) return null;
  return (
    <Text>
      <Anchor href={`mailto:${email}`}>{email}</Anchor>
    </Text>
  );
};
