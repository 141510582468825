import React from 'react';
import {Text, TextProps, Platform} from 'react-native';
import * as WebBrowser from 'expo-web-browser';
import * as Linking from 'expo-linking';
import {t} from 'react-native-tailwindcss';

type Props = TextProps & {href: string};

function isCustomLink(link: string) {
  return link.startsWith('tel:') || link.startsWith('email:');
}

export const Anchor: React.FC<Props> = (props) => {
  return (
    <Text
      {...props}
      accessibilityRole="link"
      style={[t.textPurple500, props.style]}
      onPress={(e) => {
        if (Platform.OS === 'web' || isCustomLink(props.href)) {
          Linking.openURL(props.href);
        } else {
          WebBrowser.openBrowserAsync(props.href);
        }
        props.onPress?.(e);
      }}
    />
  );
};
