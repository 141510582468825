import {Text} from 'native-base';
import React from 'react';
import {useTranslation} from 'react-i18next';
import {SafeAreaView} from 'react-native-safe-area-context';
import {t} from 'react-native-tailwindcss';

type Props = {};

export const NotFoundScreen: React.FC<Props> = () => {
  const i18n = useTranslation();
  return (
    <SafeAreaView style={[t.flex1, t.bgWhite]} edges={['bottom']}>
      <Text style={[t.text2xl, t.textCenter, t.pY5]}>{i18n.t('notFound')}</Text>
    </SafeAreaView>
  );
};
