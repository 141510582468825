import React, {useCallback, useRef, useState} from 'react';
import DateTimePicker from '@react-native-community/datetimepicker';
import moment from '../lib/moment';
import {Button, Input, Box} from 'native-base';
import {Platform, TextInput, TouchableWithoutFeedback, Modal} from 'react-native';
import {useSafeAreaInsets} from 'react-native-safe-area-context';
import {useTranslation} from 'react-i18next';

function DateInputIos(props: DateInputProps) {
  const insets = useSafeAreaInsets();
  const i18n = useTranslation();
  const [openPicker, setPickerOpen] = useState(false);
  const [date, setDate] = useState(props.date);

  const inputRef = useRef<TextInput>();

  const onClose = useCallback(() => {
    setPickerOpen(false);
  }, []);

  const onDone = () => {
    props.onDateChange(date);
    onClose();
  };

  return (
    <>
      <Input
        placeholder={props.placeholder}
        ref={inputRef}
        showSoftInputOnFocus={false}
        onFocus={() => {
          setPickerOpen(true);
        }}
        value={
          props.date
            ? moment(props.date).format(props.mode === 'date' ? 'DD/MM/YYYY' : 'HH:mm')
            : undefined
        }
      />
      <Modal
        animationType="fade"
        transparent
        visible={openPicker}
        onRequestClose={() => onClose()}
        onDismiss={() => {
          inputRef.current?.blur();
        }}>
        <TouchableWithoutFeedback onPress={() => onClose()}>
          <Box style={{flex: 1, backgroundColor: 'black', opacity: 0.2}} />
        </TouchableWithoutFeedback>

        <Box
          style={{
            position: 'absolute',
            bottom: 0,
            left: 0,
            width: '100%',
            borderTopRightRadius: 4,
            borderTopLeftRadius: 4,
            backgroundColor: 'white',
            paddingBottom: insets.bottom
          }}>
          <Button onPress={() => onDone()}>{i18n.t('done')}</Button>
          <DateTimePicker
            mode={props.mode}
            value={date ?? new Date()}
            onChange={(_, date) => {
              date && setDate(date);
            }}
            display={'spinner'}
            maximumDate={props.maxDate}
            minimumDate={props.minDate}
          />
        </Box>
      </Modal>
    </>
  );
}

function DateInputAndroid(props: DateInputProps) {
  const [openPicker, setPickerOpen] = useState(false);
  const inputRef = useRef<TextInput>();
  return (
    <>
      <Input
        placeholder={props.placeholder}
        ref={inputRef}
        showSoftInputOnFocus={false}
        value={
          props.date
            ? moment(props.date).format(props.mode === 'date' ? 'DD/MM/YYYY' : 'HH:mm')
            : undefined
        }
        onFocus={() => {
          setPickerOpen(true);
        }}
      />
      {openPicker && (
        <DateTimePicker
          mode={props.mode}
          value={moment().toDate()}
          onChange={(_, date) => {
            setPickerOpen(false);
            date && props.onDateChange(date);
            inputRef.current?.blur();
          }}
          maximumDate={props.maxDate}
          minimumDate={props.minDate}
        />
      )}
    </>
  );
}

function DateInputWeb(props: DateInputProps) {
  const ref = useRef<TextInput>(null);

  return (
    <Input
      ref={ref}
      // @ts-expect-error
      style={{$$css: true, display: props.date ? 'has-value' : ''}}
      onChangeText={(newValue) => {
        const newDate =
          props.mode === 'date' ? new Date(newValue) : moment(newValue, 'HH:mm').toDate();
        props.onDateChange(!isNaN(newDate.getDate()) ? newDate : undefined);
      }}
      placeholder={props.placeholder ?? ''}
      onKeyPress={(e) => e.preventDefault()}
      value={
        props.date
          ? props.mode === 'date'
            ? moment(props.date).format('YYYY-MM-DD')
            : moment(props.date).format('HH:mm')
          : undefined
      }
      //@ts-expect-error added implementation via patch
      keyboardType={props.mode}
      maxDate={props.maxDate?.toISOString().split('T')[0]}
      minDate={props.minDate?.toISOString().split('T')[0]}
    />
  );
}

interface DateInputProps {
  date?: Date;
  mode: 'date' | 'time';
  onDateChange(newDate: Date | undefined): void;
  placeholder?: string;
  maxDate?: Date;
  minDate?: Date;
}

export function DateInput(props: DateInputProps) {
  if (Platform.OS === 'ios') {
    return <DateInputIos {...props} />;
  }
  if (Platform.OS === 'android') {
    return <DateInputAndroid {...props} />;
  }
  if (Platform.OS === 'web') {
    return <DateInputWeb {...props} />;
  }
  return null;
}
