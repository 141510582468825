import {usePointsOfSaleQuery, useStoresQuery} from '../api/generated';

export const usePointOfSaleId = (): [string | undefined, boolean] => {
  const {data: storesData, loading: loadingStores} = useStoresQuery({variables: {pageSize: 10}});
  const storeId = storesData?.stores.items[0]?.id;
  const {data: posData, loading: loadingPointsOfSale} = usePointsOfSaleQuery({
    variables: {storeId},
    skip: !storeId
  });
  const loading = loadingStores || loadingPointsOfSale;
  const pointOfSaleId = posData?.pointsOfSale.items[0]?.id;
  return [pointOfSaleId, loading];
};
