import {createNativeStackNavigator} from '@react-navigation/native-stack';

export const Stack = createNativeStackNavigator<AuthNavParams>();

export enum AuthNav {
  LogInScreen = 'LogInScreen',
  ConfirmLogInScreen = 'ConfirmLogInScreen'
}

export type AuthNavParams = {
  [AuthNav.LogInScreen]: undefined;
  [AuthNav.ConfirmLogInScreen]: undefined;
};
