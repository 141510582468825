import {useUserContext} from '../lib/authContext';
import {useAppTheme} from '../hooks/useAppTheme';
import {useLockscreen} from '../hooks/useLockcreen';
import {useApolloClient} from '@apollo/client';
import {useCallback} from 'react';
import {useClearSettings} from './useSettings';

export function useSignOut() {
  const {signOut} = useUserContext();
  const {resetTheme} = useAppTheme();
  const {removePinFromStorage} = useLockscreen();
  const client = useApolloClient();
  const clearSettings = useClearSettings();

  const signOutAndClean = useCallback(async () => {
    signOut();
    resetTheme();
    clearSettings();
    removePinFromStorage();
    client.clearStore();
  }, [signOut, resetTheme, removePinFromStorage, client, clearSettings]);

  return signOutAndClean;
}
