import React from 'react';
import moment from '../lib/moment';
import {PaymentSummaryCard} from './PaymentSummaryCard';
import {timezone as localTimezone} from 'expo-localization';
import {PaymentFilter} from '../screens/navigation';
import {useTranslation} from 'react-i18next';

interface Props {
  filters: PaymentFilter | undefined;
  isPlaceholder: boolean;
  shouldRefetch: boolean;
}

export function PaymentSummary({filters, isPlaceholder, shouldRefetch}: Props) {
  const timezone = localTimezone || moment.tz.guess();
  const i18n = useTranslation();

  if (!filters?.startDate) {
    return null;
  }

  const {startDate, endDate} = filters;

  return (
    <PaymentSummaryCard
      label={i18n.t('summary')}
      timezone={timezone}
      freshData
      startDate={moment.unix(startDate)}
      endDate={endDate ? moment.unix(endDate) : moment()}
      isPlaceholder={isPlaceholder}
      shouldRefetch={shouldRefetch}
    />
  );
}
