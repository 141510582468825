import produce from 'immer';
import {
  useCreatePaymentMutation,
  PaymentStatus,
  ChargesQuery,
  ChargesQueryVariables,
  CreatePaymentInput,
  Payment
} from '../api/generated';
import {chargesQuery} from '../api/queries';
import {CURRENCY, getExpirationDate} from '../lib/paymentUtils';
import {randomHex} from '../lib/utils';
import {usePointOfSaleId} from './usePointOfSaleId';
import {useSettings} from './useSettings';

export const useCreatePayment = () => {
  const [createPayment] = useCreatePaymentMutation();
  const [pointOfSaleId] = usePointOfSaleId();
  const [settings] = useSettings();

  function handleCreatePayment(
    {amount, description: description}: CreatePaymentInput,
    onError: () => void
  ) {
    const paymentId = randomHex(40);
    const orderId = 'QR' + randomHex(6).toUpperCase();
    const createPaymentInput: Payment = {
      __typename: 'Payment',
      amount: amount,
      accountId: '',
      createdAt: Date.now() / 1000,
      currency: CURRENCY,
      customer: null,
      description: description || null,
      id: paymentId,
      orderId: orderId,
      paymentMethod: null,
      status: PaymentStatus.PENDING,
      statusCode: null,
      statusMessage: 'PENDING',
      refundedAmount: null
    };

    createPayment({
      variables: {
        input: {
          amount,
          pointOfSaleId,
          id: paymentId,
          orderId: orderId,
          description: description,
          expireAt: getExpirationDate(settings.transactionExpiration)
        }
      },
      optimisticResponse: {
        createPayment: createPaymentInput
      },
      update(cache, {data}) {
        if (!data?.createPayment) return;
        const charge: any = {...data.createPayment, __typename: 'Charge'};
        const result = cache.readQuery<ChargesQuery, ChargesQueryVariables>({
          query: chargesQuery
        });
        if (!result?.charges) return;
        cache.writeQuery<ChargesQuery, ChargesQueryVariables>({
          query: chargesQuery,
          variables: {from: 0, size: 20},
          data: produce(result, (draft) => {
            draft.charges.items.pop();
            draft.charges.items.unshift(charge);
          })
        });
      }
    }).catch(onError);
    return createPaymentInput;
  }

  return handleCreatePayment;
};
