import {InMemoryCache} from '@apollo/client';
import isEqual from 'lodash/isEqual';

let chargeFilter: object | undefined = undefined;

export const cache = new InMemoryCache({
  typePolicies: {
    Query: {
      fields: {
        charge: {
          read(_, {args, toReference}) {
            return toReference({
              __typename: 'Charge',
              id: args!.id
            });
          }
        },
        charges: {
          keyArgs: false,
          merge(existing, incoming, {args}) {
            // if the filters have changed, we want to discard exiting cache
            if (!isEqual(args?.filter, chargeFilter)) {
              chargeFilter = args?.filter;
              return incoming;
            }
            if (!existing) return incoming;

            const pageInfo = {from: 0, ...args};
            const items = existing.items ? existing.items.slice(0) : [];
            for (let i = 0; i < incoming.items.length; ++i) {
              items[pageInfo.from + i] = incoming.items[i];
            }
            return {...existing, items};
          }
        }
      }
    }
  }
});
