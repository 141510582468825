import React, {useState} from 'react';
import {Pressable} from 'native-base';
import {FlatList} from 'react-native';
import moment from '../lib/moment';
import {timezone as localTimezone} from 'expo-localization';
import {Platform} from 'react-native';
import {useTranslation} from 'react-i18next';
import {PaymentSummaryCard} from './PaymentSummaryCard';

export function TodaysPaymentSummary({
  shouldRefetch,
  isPlaceholder
}: {
  isPlaceholder: boolean;
  shouldRefetch: boolean;
}) {
  const i18n = useTranslation();
  const timezone = localTimezone || moment.tz.guess();
  const now = moment();
  const [itemWidth, setItemWidth] = useState(0);

  const [data] = useState([
    {time: now, label: i18n.t('today')},
    {time: now.clone().subtract(1, 'day'), label: i18n.t('yesterday')}
  ]);

  const renderContent = (isHovered?: boolean) => {
    return (
      <FlatList
        pagingEnabled={Platform.OS !== 'web'}
        horizontal
        inverted
        onLayout={({
          nativeEvent: {
            layout: {width}
          }
        }) => {
          if (width > 0 && itemWidth !== width) {
            setItemWidth(width);
          }
        }}
        data={data}
        showsHorizontalScrollIndicator={Platform.OS === 'web' && isHovered}
        renderItem={({item, index}) => (
          <PaymentSummaryCard
            isPlaceholder={isPlaceholder}
            shouldRefetch={shouldRefetch}
            label={item.label}
            timezone={timezone}
            startDate={item.time.startOf('day')}
            endDate={item.time.endOf('day')}
            isSubsequentItem={index !== 0}
            freshData={index === 0}
            itemWidth={itemWidth}
          />
        )}
        keyExtractor={(item) => item.label}
        extraData={shouldRefetch}
      />
    );
  };

  if (Platform.OS !== 'web') {
    return renderContent();
  }

  return <Pressable>{({isHovered}) => renderContent(isHovered)}</Pressable>;
}
