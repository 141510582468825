import React from 'react';
import {Menu as MenuBase} from 'native-base';
import {InterfaceMenuProps} from 'native-base/lib/typescript/components/composites/Menu/types';

export interface MenuWebProps {
  onSelected: (newKey: string) => void;
  options: Record<string, string>;
  value: string;
  offset?: number;
  placement?: 'top left';
  trigger: (ref: any, onPress: () => void) => React.ReactElement;
  onClose?(): void;
}

export default function Menu({
  value,
  options,
  onSelected,
  trigger,
  onClose,
  offset,
  placement
}: MenuWebProps) {
  return (
    <MenuBase
      w="100%"
      shouldOverlapWithTrigger
      placement={placement ?? 'top right'}
      offset={offset ?? -100}
      onClose={onClose}
      trigger={(triggerProps) => trigger(triggerProps.ref, triggerProps.onPress)}>
      {Object.entries(options).map(([key, label]) => {
        const isSelected = key === value;
        return (
          <MenuBase.Item
            key={key}
            isPressed={isSelected}
            onPress={() => {
              if (isSelected) return;
              onSelected(key);
            }}>
            {label}
          </MenuBase.Item>
        );
      })}
    </MenuBase>
  );
}
