import {CardBrand, PaymentMethods, TokenizationMethods} from '../api/generated';

export const paymentMethodLabels: {[key: string]: string} = {
  [PaymentMethods.BIZUM]: 'MONEI Pay (Bizum)',
  [PaymentMethods.CARD]: 'Card',
  [PaymentMethods.PAYPAL]: 'PayPal',
  [PaymentMethods.COFIDIS]: 'Cofidis 4xcard',
  [PaymentMethods.COFIDISLOAN]: 'Cofidis 6x12x24x'
};

export const tokenizationMethodLabels: {[key: string]: string} = {
  [TokenizationMethods.GOOGLEPAY]: 'Google Pay',
  [TokenizationMethods.APPLEPAY]: 'Apple Pay',
  [TokenizationMethods.CLICKTOPAY]: 'Click to Pay'
};

export const cardBrandLabels: {[key in CardBrand]: string} = {
  [CardBrand.VISA]: 'Visa',
  [CardBrand.AMEX]: 'American Express',
  [CardBrand.DINERS]: 'Diners Club',
  [CardBrand.JCB]: 'JCB',
  [CardBrand.MASTERCARD]: 'Mastercard',
  [CardBrand.UNIONPAY]: 'UnionPay',
  [CardBrand.UNKNOWN]: 'Unknown',
  [CardBrand.DISCOVER]: 'Discover'
};
