import React, {useCallback, useContext, useState} from 'react';
import {View} from 'react-native';
import {
  GestureHandlerRootView,
  HandlerStateChangeEvent,
  TapGestureHandler,
  TapGestureHandlerEventPayload
} from 'react-native-gesture-handler';

interface OutsideClickContextType {
  setCloseItemCallback: (close: () => void, containerRef: React.RefObject<View>) => void;
  resetClosableItem(): void;
}

const defaultValue = {
  setCloseItemCallback: () => {},
  resetClosableItem: () => {}
};

export const OutsideClickContext = React.createContext<OutsideClickContextType>(defaultValue);

export function OutsideClickContextProvider({children}: {children: React.ReactNode}) {
  const [closeItem, setCloseItem] = useState<(() => void) | undefined>(undefined);
  const [containerRef, setConatinerRef] = useState<React.RefObject<View> | undefined>();

  const onCloseItemChange = useCallback(
    (close: () => void, containerRef: React.RefObject<View>) => {
      closeItem?.(); // we close any currently opened item
      setCloseItem(() => close);
      setConatinerRef(containerRef);
    },
    [closeItem]
  );

  const resetClosableItem = () => {
    setCloseItem(undefined);
    setConatinerRef(undefined);
  };

  const onClick = (event: HandlerStateChangeEvent) => {
    const {absoluteX, absoluteY} = (event as HandlerStateChangeEvent<TapGestureHandlerEventPayload>)
      .nativeEvent;
    // we mesure the container to check if the click happened outsdide of the compoenent
    containerRef?.current?.measure((_x, _y, width, height, pageX, pageY) => {
      if (
        absoluteX > pageX &&
        absoluteX < pageX + width &&
        absoluteY > pageY &&
        absoluteY < pageY + height
      ) {
        // we clicked inside the component
        return;
      }
      closeItem?.();
      resetClosableItem();
    });
  };

  return (
    <GestureHandlerRootView style={{flex: 1}}>
      <OutsideClickContext.Provider
        value={{setCloseItemCallback: onCloseItemChange, resetClosableItem}}>
        <TapGestureHandler enabled={!!closeItem} onBegan={onClick}>
          <View style={{flex: 1}}>{children}</View>
        </TapGestureHandler>
      </OutsideClickContext.Provider>
    </GestureHandlerRootView>
  );
}
