import {deleteItemAsync, getItemAsync, setItemAsync} from 'expo-secure-store';
import React, {useEffect, useState} from 'react';
import {Platform} from 'react-native';
import {SECURE_STORE} from '../storage/storageKeys';

interface LockscreenContextType {
  passcodeState: {
    isLoading: boolean;
    hasPasscode: boolean;
    hasPassedLockscreen: boolean;
  };
  setLockscreenPassed: () => void;
  getStoredPin: () => Promise<string | null>;
  removePinFromStorage: () => Promise<void>;
  storePinInSecureStore: (pin: string) => Promise<void>;
}

const defaultValue = {
  passcodeState: {isLoading: false, hasPasscode: true, hasPassedLockscreen: true},
  setLockscreenPassed: () => {},
  getStoredPin: async () => null,
  removePinFromStorage: async () => {},
  storePinInSecureStore: async () => {}
};

export const LockscreenContext = React.createContext<LockscreenContextType>(defaultValue);

export const LockscreenContextMobile: React.FC<{children?: React.ReactNode}> = ({children}) => {
  const [passcodeState, setPasscodeState] = useState({
    isLoading: true,
    hasPasscode: false,
    hasPassedLockscreen: false
  });

  useEffect(() => {
    const load = async () => {
      const passcode = await getStoredPin();
      setPasscodeState({...passcodeState, isLoading: false, hasPasscode: !!passcode});
    };
    load();
  }, []);

  const getStoredPin = async () => {
    const pin = await getItemAsync(SECURE_STORE.PIN);
    return pin;
  };

  const removePinFromStorage = async () => {
    try {
      await deleteItemAsync(SECURE_STORE.PIN);
    } catch {
      // do nothing
    }
  };

  const storePinInSecureStore = async (pin: string) => {
    try {
      await setItemAsync(SECURE_STORE.PIN, pin);
      setPasscodeState({...passcodeState, hasPasscode: true, hasPassedLockscreen: true});
    } catch {
      // do nothing
    }
  };

  const setLockscreenPassed = () => {
    setPasscodeState({...passcodeState, hasPassedLockscreen: true});
  };

  return (
    <LockscreenContext.Provider
      value={{
        passcodeState,
        setLockscreenPassed,
        getStoredPin,
        removePinFromStorage,
        storePinInSecureStore
      }}>
      {children}
    </LockscreenContext.Provider>
  );
};

export const LockscreenContextWeb: React.FC<{children?: React.ReactNode}> = ({children}) => {
  return <LockscreenContext.Provider value={defaultValue}>{children}</LockscreenContext.Provider>;
};

export const LockscreenContextProvider =
  Platform.OS === 'web' ? LockscreenContextWeb : LockscreenContextMobile;
