import {NativeStackScreenProps} from '@react-navigation/native-stack';
import {Auth} from 'aws-amplify';
import Constants from 'expo-constants';
import gravatar from 'gravatar';
import {Avatar, Box, Button, Divider, Switch, Text, VStack} from 'native-base';
import React, {useEffect, useState} from 'react';
import {Linking, ScrollView} from 'react-native';
import {SafeAreaView} from 'react-native-safe-area-context';
import {t} from 'react-native-tailwindcss';
import {useAccountQuery} from '../api/generated';
import {Properties, PropertyList} from '../components/PropertyList';
import {useSignOut} from '../hooks/useSignOut';
import {useSettings} from '../hooks/useSettings';
import {useUserContext} from '../lib/authContext';
import {
  BiometryType,
  getBiometryType,
  getBiometryTypeString,
  hasEnrolledBiometry,
  promptBiometry
} from '../lib/biometryUtils';
import {formatPhone, formatWebsite} from '../lib/formatters';
import {useTranslation} from 'react-i18next';
import {Nav, NavParams} from './navigation';
import {localeMap} from '../lib/utils';
import {getAdminEmail} from '../storage/sessionStorage';
import {TRANSACTION_EXPIRATION_MAP} from '../lib/paymentUtils';
import type {TransactionExpiration} from '../lib/paymentUtils';

export const SettingsScreen: React.FC<
  NativeStackScreenProps<NavParams, Nav.SettingsScreen>
> = ({}) => {
  const i18n = useTranslation();
  const {userEmail} = useUserContext();
  const {data: accountData} = useAccountQuery();
  const account = accountData?.account;
  const accountId = account?.id;
  const details = account?.publicBusinessDetails;
  const [settings, setSettings] = useSettings();
  const [biometryEntryVisible, setBiometryEntryVisible] = useState(false);
  const [biometryEnabled, setBiometryEnabled] = useState(false);
  const [biometryType, setBiometryType] = useState<BiometryType | undefined>();
  const signOut = useSignOut();

  useEffect(() => {
    (async () => {
      if (await hasEnrolledBiometry()) {
        const biometryMethod = await getBiometryType();
        setBiometryEntryVisible(true);
        setBiometryType(biometryMethod);
      }
    })();
  }, []);

  useEffect(() => {
    if (settings.useBiometricAuth && !biometryEnabled) {
      setBiometryEnabled(true);
    }
  }, [settings.useBiometricAuth, biometryEntryVisible]);

  const authenticateAsync = async () => {
    const authenticated = await promptBiometry(i18n.t('biometrySetPrompt'));
    if (authenticated) {
      await Auth.rememberDevice();
      setSettings({...settings, useBiometricAuth: true});
      return;
    } else {
      setBiometryEnabled(false);
    }
  };

  const toggleUseBiometricAuth = (newValue: boolean) => {
    setBiometryEnabled(newValue);
    if (newValue) {
      authenticateAsync();
    } else {
      setSettings({...settings, useBiometricAuth: false});
    }
  };

  const handleSignOut = async () => {
    signOut();
  };

  const deleteAccount =
    'https://support.monei.com/hc/' +
    localeMap() +
    '/requests/new?ticket_form_id=360000322338&tf_4417061715857=' +
    accountId +
    '&tf_4450339294609=account&tf_4451372321809=yes&tf_subject=' +
    i18n.t('deleteAccount') +
    '&tf_description=' +
    i18n.t('deleteAccountDesc') +
    '&tf_anonymous_requester_email=' +
    getAdminEmail();

  const dashboard = 'https://dashboard.monei.com/settings';

  const companyInfo = [
    {
      label: i18n.t('companyName'),
      value: details?.companyName
    },
    {
      label: i18n.t('website'),
      value: formatWebsite(details?.website)
    },
    {
      label: i18n.t('phone'),
      value: formatPhone(details?.phone)
    }
  ];

  const appSettings: Properties = [
    {
      label: i18n.t('showQR'),
      value: (
        <Switch
          isChecked={settings.showQR}
          onToggle={() => {
            setSettings({...settings, showQR: !settings.showQR});
          }}
        />
      )
    },
    {
      label: i18n.t('askForDescription'),
      value: (
        <Switch
          isChecked={settings.showDescription}
          onToggle={() => {
            setSettings({...settings, showDescription: !settings.showDescription});
          }}
        />
      )
    },
    {
      label: i18n.t('paymentExpiration'),
      value: settings.transactionExpiration,
      menu: {
        options: TRANSACTION_EXPIRATION_MAP,
        onSelected: (newKey) => {
          setSettings({...settings, transactionExpiration: newKey as TransactionExpiration});
        }
      }
    },
    {
      label: i18n.t('useBiometricAuth', {biometryType: getBiometryTypeString(biometryType)}),
      value: biometryEntryVisible && biometryType && (
        <Switch
          isChecked={biometryEnabled}
          onValueChange={(value) => toggleUseBiometricAuth(value)}
        />
      )
    },
    {
      label: i18n.t('appVersion'),
      value: Constants.manifest?.version
    },
    {
      label: i18n.t('advancedSettings'),
      onPress: () => Linking.openURL(dashboard)
    },
    {
      label: <Text style={t.textRed600}>{i18n.t('deleteAccount')}</Text>,
      onPress: () => Linking.openURL(deleteAccount)
    }
  ];

  return (
    <SafeAreaView style={[t.flex1, t.bgWhite]} edges={['bottom']}>
      <ScrollView>
        <VStack space={2} style={[t.p2, t.m2]} alignSelf="center">
          <Avatar
            alignSelf="center"
            size="lg"
            source={{
              uri: `https:${gravatar.url(userEmail)}`
            }}>
            --
          </Avatar>
          <Text>{userEmail}</Text>
        </VStack>
        <Divider style={t.mL5} />
        <Box bg="gray.100" style={[t.pY3, t.pX5]}>
          <Text>{i18n.t('companyInfo')}</Text>
        </Box>
        <PropertyList properties={companyInfo} />
        <Divider style={t.mL5} />
        <Box bg="gray.100" style={[t.pY3, t.pX5]}>
          <Text>{i18n.t('appSettings')}</Text>
        </Box>
        <PropertyList properties={appSettings} />
      </ScrollView>
      <Button style={[t.m4]} variant="outline" onPress={handleSignOut}>
        {i18n.t('signOut')}
      </Button>
    </SafeAreaView>
  );
};
