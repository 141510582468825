import * as React from 'react';
import {Platform, View} from 'react-native';
import Svg, {SvgProps, G, Path} from 'react-native-svg';

const Logo = (props: SvgProps) => (
  <Svg viewBox="0 0 905.7 160.8" {...props}>
    <G fill="#6FC7B4">
      <Path d="M0 2.5h36.2L65.6 50 95 2.5h36.2v123.2H97.1v-71L65.6 103h-.9L33.6 55.1v70.2H0Zm214.4 94.6a33.8 33.8 0 0 0 13-2.5 27.1 27.1 0 0 0 9.7-7.2 37.1 37.1 0 0 0 6.3-10.5c1.7-3.8 2.1-8.4 2.1-12.6v-.4a38.7 38.7 0 0 0-2.1-12.6 43.7 43.7 0 0 0-6.3-10.5 32.7 32.7 0 0 0-10.1-7.2 31.1 31.1 0 0 0-13-2.5 30.5 30.5 0 0 0-12.6 2.5 27.1 27.1 0 0 0-9.7 7.2 24.9 24.9 0 0 0-6.3 10.5 40.6 40.6 0 0 0-2.1 12.6v.4a38.7 38.7 0 0 0 2.1 12.6 43.7 43.7 0 0 0 6.3 10.5 28.6 28.6 0 0 0 10.1 7.2 30.5 30.5 0 0 0 12.6 2.5Zm-.4 30.7a70.2 70.2 0 0 1-26.5-5 72.1 72.1 0 0 1-21-13.5 66.9 66.9 0 0 1-13.9-20.2 60.1 60.1 0 0 1-5-24.8v-.4a60.4 60.4 0 0 1 5-24.8 58.3 58.3 0 0 1 13.9-20.2 65.8 65.8 0 0 1 21-13.9A68.7 68.7 0 0 1 214 0a70.6 70.6 0 0 1 26.5 5 72.1 72.1 0 0 1 21 13.5 66.9 66.9 0 0 1 13.9 20.2 61.7 61.7 0 0 1 5 24.8v.4a62.1 62.1 0 0 1-5 24.8 58.3 58.3 0 0 1-13.9 20.2 65.8 65.8 0 0 1-21 13.9 69.1 69.1 0 0 1-26.5 5ZM298.5 2.5h31.9l50.5 65.2V2.5h33.6v123.2h-29.8l-52.6-67.3v67.3h-33.6Zm137.9 0h98.8v29H470V50h58.9v26.9H470v19.4h66v29h-99.6Zm117.7 0h34v123.2h-34Z" />
      <G opacity={0.6}>
        <Path d="M698.3 8a35.8 35.8 0 0 1 15.9 15.1c3.7 6.6 5.6 14.3 5.6 23.3s-1.9 16.7-5.6 23.3a36 36 0 0 1-15.9 15.2c-6.9 3.5-15 5.2-24.3 5.2h-24.3v35.5h-20V2.7H674c9.3 0 17.4 1.8 24.3 5.3Zm-5.4 56.5c4.5-4.2 6.7-10.2 6.7-18.1s-2.2-13.8-6.7-18-11.1-6.4-19.8-6.4h-23.4v48.8h23.4q13 0 19.8-6.3ZM796.2 40.7q9.6 9.9 9.6 30v54.9h-18.1v-11.4a23.6 23.6 0 0 1-10.1 9.4 35.7 35.7 0 0 1-15.7 3.2 34.8 34.8 0 0 1-16.2-3.6 25.6 25.6 0 0 1-10.6-10 28.3 28.3 0 0 1-3.8-14.5c0-8.4 2.7-15.1 8.2-20.2s14.1-7.7 25.9-7.7h21.2v-1.4c0-6.5-1.7-11.6-5.1-15.1s-8.6-5.2-15.3-5.2a39.8 39.8 0 0 0-13.6 2.4 32.1 32.1 0 0 0-11.3 6.9l-7.5-16a44.8 44.8 0 0 1 15.5-8.6 60.3 60.3 0 0 1 19.2-3c12.1 0 21.3 3.3 27.7 9.9Zm-17.5 66.4a19.5 19.5 0 0 0 7.9-10.8V85.4h-19.8c-11.1 0-16.6 4.1-16.6 12.5a11.6 11.6 0 0 0 4.2 9.4c2.7 2.4 6.6 3.5 11.5 3.5a22.4 22.4 0 0 0 12.8-3.7ZM905.7 31.9l-38.4 101.2c-3.6 10.2-7.9 17.4-13.1 21.5s-11.3 6.2-18.5 6.2a34.6 34.6 0 0 1-12.2-2.2 27.6 27.6 0 0 1-9.8-6.3l7.7-16.2a19 19 0 0 0 6.5 4.7 17.3 17.3 0 0 0 7.6 1.8 12 12 0 0 0 8.4-3c2.2-2 4.2-5.3 6-10l1.4-3.7-35.8-94h20l25.8 69.3 26-69.3Z" />
      </G>
    </G>
  </Svg>
);

export const MoneiLogo = ({height, ...rest}: SvgProps) => {
  const aspectRatio = 141 / 31;
  return (
    <View
      style={{height, aspectRatio: aspectRatio, marginBottom: Platform.OS === 'android' ? -1 : 0}}>
      <Svg viewBox="0 0 141 31" height="100%" {...rest}>
        <G fill="#00c1b1">
          <Path d="M.8.9h8.6l7 11.3 7-11.3H32v29.3h-8.1V13.3l-7.5 11.5h-.2L8.8 13.4v16.7h-8V.9zm51 22.5c1.1 0 2.1-.2 3.1-.6.9-.4 1.7-1 2.3-1.7s1.1-1.5 1.5-2.5c.4-.9.5-2 .5-3v-.1c0-1.1-.2-2.1-.5-3-.4-.9-.9-1.8-1.5-2.5-.7-.7-1.5-1.3-2.4-1.7-.9-.4-1.9-.6-3.1-.6-1.1 0-2.1.2-3 .6-.9.4-1.7 1-2.3 1.7-.7.7-1.2 1.5-1.5 2.5-.3.9-.5 2-.5 3v.1c0 1.1.2 2.1.5 3 .4.9.9 1.8 1.5 2.5.7.7 1.4 1.3 2.4 1.7.9.4 1.9.6 3 .6zm-.1 7.3c-2.3 0-4.3-.4-6.3-1.2-1.9-.8-3.6-1.9-5-3.2-1.4-1.4-2.5-3-3.3-4.8s-1.2-3.8-1.2-5.9v-.1c0-2.1.4-4.1 1.2-5.9.8-1.9 1.9-3.5 3.3-4.8 1.4-1.4 3.1-2.5 5-3.3s4-1.2 6.3-1.2S56 .7 58 1.5c1.9.8 3.6 1.9 5 3.2 1.4 1.4 2.5 3 3.3 4.8s1.2 3.8 1.2 5.9v.1c0 2.1-.4 4.1-1.2 5.9-.8 1.9-1.9 3.5-3.3 4.8-1.4 1.4-3.1 2.5-5 3.3s-4 1.2-6.3 1.2zM71.8.9h7.6l12 15.5V.9h8v29.3h-7.1l-12.5-16v16h-8V.9zm32.8 0h23.5v6.9h-15.5v4.4h14v6.4h-14v4.6h15.7v6.9h-23.7V.9zm28 0h8.1v29.3h-8.1V.9z" />
        </G>
      </Svg>
    </View>
  );
};

export default Logo;
