import {NetworkStatus} from '@apollo/client';
import {Ionicons} from '@expo/vector-icons';
import {NativeStackScreenProps} from '@react-navigation/native-stack';
import {Box, Button, HStack, Text} from 'native-base';
import React, {useLayoutEffect} from 'react';
import {useTranslation} from 'react-i18next';
import {FlatList} from 'react-native';
import {SafeAreaView} from 'react-native-safe-area-context';
import {t} from 'react-native-tailwindcss';
import {
  ChargeFieldsFragment,
  useChargesQuery,
  useOnUpdateChargesSubscription
} from '../api/generated';
import {HeaderButton, HeaderButtonRight} from '../components/HeaderButton';
import {LoadingEntriesSkeleton} from '../components/LoadingEntriesSkeleton';
import {PaymentItem} from '../components/PaymentItem';
import {PaymentSummary} from '../components/PaymentSummary';
import {useAsyncAction} from '../hooks/useAsyncAction';
import {useUserContext} from '../lib/authContext';
import {clearObject, mapDateToFilter, mapStatusToFilter} from '../lib/utils';
import {Nav, NavParams} from './navigation';

const size = 20;

export const PaymentsScreen: React.FC<NativeStackScreenProps<NavParams, Nav.PaymentsScreen>> = ({
  navigation,
  route
}) => {
  const i18n = useTranslation();
  const {accountId} = useUserContext();
  const {filters, search} = route.params ?? {};
  const {data, refetch, networkStatus, fetchMore} = useChargesQuery({
    notifyOnNetworkStatusChange: true,
    fetchPolicy: 'cache-and-network',
    variables: {
      filter: clearObject({
        createdAt: mapDateToFilter(filters?.startDate, filters?.endDate),
        customerEmail: search?.email ? {match: search.email} : undefined,
        orderId: search?.orderID ? {match: search.orderID} : undefined,
        customerPhone: search?.phone ? {match: search.phone} : undefined,
        ...mapStatusToFilter(filters?.status)
      }),
      size,
      from: 0
    }
  });

  const [onRefresh, isRefreshing] = useAsyncAction(refetch);
  const payments = data?.charges.items || [];
  const showSummaryWidget =
    !!(filters?.startDate || filters?.endDate) &&
    (!filters?.status || filters?.status === 'succeeded');

  useLayoutEffect(() => {
    navigation.setOptions({
      headerRight: () => (
        <HStack>
          <HeaderButton
            icon="search"
            displayDot={!!search}
            onPress={() => {
              navigation.navigate(Nav.PaymentSearchScreen, {search});
            }}
          />
          <HeaderButtonRight
            icon="funnel-outline"
            displayDot={!!filters}
            onPress={() => {
              navigation.navigate(Nav.PaymentFilterScreen, {filters});
            }}
          />
        </HStack>
      )
    });
  }, [navigation, filters, search]);

  useOnUpdateChargesSubscription({
    variables: {accountId},
    skip: !navigation.isFocused(),
    onData: () => refetch()
  });

  const handleLoadMore = async () => {
    return fetchMore({
      variables: {
        from: payments.length
      }
    });
  };

  const isLoading =
    networkStatus === NetworkStatus.loading || networkStatus === NetworkStatus.setVariables;

  return (
    <SafeAreaView style={[t.flex1, t.bgWhite]} edges={['bottom']}>
      <Box style={[t.flex1, t.mT2]}>
        {showSummaryWidget && (
          <PaymentSummary filters={filters} shouldRefetch={isRefreshing} isPlaceholder={false} />
        )}
        <LoadingEntriesSkeleton isLoading={isLoading} numberOfEntries={size}>
          <FlatList<ChargeFieldsFragment>
            data={payments}
            onRefresh={onRefresh}
            refreshing={isRefreshing}
            onEndReachedThreshold={0.5}
            initialNumToRender={20}
            ListEmptyComponent={() => (
              <Text style={[t.textGray600, t.textCenter, t.pY6, t.pX4, t.flex1]}>
                {i18n.t('noPayments')}
              </Text>
            )}
            onEndReached={handleLoadMore}
            renderItem={({item}) => (
              <PaymentItem
                item={item}
                key={item.id}
                onPress={() => navigation.navigate(Nav.PaymentScreen, {paymentId: item.id})}
              />
            )}
            keyExtractor={(item) => item.id}
          />
        </LoadingEntriesSkeleton>
      </Box>
    </SafeAreaView>
  );
};
