import {NativeStackScreenProps} from '@react-navigation/native-stack';
import {Button, Box} from 'native-base';
import React, {useEffect, useRef, useState} from 'react';
import {ScrollView, TextInput} from 'react-native';
import {SafeAreaView} from 'react-native-safe-area-context';
import {t} from 'react-native-tailwindcss';
import {useCreatePayment} from '../../hooks/useCreatePayment';
import {useSettings} from '../../hooks/useSettings';
import {useTranslation} from 'react-i18next';
import {Nav, NavParams} from '../navigation';
import {triggerHapticFeedback} from '../../lib/hapticFeedback';
import {AmountInput} from '../../components/AmountInput';
import {KeyboardAvoidingView} from '../../components/KeyboardAvoidingView';
import {CompositeScreenProps} from '@react-navigation/native';
import {CreatePaymentParams, NavCreatePayment} from '.';

const MAX_AMOUNT = 300000;

export const CreatePaymentAmountScreen: React.FC<
  CompositeScreenProps<
    NativeStackScreenProps<CreatePaymentParams, NavCreatePayment.AmountScreen>,
    NativeStackScreenProps<NavParams, Nav.CreatePaymentScreens>
  >
> = ({navigation}) => {
  const [amount, setAmount] = useState(0);
  const i18n = useTranslation();
  const inputRef = useRef<TextInput>(null);
  const [settings] = useSettings();
  const createPayment = useCreatePayment();

  useEffect(() => {
    return navigation.addListener('transitionEnd', (nativeEvent) => {
      if (!nativeEvent.data.closing) {
        inputRef.current?.focus();
      }
    });
  }, [navigation]);

  const handleSubmit = () => {
    if (amount === 0) {
      return;
    }

    if (settings.showDescription) {
      return navigation.navigate(NavCreatePayment.DescriptionScreen, {amount});
    }

    const {id} = createPayment({amount}, navigation.goBack);
    inputRef.current?.blur();

    if (settings.showQR) {
      triggerHapticFeedback();
      navigation.replace(Nav.PaymentQRScreen, {
        paymentId: id
      });
    } else {
      navigation.replace(Nav.RequestToPayScreen, {paymentId: id});
    }
  };

  const isTooLarge = amount > MAX_AMOUNT;
  const isInvalid = isTooLarge || amount <= 0;

  return (
    <SafeAreaView style={[t.flex1, t.bgWhite]} edges={['bottom']}>
      <KeyboardAvoidingView offset={125}>
        <ScrollView
          contentContainerStyle={[t.flex1, t.flex, t.flexCol, t.justifyCenter]}
          keyboardShouldPersistTaps="handled">
          <AmountInput
            ref={inputRef}
            onAmountChange={setAmount}
            amount={amount}
            onSubmit={handleSubmit}
            maxAmount={MAX_AMOUNT}
            getMaxAmountErrorMessage={(maxAmountString: string) =>
              i18n.t('maxAmountError', {
                amount: maxAmountString
              })
            }
          />
        </ScrollView>
        <Box style={[t.p4]}>
          <Button isDisabled={isInvalid} onPress={handleSubmit}>
            {settings.showDescription ? i18n.t('continue') : i18n.t('createPayment')}
          </Button>
        </Box>
      </KeyboardAvoidingView>
    </SafeAreaView>
  );
};
