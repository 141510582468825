
export function isValidAccessLevel(a:unknown): a is AccessLevels{
  return typeof a === "string" && Object.values<string>(AccessLevels).includes(a)
}

export enum AccessLevels {
  ADMIN_ACCESS = 'admin_access',
  WRITE_ACCESS = 'write_access',
  READ_ACCESS = 'read_access'
}
