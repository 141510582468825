import {NativeStackNavigationOptions} from '@react-navigation/native-stack';
import {HeaderBackButton} from '@react-navigation/elements';
import {Button, CloseIcon, useTheme} from 'native-base';
import React from 'react';
import {Platform, useWindowDimensions} from 'react-native';
import {t} from 'react-native-tailwindcss';
import {AccountFieldsFragment, Maybe, useAccountQuery} from '../api/generated';
import {AccountContext} from '../lib/accountContext';
import {setAdminEmail} from '../storage/sessionStorage';
import {HomeScreen} from './HomeScreen';
import {Nav, Stack} from './navigation';
import {NotFoundScreen} from './NotFoundScreen';
import {PaymentQRScreen} from './PaymentQRScreen';
import {PaymentScreen} from './PaymentScreen';
import {PaymentsScreen} from './PaymentsScreen';
import {PaymentFilterScreen} from './PaymentFilterScreen';
import {PaymentSearchScreen} from './PaymentSearchScreen';
import {RequestToPayScreen} from './RequestToPayScreen';
import {SettingsScreen} from './SettingsScreen';
import {CreatePaymentScreens, createPaymentStackLinking} from './CreatePaymentScreens';
import {useTranslation} from 'react-i18next';
import {RefundScreen} from './RefundScreen';
import {HeaderButtonLeft, HeaderButtonRight} from '../components/HeaderButton';

export const mainStackLinking = {
  initialRouteName: Nav.HomeScreen,
  screens: {
    [Nav.HomeScreen]: '/',
    [Nav.PaymentQRScreen]: '/payments/:paymentId/qr',
    [Nav.PaymentScreen]: '/payments/:paymentId',
    [Nav.RefundScreen]: '/payments/:paymentId/refund',
    [Nav.PaymentsScreen]: {
      path: '/payments',
      stringify: {
        // @ts-ignore
        filters: (filters) => {
          return JSON.stringify(filters);
        },
        // @ts-ignore
        search: (search) => {
          return JSON.stringify(search);
        }
      },
      parse: {
        // @ts-ignore
        filters: (filters) => {
          return JSON.parse(filters);
        },
        // @ts-ignore
        search: (search) => {
          return JSON.parse(search);
        }
      }
    },
    [Nav.RequestToPayScreen]: '/payments/:paymentId/request',
    [Nav.SettingsScreen]: '/settings',
    [Nav.CreatePaymentScreens]: createPaymentStackLinking,
    [Nav.PaymentFilterScreen]: {
      path: '/payments/filter',
      stringify: {
        // @ts-ignore
        filters: (filters) => {
          return JSON.stringify(filters);
        }
      },
      parse: {
        // @ts-ignore
        filters: (filters) => {
          return JSON.parse(filters);
        }
      }
    },
    [Nav.PaymentSearchScreen]: {
      path: '/payments/search',
      stringify: {
        // @ts-ignore
        search: (search) => {
          return JSON.stringify(search);
        }
      },
      parse: {
        // @ts-ignore
        search: (search) => {
          return JSON.parse(search);
        }
      }
    },
    [Nav.NotFound]: '*'
  }
};

export const getAccountName = (account?: Maybe<AccountFieldsFragment>) => {
  if (!account) return '';
  return (
    account.name ||
    account.publicBusinessDetails?.companyName ||
    account.business?.companyName ||
    account.business?.legalName
  );
};

export const modalOptions = (goBack: () => void) => ({
  headerLeft: () => {
    if (Platform.OS !== 'web') {
      return <HeaderButtonLeft icon={'close'} onPress={goBack} />;
    }
    return <HeaderBackButton onPress={goBack} />;
  },
  presentation: Platform.OS === 'web' ? ('card' as const) : ('modal' as const)
});

export const MainStack: React.FC = () => {
  const {data} = useAccountQuery();
  const i18n = useTranslation();
  const accountName = getAccountName(data?.account) || i18n.t('home');
  const adminEmail = data?.account?.adminEmail;
  const {width} = useWindowDimensions();
  const {colors} = useTheme();

  setAdminEmail(adminEmail);

  return (
    <AccountContext.Provider value={data?.account ?? null}>
      <Stack.Navigator
        initialRouteName={Nav.HomeScreen}
        screenOptions={{
          headerTitleAlign: 'center',
          headerBackTitleVisible: false,
          headerTintColor: colors.primary[600],
          headerTitleStyle: {
            color: 'black'
          }
        }}>
        <Stack.Screen
          name={Nav.HomeScreen}
          component={HomeScreen}
          options={({navigation}) => ({
            title: accountName ?? undefined,
            headerRight: () => (
              <HeaderButtonRight
                onPress={() => navigation.navigate(Nav.SettingsScreen)}
                icon="settings-outline"
              />
            )
          })}
        />
        <Stack.Screen
          name={Nav.SettingsScreen}
          component={SettingsScreen}
          options={({navigation: {goBack}}) => ({
            title: i18n.t('settings'),
            ...modalOptions(goBack)
          })}
        />
        <Stack.Screen
          name={Nav.CreatePaymentScreens}
          component={CreatePaymentScreens}
          options={({navigation: {goBack}}) => ({
            title: i18n.t('createPayment'),
            headerShown: false,
            ...modalOptions(goBack)
          })}
        />
        <Stack.Screen
          name={Nav.PaymentQRScreen}
          component={PaymentQRScreen}
          options={({navigation: {goBack}}) => ({
            title: i18n.t('QRCode'),
            ...modalOptions(goBack)
          })}
        />
        <Stack.Screen
          name={Nav.PaymentsScreen}
          component={PaymentsScreen}
          options={{title: i18n.t('payments')}}
        />
        <Stack.Screen
          name={Nav.PaymentScreen}
          component={PaymentScreen}
          options={({navigation: {goBack}}) => ({
            title: i18n.t('payment'),
            ...modalOptions(goBack)
          })}
        />
        <Stack.Screen
          name={Nav.RequestToPayScreen}
          component={RequestToPayScreen}
          options={({navigation: {goBack}}) => ({
            title: i18n.t('requestToPay'),
            ...modalOptions(goBack)
          })}
        />
        <Stack.Screen
          name={Nav.RefundScreen}
          component={RefundScreen}
          options={({navigation: {goBack}}) => ({
            title: i18n.t('refundPayment'),
            ...modalOptions(goBack)
          })}
        />
        <Stack.Screen
          name={Nav.PaymentFilterScreen}
          component={PaymentFilterScreen}
          options={({navigation: {goBack}}) => ({
            title: i18n.t('paymentFilter'),
            ...modalOptions(goBack)
          })}
        />
        <Stack.Screen
          name={Nav.PaymentSearchScreen}
          component={PaymentSearchScreen}
          options={({navigation: {goBack}}) => ({
            title: i18n.t('paymentSearch'),
            ...modalOptions(goBack)
          })}
        />
        <Stack.Screen name={Nav.NotFound} component={NotFoundScreen} options={{title: '404'}} />
      </Stack.Navigator>
    </AccountContext.Provider>
  );
};
