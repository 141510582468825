import * as Clipboard from 'expo-clipboard';
import * as Sharing from 'expo-sharing';
import {Toast} from 'native-base';
import {t} from 'react-native-tailwindcss';
import {ChargeStatus} from '../api/generated';
import i18n from './i18n';
import {Platform, Share, Alert} from 'react-native';
import {pickBy, identity, pick} from 'lodash';
import type {StatusKey} from '../screens/navigation';

export const getStatusStyle = (status: ChargeStatus) => {
  switch (status) {
    case ChargeStatus.SUCCEEDED: {
      return t.textGreen500;
    }
    case ChargeStatus.FAILED: {
      return t.textRed500;
    }
    default: {
      return t.textGray500;
    }
  }
};

export const shareUrl = async (url: string) => {
  if (Platform.OS === 'web') {
    if (await Sharing.isAvailableAsync()) {
      await Sharing.shareAsync(url);
      return;
    }
    Clipboard.setString(url);
    Toast.show({
      description: i18n.t('copied'),
      duration: 1500
    });
    return;
  }
  try {
    const options = Platform.OS === 'ios' ? {url} : {message: url};

    await Share.share({
      title: i18n.t('sharePayment'),
      ...options
    });
  } catch (error) {
    if (error instanceof Error) {
      Alert.alert('Error', error.message);
    }
  }
};

/**
 * Generates a hex random string
 * @param size: number
 */

export const randomHex = (size: number) => {
  return [...Array(size)].map(() => Math.floor(Math.random() * 16).toString(16)).join('');
};

export const localeMap = (deviceLocale = i18n.language) => {
  switch (deviceLocale) {
    case 'es-ES':
    case 'ca-ES':
      return 'es-es';
  }
  return 'en-us';
};

export function mapDateToFilter(start: number | undefined, end: number | undefined) {
  if (end && start) {
    if (start > end) return undefined;
    return {range: [start, end]};
  }
  if (start && !end) {
    return {gte: start};
  }
  if (end && !start) {
    return {lte: end};
  }
  return undefined;
}

export function clearObject<A extends object>(obj: A) {
  const trimmedObject = pickBy(obj, identity);
  return Object.keys(trimmedObject).length > 0 ? trimmedObject : undefined;
}

export function mapStatusToFilter(status: StatusKey | undefined) {
  switch (status) {
    case 'succeeded':
      return {status: {match: ChargeStatus.SUCCEEDED}};
    case 'refunded':
      return {
        or: [
          {status: {match: ChargeStatus.REFUNDED}},
          {status: {match: ChargeStatus.PARTIALLY_REFUNDED}}
        ]
      };
    case 'failed':
      return {status: {match: ChargeStatus.FAILED}};
    case 'uncaptured':
      return {status: {match: ChargeStatus.AUTHORIZED}};
    default:
      return {status: undefined};
  }
}
