import {ApolloClient, ApolloLink, ApolloProvider, createHttpLink} from '@apollo/client';
import {GraphQLErrors} from '@apollo/client/errors';
import {onError} from '@apollo/client/link/error';
import {Auth} from 'aws-amplify';
import {AUTH_TYPE, AuthOptions, createAuthLink} from 'aws-appsync-auth-link';
import {createSubscriptionHandshakeLink} from 'aws-appsync-subscription-link';
import React from 'react';
import {Loader} from '../components/Loader';
import {useLockscreen} from '../hooks/useLockcreen';
import {cache} from '../lib/cache';
import {showError, showGraphQLError} from '../lib/errorHandler';
import {LockscreenContextProvider} from '../lib/lockscreenContext';
import {config} from '../stage.config';
import {CreatePasscode} from './Lockscreen/CreatePasscode';
import {LockScreen} from './Lockscreen/LockScreen';
import {MainStack} from './MainStack';

const httpLink = createHttpLink({uri: config.apiEndpoint});

const params = {
  url: config.apiEndpoint,
  region: config.region,
  auth: {
    type: AUTH_TYPE.AMAZON_COGNITO_USER_POOLS,
    jwtToken: async () => (await Auth.currentSession()).getIdToken().getJwtToken()
  } as AuthOptions
};

const authLink = createAuthLink(params);

const subscriptionLink = createSubscriptionHandshakeLink(params, httpLink);

function shouldIgnoreError(graphQLErrors: GraphQLErrors) {
  return graphQLErrors[0].path?.[0] === 'charge';
}

const errorLink = onError(({graphQLErrors, networkError}) => {
  if (graphQLErrors && graphQLErrors.length > 0) {
    if (shouldIgnoreError(graphQLErrors)) {
      return;
    }
    if (graphQLErrors[0].message) {
      showGraphQLError(graphQLErrors[0]);
    }
  }
  if (networkError?.message) {
    showError(networkError.message);
  }
});

const link = ApolloLink.from([errorLink, authLink, subscriptionLink]);

const client = new ApolloClient({
  link,
  connectToDevTools: __DEV__,
  cache
});

function ScreensWithLockScreen() {
  const {
    passcodeState: {hasPasscode, hasPassedLockscreen, isLoading}
  } = useLockscreen();

  if (isLoading) {
    return <Loader active />;
  }
  if (!hasPasscode) {
    return <CreatePasscode />;
  }
  if (!hasPassedLockscreen) {
    return <LockScreen />;
  }

  return <MainStack />;
}

export const Screens: React.FC = () => {
  return (
    <ApolloProvider client={client}>
      <LockscreenContextProvider>
        <ScreensWithLockScreen />
      </LockscreenContextProvider>
    </ApolloProvider>
  );
};
