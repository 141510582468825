import {useMemo} from 'react';
import {useUserContext} from '../lib/authContext';
import {AccessLevels} from '../lib/userContext';

export const useAccessLevelCheck = () => {
  const {accessLevels} = useUserContext();
  const hasAdminAccess = accessLevels.includes(AccessLevels.ADMIN_ACCESS);
  const hasWriteAccess = hasAdminAccess || accessLevels.includes(AccessLevels.WRITE_ACCESS)
  const hasReadAccess = hasWriteAccess || accessLevels.includes(AccessLevels.READ_ACCESS)
  return useMemo(
    () => ({
      hasAdminAccess,
      hasWriteAccess,
      hasReadAccess,
    }),
    [accessLevels]
  );
};
