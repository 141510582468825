import React, {useEffect, useRef, useState} from 'react';
import {ScrollView} from 'react-native';
import {SafeAreaView} from 'react-native-safe-area-context';
import {t} from 'react-native-tailwindcss';
import {Text, Button, Box} from 'native-base';
import {useUserContext} from '../../lib/authContext';
import {
  BiometryType,
  canAuthenticateUsingBiometry,
  getBiometryType,
  getBiometryTypeString,
  hasEnrolledBiometry,
  promptBiometry
} from '../../lib/biometryUtils';
import Logo from '../../components/Logo';
import {PinInput} from '../../components/PinInput';
import {useLockscreen} from '../../hooks/useLockcreen';
import {TextInput} from 'react-native-gesture-handler';
import {KeyboardAvoidingView} from '../../components/KeyboardAvoidingView';
import {useTranslation} from 'react-i18next';
import {useSignOut} from '../../hooks/useSignOut';

export const LockScreen = () => {
  const i18n = useTranslation();
  const {userEmail} = useUserContext();
  const [biometryType, setBiometryType] = useState<BiometryType | undefined>();
  const [pin, setPin] = useState<string>('');
  const {getStoredPin, setLockscreenPassed} = useLockscreen();
  const pinRef = useRef<TextInput>(null);
  const [errorMessage, setErrorMessage] = useState('');
  const [numerOfAttempts, setNumberOfAttemtps] = useState(0);
  const signOut = useSignOut();

  useEffect(() => {
    const checkBiometry = async () => {
      if (await canAuthenticateUsingBiometry()) {
        const type = await getBiometryType();
        setBiometryType(type);
        biometryAuthentication();
      } else {
        pinRef.current?.focus();
      }
    };
    checkBiometry();
  }, []);

  const onChangePin = async (text: string) => {
    setPin(text);
    if (errorMessage) {
      setErrorMessage('');
    }
    if (text.length === 4) {
      const storedPin = await getStoredPin();
      if (text === storedPin) {
        setLockscreenPassed();
      } else {
        setErrorMessage(i18n.t('invalidPasscode'));
        setPin('');
        if (numerOfAttempts === 4) {
          signOutUser();
        } else {
          setNumberOfAttemtps(numerOfAttempts + 1);
        }
      }
    }
  };

  const biometryAuthentication = async () => {
    if (await hasEnrolledBiometry()) {
      const authenticated = await promptBiometry(i18n.t('biometryLoginPrompt', {email: userEmail}));
      if (authenticated) {
        setLockscreenPassed();
      }
    }
  };

  const signOutUser = () => {
    signOut();
  };

  const handleSignOut = () => {
    signOutUser();
  };

  return (
    <SafeAreaView style={[t.flex1, t.bgWhite]} edges={['bottom']}>
      <KeyboardAvoidingView>
        <ScrollView
          contentContainerStyle={[t.flex, t.flex1, t.flexCol, t.justifyCenter]}
          keyboardShouldPersistTaps="handled">
          <Box style={t.p6}>
            <Box style={[t.mB4, {alignItems: 'center', justifyContent: 'center'}]}>
              <Logo width="250" height="50" style={[t.m4]} />
            </Box>
            <Box style={[t.itemsCenter, t.mB4]}>
              <Text style={[t.text2xl, t.mB1]}>{i18n.t('loginWelcome')}</Text>
              <Text>{userEmail}</Text>
            </Box>
            <Box style={t.h5}>
              <PinInput
                label={i18n.t('enterPasscode')}
                ref={pinRef}
                value={pin}
                onChange={onChangePin}
                errorMessage={errorMessage}
              />
            </Box>
          </Box>
        </ScrollView>
        <Box>
          <Button onPress={handleSignOut} variant={'ghost'} style={[biometryType ? t.mX6 : t.m6]}>
            {i18n.t('signOut')}
          </Button>
          {biometryType && (
            <Button onPress={biometryAuthentication} style={[t.m6, t.mT4]}>
              {i18n.t('biometryLogin', {biometryType: getBiometryTypeString(biometryType)})}
            </Button>
          )}
        </Box>
      </KeyboardAvoidingView>
    </SafeAreaView>
  );
};
