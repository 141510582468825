import React, {useLayoutEffect, useRef, useState} from 'react';
import {NativeStackScreenProps} from '@react-navigation/native-stack';
import {ScrollView, TextInput} from 'react-native';
import {SafeAreaView} from 'react-native-safe-area-context';
import {Nav, NavParams, PaymentSearch} from './navigation';
import {t} from 'react-native-tailwindcss';
import {Box, Button, HStack, Input} from 'native-base';
import {useTranslation} from 'react-i18next';
import {clearObject} from '../lib/utils';
import {KeyboardAvoidingView} from '../components/KeyboardAvoidingView';
import {Select} from '../components/Select';
import {PhoneNumberInput} from '../components/PhoneNumberInput';
import {HeaderButtonRight} from '../components/HeaderButton';

type SearchKey = 'email' | 'orderID' | 'phone';

export const PaymentSearchScreen: React.FC<
  NativeStackScreenProps<NavParams, Nav.PaymentSearchScreen>
> = ({navigation, route}) => {
  const {search} = route.params ?? {};
  const i18n = useTranslation();

  const [email, setEmail] = useState(search?.email ?? '');
  const [orderID, setOrderId] = useState(search?.orderID ?? '');
  const [phone, setPhoneNumber] = useState(search?.phone ?? '');
  const [selectedSearch, setSelectedSearch] = useState<SearchKey>(
    search?.email ? 'email' : search?.phone ? 'phone' : 'orderID'
  );
  const valueInputRef = useRef<TextInput>();

  const options: Record<SearchKey, string> = {
    email: i18n.t('email'),
    orderID: i18n.t('orderId'),
    phone: i18n.t('phoneNumber')
  };

  const submitDisabled = !email && !orderID && !phone;

  const onSubmit = () => {
    if (submitDisabled) {
      return;
    }
    navigation.navigate(Nav.PaymentsScreen, {
      search: clearObject<PaymentSearch>({
        email,
        orderID,
        phone
      })
    });
  };

  useLayoutEffect(() => {
    navigation.setOptions({
      headerRight: () => (
        <HeaderButtonRight
          onPress={() => {
            navigation.navigate(Nav.PaymentsScreen, {search: undefined});
          }}
          text={i18n.t('reset')}
        />
      )
    });
  }, [navigation, i18n]);

  const renderInput = () => {
    const commonProps = {
      onSubmitEditing: onSubmit,
      autoFocus: true,
      ref: valueInputRef,
      returnKeyType: 'search' as const
    };

    if (selectedSearch === 'email') {
      return (
        <Input
          {...commonProps}
          value={email}
          onChangeText={setEmail}
          keyboardType="email-address"
        />
      );
    }
    if (selectedSearch === 'orderID') {
      return (
        <Input
          {...commonProps}
          value={orderID}
          onChangeText={(newText) => setOrderId(newText.toUpperCase())}
          keyboardType="ascii-capable"
        />
      );
    }
    if (selectedSearch === 'phone') {
      return (
        <PhoneNumberInput
          {...commonProps}
          value={phone}
          countryCode="ES"
          onChangeText={(newNumber) => setPhoneNumber(newNumber)}
        />
      );
    }
  };

  return (
    <SafeAreaView style={[t.flex1, t.bgWhite]} edges={['bottom']}>
      <KeyboardAvoidingView offset={125}>
        <ScrollView style={[t.flex1]} contentContainerStyle={[t.pX4, t.pY2]}>
          <HStack style={[t.itemsCenter]}>
            <Box flex={1}>
              <Select
                onSelected={(newKey) => {
                  setSelectedSearch(newKey as SearchKey);
                  setEmail('');
                  setOrderId('');
                  setPhoneNumber('');
                }}
                nextInputRef={valueInputRef}
                selectedValue={selectedSearch}
                text={options[selectedSearch]}
                options={options}
              />
            </Box>
          </HStack>

          {renderInput()}
        </ScrollView>
        <Box style={[t.p4]}>
          <Button isDisabled={submitDisabled} onPress={onSubmit}>
            {i18n.t('search')}
          </Button>
        </Box>
      </KeyboardAvoidingView>
    </SafeAreaView>
  );
};
