import {NavigatorScreenParams} from '@react-navigation/native';
import {createNativeStackNavigator} from '@react-navigation/native-stack';
import {CreatePaymentParams} from './CreatePaymentScreens';

export const Stack = createNativeStackNavigator<NavParams>();

export enum Nav {
  HomeScreen = 'HomeScreen',
  SettingsScreen = 'SettingsScreen',
  CreatePaymentScreens = 'CreatePaymentScreens',
  PaymentScreen = 'PaymentScreen',
  PaymentsScreen = 'PaymentsScreen',
  PaymentQRScreen = 'PaymentQRScreen',
  RefundScreen = 'RefundScreen',
  PaymentFilterScreen = 'PaymentFilterScreen',
  PaymentSearchScreen = 'PaymentSearchScreen',
  RequestToPayScreen = 'RequestToPayScreen',
  NotFound = 'NotFound'
}

export type StatusKey = 'succeeded' | 'refunded' | 'uncaptured' | 'failed';

export interface PaymentFilter {
  endDate?: number;
  startDate?: number;
  status?: StatusKey;
}

export interface PaymentSearch {
  email?: string;
  orderID?: string;
  phone?: string;
}

export type NavParams = {
  [Nav.HomeScreen]: undefined;
  [Nav.SettingsScreen]: undefined;
  [Nav.CreatePaymentScreens]: NavigatorScreenParams<CreatePaymentParams>;
  [Nav.PaymentsScreen]: {filters?: PaymentFilter; search?: PaymentSearch};
  [Nav.PaymentScreen]: {paymentId: string};
  [Nav.PaymentQRScreen]: {paymentId: string};
  [Nav.RequestToPayScreen]: {paymentId: string};
  [Nav.RefundScreen]: {paymentId: string};
  [Nav.PaymentFilterScreen]: {filters?: PaymentFilter};
  [Nav.PaymentSearchScreen]: {search?: PaymentSearch};
  [Nav.NotFound]: undefined;
};
