import {useFocusEffect} from '@react-navigation/native';
import {NativeStackScreenProps} from '@react-navigation/native-stack';
import {Auth} from 'aws-amplify';
import {Box, Button, Text, FormControl, VStack, WarningOutlineIcon, Input} from 'native-base';
import React, {useCallback, useContext, useRef, useState} from 'react';
import {Controller, useForm} from 'react-hook-form';
import {ScrollView, TextInput} from 'react-native';
import {SafeAreaView} from 'react-native-safe-area-context';
import {t} from 'react-native-tailwindcss';
import {Anchor} from '../components/Anchor';
import {KeyboardAvoidingView} from '../components/KeyboardAvoidingView';
import Logo from '../components/Logo';
import {AuthContext} from '../lib/authContext';
import {config} from '../stage.config';
import {AuthNav, AuthNavParams} from './navigation';
import {useTranslation} from 'react-i18next';

export const LogInScreen: React.FC<NativeStackScreenProps<AuthNavParams, AuthNav.LogInScreen>> = ({
  navigation
}) => {
  const i18n = useTranslation();
  const {
    control,
    handleSubmit,
    setError,
    reset,
    formState: {errors}
  } = useForm({defaultValues: {email: '', password: ''}});
  const {setAuthState} = useContext(AuthContext);
  const [isLoading, setLoading] = useState(false);
  const passwordInputRef = useRef<TextInput>();

  useFocusEffect(
    useCallback(() => {
      reset();
    }, [reset])
  );

  const onSubmit = async ({email, password}: any) => {
    setLoading(true);
    try {
      const user = await Auth.signIn(email, password);
      if (user.challengeName) {
        if (user.challengeName === 'SMS_MFA' || user.challengeName === 'SOFTWARE_TOKEN_MFA') {
          setAuthState({
            currentUser: user,
            mfaDestination: user.challengeParam?.CODE_DELIVERY_DESTINATION,
            mfaType: user.challengeName,
            isAuthenticated: false
          });
          return navigation.navigate(AuthNav.ConfirmLogInScreen);
        }
      }
      const currentUser = await Auth.currentAuthenticatedUser();
      setAuthState({currentUser, isAuthenticated: true});
    } catch (error) {
      if (error instanceof Error) {
        setError('email', {type: 'manual', message: error.message});
      }
    } finally {
      setLoading(false);
    }
  };

  return (
    <SafeAreaView style={[t.flex1, t.bgWhite]} edges={['bottom']}>
      <KeyboardAvoidingView>
        <ScrollView
          contentContainerStyle={[t.flex, t.flex1, t.flexCol, t.justifyCenter]}
          keyboardShouldPersistTaps="handled">
          <Box style={[t.p6]}>
            <Box style={[t.mB2, {alignItems: 'center', justifyContent: 'center'}]}>
              <Logo width="250" height="50" style={[t.m4]} />
            </Box>
            <VStack space={3}>
              <FormControl isRequired isInvalid={'email' in errors}>
                <Controller
                  name="email"
                  control={control}
                  defaultValue=""
                  rules={{
                    required: i18n.t('emailRequired')
                  }}
                  render={({field: {onChange, onBlur, value}}) => (
                    <Input
                      autoFocus
                      value={value}
                      onBlur={onBlur}
                      autoCorrect={false}
                      autoCapitalize="none"
                      onChangeText={(text) => onChange(text)}
                      onSubmitEditing={() => passwordInputRef.current?.focus()}
                      blurOnSubmit={false}
                      returnKeyType="next"
                      placeholder={i18n.t('email')}
                      textContentType="emailAddress"
                      keyboardType="email-address"
                    />
                  )}
                />
                <FormControl.ErrorMessage leftIcon={<WarningOutlineIcon size="xs" />}>
                  {errors?.email?.message}
                </FormControl.ErrorMessage>
              </FormControl>
              <FormControl isRequired isInvalid={'password' in errors}>
                <Controller
                  name="password"
                  defaultValue=""
                  control={control}
                  rules={{
                    required: i18n.t('passwordRequired')
                  }}
                  render={({field: {onChange, onBlur, value}}) => (
                    <Input
                      ref={passwordInputRef}
                      value={value}
                      onBlur={onBlur}
                      autoCorrect={false}
                      autoCapitalize="none"
                      onChangeText={(text) => onChange(text)}
                      returnKeyType="done"
                      onSubmitEditing={() => handleSubmit(onSubmit)()}
                      placeholder={i18n.t('password')}
                      textContentType="password"
                      secureTextEntry
                    />
                  )}
                />
                <FormControl.ErrorMessage leftIcon={<WarningOutlineIcon size="xs" />}>
                  {errors?.password?.message}
                </FormControl.ErrorMessage>
              </FormControl>
              <Text color={'black'} style={t.textCenter}>
                {i18n.t('dontHaveAccount')}{' '}
                <Anchor href={config.signUpUrl}>{i18n.t('signUp')}</Anchor>
              </Text>
            </VStack>
          </Box>
        </ScrollView>
        <Box style={t.p6}>
          <Button isLoading={isLoading} onPress={handleSubmit(onSubmit)} style={[t.rounded]}>
            {i18n.t('logIn')}
          </Button>
        </Box>
      </KeyboardAvoidingView>
    </SafeAreaView>
  );
};
