/**
 * File to store values in memmory
 */

/**
 * Account admin email to include it in unauthorized alerts messages
 */

let adminEmail: string | null | undefined;

export const setAdminEmail = (email?: string | null) => (adminEmail = email);
export const getAdminEmail = () => adminEmail || 'support@monei.com';
