import {Box, ChevronRightIcon, Divider, HStack, List, Text} from 'native-base';
import {useSafeAreaInsets} from 'react-native-safe-area-context';
import React from 'react';
import {t} from 'react-native-tailwindcss';
import Menu from './Menu';
import {PressableFeedback} from './PressableFeedback';

export type Property = {
  label: string | JSX.Element;
  value?: string | null | false | JSX.Element;
  menu?: {
    options: Record<string, string>;
    onSelected: (newKey: string) => void;
  };
  onPress?: () => void;
};
export type Properties = Array<Property>;

type Props = {
  properties: Properties;
  showEndDivider?: boolean;
};

interface PropertyListItemProps {
  p: Property;
  showDivider: boolean;
  onPress?: () => void;
}

const PropertyListItem = React.forwardRef<any, PropertyListItemProps>(
  ({p, showDivider, onPress}, ref) => (
    <Box style={[t.mL5]}>
      <PressableFeedback onPress={onPress} ref={ref}>
        <HStack justifyContent="space-between" alignItems="center" style={[t.mR5, t.h12]}>
          <Box style={t.flex1}>
            {typeof p.label === 'string' ? <Text>{p.label}</Text> : p.label}
          </Box>
          {typeof p.value === 'string' ? <Text style={[t.textRight]}>{p.value}</Text> : p.value}
          {!p.value && onPress ? <ChevronRightIcon /> : null}
        </HStack>
      </PressableFeedback>
      {showDivider && <Divider bgColor={'gray.200'} />}
    </Box>
  )
);

export const PropertyList: React.FC<Props> = ({properties, showEndDivider}) => {
  const insets = useSafeAreaInsets();
  return (
    <List style={{paddingTop: 0, paddingBottom: 0}} borderColor="transparent">
      {properties
        .filter((prop) => prop.value || prop.onPress)
        .map((p, i, {length}) => {
          const {menu, value} = p;
          if (menu && typeof value === 'string') {
            const {options, onSelected} = menu;
            return (
              <Menu
                key={i}
                value={value}
                onSelected={onSelected}
                options={options}
                trigger={(ref, onPress) => (
                  <PropertyListItem
                    p={{...p, value: options[value]}}
                    showDivider={length - 1 !== i || !!showEndDivider}
                    onPress={onPress}
                    ref={ref}
                  />
                )}
              />
            );
          }
          return (
            <PropertyListItem
              key={i}
              p={p}
              showDivider={length - 1 !== i || !!showEndDivider}
              onPress={p.onPress}
            />
          );
        })}
    </List>
  );
};

export const PropertyCardList: React.FC<Props> = ({properties, showEndDivider}) => (
  <>
    {properties
      .filter((prop) => prop.value || prop.onPress)
      .map((p, i, {length}) => {
        return (
          <Box key={i}>
            <PressableFeedback onPress={p.onPress}>
              <HStack style={[t.itemsCenter, t.pX2, t.pY2]}>
                <Box style={[t.flex1, {alignItems: 'flex-start'}]}>
                  <Text style={[t.textSm, t.textGray600, {marginBottom: -2}]}>{p.label}</Text>
                  <Text>{p.value}</Text>
                </Box>
                {p.onPress ? <ChevronRightIcon /> : null}
              </HStack>
              {(length - 1 !== i || showEndDivider) && <Divider bgColor={'gray.200'} />}
            </PressableFeedback>
          </Box>
        );
      })}
  </>
);
