import {Box, Center, Text} from 'native-base';
import React, {useEffect, useMemo} from 'react';
import {
  NativeSyntheticEvent,
  Platform,
  StyleSheet,
  TextInput,
  TextInputKeyPressEventData
} from 'react-native';
import {t} from 'react-native-tailwindcss';
import {moneyFormatter} from '../lib/formatters';
import {CURRENCY} from '../lib/paymentUtils';

interface Props {
  amount: number;
  maxAmount: number;
  getMaxAmountErrorMessage: (maxAmountString: string) => string;
  onAmountChange: (newAmount: number) => void;
  hint?: string;
  onSubmit: () => void;
  onError?: () => void;
}

export const AmountInput = React.forwardRef<TextInput, Props>(
  ({onAmountChange, onSubmit, amount, maxAmount, getMaxAmountErrorMessage, onError, hint}, ref) => {
    const money = moneyFormatter(CURRENCY);

    const amountParts = money
      .formatToParts(amount / 100)
      .reduce<Record<string, string>>((result, part) => {
        if (result[part.type]) {
          result[part.type] += part.value;
        } else {
          result[part.type] = part.value;
        }
        return result;
      }, {});

    const updateAmount = (value: string) => {
      if (value === '') return onAmountChange(0);
      const amount = parseInt(value);
      if (isNaN(amount) || amount >= 1000000) return;
      onAmountChange(amount);
    };

    const handleChange = (value: string) => {
      updateAmount(`${amount}${value}`);
    };

    const handleKeyPress = (e: NativeSyntheticEvent<TextInputKeyPressEventData>) => {
      if (e.nativeEvent.key === 'Backspace') {
        const value = String(amount).slice(0, -1);
        updateAmount(value);
      }
    };

    const showError = useMemo(() => {
      return amount > maxAmount;
    }, [amount, maxAmount]);

    useEffect(() => {
      if (showError && onError) {
        onError();
      }
    }, [showError]);

    return (
      <Box style={[t.flex]}>
        <Box style={[t.flex, t.flexRow, t.justifyCenter, t.pY2]}>
          <TextInput
            ref={ref}
            style={styles.input}
            keyboardType="numeric"
            // @ts-ignore
            inputMode="numeric"
            autoComplete="off"
            autoCorrect={false}
            autoFocus={Platform.OS === 'web'}
            autoCapitalize="none"
            onSubmitEditing={onSubmit}
            returnKeyType="done"
            value=""
            onKeyPress={handleKeyPress}
            onChangeText={handleChange}
          />
          <Box
            _text={{
              fontSize: '8xl'
            }}>
            {amountParts.currency}
          </Box>
          <Box
            style={t.mX2}
            _text={{
              fontSize: '8xl'
            }}>
            {amountParts.integer}
          </Box>
          <Box
            style={t.mT5}
            _text={{
              fontSize: '5xl'
            }}>
            {amountParts.fraction}
          </Box>
        </Box>
        {(hint || showError) && (
          <Box style={[{top: '100%'}, t.insetX0, t.absolute, t.flex, t.itemsCenter]}>
            <Box style={[{maxWidth: 300}, t.mX4]}>
              <Text textAlign="center" color={showError ? 'red.500' : 'gray.400'}>
                {showError ? getMaxAmountErrorMessage(money.format(maxAmount / 100)) : hint}
              </Text>
            </Box>
          </Box>
        )}
      </Box>
    );
  }
);

const styles = StyleSheet.create({
  input: {
    fontSize: 90,
    position: 'absolute',
    width: '100%',
    height: '100%',
    left: 0,
    right: 0,
    top: 0,
    bottom: 0,
    textAlign: 'center',
    zIndex: 1,
    opacity: 0
  }
});
