import React, {useEffect, useLayoutEffect, useState} from 'react';
import {NativeStackScreenProps} from '@react-navigation/native-stack';
import {ScrollView} from 'react-native';
import {SafeAreaView} from 'react-native-safe-area-context';
import {Nav, NavParams, PaymentFilter, StatusKey} from './navigation';
import {t} from 'react-native-tailwindcss';
import {DateInput} from '../components/DateInput';
import {Box, Button, Divider, FormControl, HStack, Text, WarningOutlineIcon} from 'native-base';
import moment from '../lib/moment';
import {useTranslation} from 'react-i18next';
import {clearObject} from '../lib/utils';
import {Select} from '../components/Select';
import {HeaderButtonRight} from '../components/HeaderButton';

function mergeDateAndDatetime(day: Date | undefined, time: Date | undefined, seconds: number) {
  return day
    ? moment(day)
        .set('hours', time ? moment(time).get('hours') : 0)
        .set('minutes', time ? moment(time).get('minutes') : 0)
        .set('seconds', seconds)
        .unix()
    : undefined;
}

function dateFromUnix(date: number | undefined) {
  return date ? moment.unix(date).toDate() : undefined;
}

type StatusPickerKeys = StatusKey | 'all';

export const PaymentFilterScreen: React.FC<
  NativeStackScreenProps<NavParams, Nav.PaymentFilterScreen>
> = ({navigation, route}) => {
  const {filters} = route.params ?? {};
  const i18n = useTranslation();

  const [fromDate, setFromDate] = useState<Date | undefined>(
    dateFromUnix(filters?.startDate) ?? moment().subtract(1, 'months').startOf('day').toDate()
  );
  const [fromDateTime, setFromDateTime] = useState<Date | undefined>(
    dateFromUnix(filters?.startDate) ?? moment('00:00', 'HH:mm').toDate()
  );
  const [toDate, setToDate] = useState<Date | undefined>(
    dateFromUnix(filters?.endDate) ?? new Date()
  );
  const [toDateTime, setToDateTime] = useState<Date | undefined>(
    dateFromUnix(filters?.endDate) ?? moment('23:59', 'HH:mm').toDate()
  );
  const [validDate, setDateValid] = useState(true);

  const [status, setStatus] = useState<StatusPickerKeys>(filters?.status ?? 'all');

  const options: Record<StatusPickerKeys, string> = {
    all: i18n.t('all'),
    succeeded: i18n.t('SUCCEEDED'),
    refunded: i18n.t('REFUNDED'),
    uncaptured: i18n.t('AUTHORIZED'),
    failed: i18n.t('FAILED')
  };

  useEffect(() => {
    const startDate = mergeDateAndDatetime(fromDate, fromDateTime, 0);
    const endDate = mergeDateAndDatetime(toDate, toDateTime, 59);
    if (endDate && startDate && endDate < startDate) {
      setDateValid(false);
    } else {
      setDateValid(true);
    }
  }, [fromDate, fromDateTime, toDate, toDateTime]);

  useLayoutEffect(() => {
    navigation.setOptions({
      headerRight: () => (
        <HeaderButtonRight
          onPress={() => {
            navigation.navigate(Nav.PaymentsScreen, {filters: undefined});
          }}
          text={i18n.t('reset')}
        />
      )
    });
  }, [navigation, i18n]);

  const now = new Date();

  return (
    <SafeAreaView style={[t.flex1, t.bgWhite]} edges={['bottom']}>
      <ScrollView style={[t.flex1]} contentContainerStyle={[t.pX4]}>
        <Box style={[t.pY2]}>
          <Text bold>{i18n.t('paymentStatus')}</Text>
          <Select
            selectedValue={status}
            text={options[status]}
            onSelected={(newKey) => setStatus(newKey as StatusKey)}
            options={options}
          />
        </Box>
        <Divider />
        <Box style={[t.pY2]}>
          <Text style={[t.pB1]} bold>
            {i18n.t('dateRange')}
          </Text>
          <FormControl isInvalid={!validDate}>
            <Text>{i18n.t('from')}</Text>
            <HStack space={2} marginBottom={1}>
              <Box style={{flex: 1}}>
                <DateInput
                  mode="date"
                  placeholder={i18n.t('from')}
                  maxDate={now}
                  date={fromDate}
                  onDateChange={(newDate) => {
                    setFromDate(newDate);
                    if (!toDate) {
                      setToDate(newDate);
                    }
                  }}
                />
              </Box>
              <Box style={{width: 130}}>
                <DateInput mode="time" date={fromDateTime} onDateChange={setFromDateTime} />
              </Box>
            </HStack>
            <Text>{i18n.t('to')}</Text>
            <HStack space={2}>
              <Box style={{flex: 1}}>
                <DateInput
                  mode="date"
                  placeholder={i18n.t('to')}
                  date={toDate}
                  onDateChange={setToDate}
                  minDate={fromDate}
                  maxDate={now}
                />
                <FormControl.ErrorMessage leftIcon={<WarningOutlineIcon size="xs" />}>
                  {i18n.t('dateFilterError')}
                </FormControl.ErrorMessage>
              </Box>
              <Box style={{width: 130}}>
                <DateInput mode="time" date={toDateTime} onDateChange={setToDateTime} />
              </Box>
            </HStack>
          </FormControl>
          <Box style={[t.mT2]}>
            <DatePresets
              onDateChange={(newStart, newEnd) => {
                setFromDate(newStart);
                setToDate(newEnd);
              }}
            />
          </Box>
        </Box>
      </ScrollView>
      <Box style={[t.p4]}>
        <Button
          isDisabled={!validDate}
          onPress={() => {
            navigation.navigate(Nav.PaymentsScreen, {
              filters: clearObject<PaymentFilter>({
                startDate: mergeDateAndDatetime(fromDate, fromDateTime, 0),
                endDate: mergeDateAndDatetime(toDate, toDateTime, 59),
                status: status !== 'all' ? status : undefined
              })
            });
          }}>
          {i18n.t('applyFilters')}
        </Button>
      </Box>
    </SafeAreaView>
  );
};

function DatePresets({
  onDateChange
}: {
  onDateChange: (newStartDate: Date, newEndDate: Date) => void;
}) {
  const i18n = useTranslation();
  const presets = [
    {label: i18n.t('today'), startDate: moment().startOf('day')},
    {label: i18n.t('lastWeek'), startDate: moment().subtract(7, 'days').startOf('day')},
    {label: i18n.t('last14days'), startDate: moment().subtract(14, 'days').startOf('day')},
    {label: i18n.t('lastMonth'), startDate: moment().subtract(1, 'months').startOf('day')},
    {label: i18n.t('last3Months'), startDate: moment().subtract(3, 'months').startOf('day')}
  ];

  return (
    <Box style={[t.flexRow, t.flexWrap]}>
      {presets.map((preset) => (
        <Box key={preset.label}>
          <Button
            marginRight={2}
            marginTop={2}
            variant={'outline'}
            onPress={() => onDateChange(preset.startDate.toDate(), new Date())}>
            {preset.label}
          </Button>
        </Box>
      ))}
    </Box>
  );
}
