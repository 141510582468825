import {useFocusEffect} from '@react-navigation/native';
import {NativeStackScreenProps} from '@react-navigation/native-stack';
import * as Brightness from 'expo-brightness';
import {useKeepAwake} from 'expo-keep-awake';
import {Box, Button, Divider} from 'native-base';
import React, {useCallback, useEffect, useLayoutEffect, useState} from 'react';
import {ScrollView} from 'react-native';
import {SafeAreaView} from 'react-native-safe-area-context';
import {t} from 'react-native-tailwindcss';
import {ChargeStatus, useChargeQuery, useOnUpdateChargeSubscription} from '../api/generated';
import {Loader} from '../components/Loader';
import {PropertyCardList} from '../components/PropertyList';
import {QRCode} from '../components/QRCode';
import {useAccountName} from '../hooks/useAccountName';
import {usePaymentUrl} from '../hooks/usePaymentUrl';
import {useUserContext} from '../lib/authContext';
import {formatIntAmount} from '../lib/formatters';
import {useTranslation} from 'react-i18next';
import {triggerHapticFeedback} from '../lib/hapticFeedback';
import {shareUrl} from '../lib/utils';
import {Nav, NavParams} from './navigation';
import {HeaderButtonRight} from '../components/HeaderButton';

const POLLING_DELAY = 7000;
const POLLING_INTERVAL = 3000;

export const PaymentQRScreen: React.FC<NativeStackScreenProps<NavParams, Nav.PaymentQRScreen>> = ({
  navigation,
  route
}) => {
  useKeepAwake();
  const {paymentId} = route.params;
  const i18n = useTranslation();
  const merchantName = useAccountName();
  const {accountId} = useUserContext();
  const [brightness, setBrightness] = useState(1);
  const paymentUrl = usePaymentUrl(paymentId);
  const {
    data,
    loading: loadingPayment,
    startPolling
  } = useChargeQuery({
    variables: {id: paymentId}
  });
  const payment = data?.charge;
  const status = data?.charge?.status;

  useOnUpdateChargeSubscription({
    variables: {accountId, id: paymentId}
  });

  useEffect(() => {
    const timeout = setTimeout(() => {
      startPolling(POLLING_INTERVAL);
    }, POLLING_DELAY);
    return () => {
      clearTimeout(timeout);
    };
  }, []);

  useEffect(() => {
    if (status && ![ChargeStatus.PENDING, ChargeStatus.PENDING_PROCESSING].includes(status)) {
      triggerHapticFeedback();
      const hasPaymentScreenInHistory = navigation
        .getState()
        .routes.find((route) => route.name === Nav.PaymentScreen);
      hasPaymentScreenInHistory
        ? navigation.navigate(Nav.PaymentScreen, {paymentId}) // will navigate to existing payments route
        : navigation.replace(Nav.PaymentScreen, {paymentId});
    }
  }, [status, navigation, paymentId]);

  const setMaximumBrightness = useCallback(async () => {
    try {
      const {status} = await Brightness.requestPermissionsAsync();
      if (status !== 'granted') return;
    } catch (error) {
      console.log(error);
    }
    const brightness = await Brightness.getBrightnessAsync();
    setBrightness(brightness);
    return Brightness.setSystemBrightnessAsync(1);
  }, []);

  const restoreBrightness = useCallback(async () => {
    try {
      const {status} = await Brightness.requestPermissionsAsync();
      if (status !== 'granted') return;
    } catch (error) {
      console.log(error);
    }
    return Brightness.setSystemBrightnessAsync(brightness);
  }, [brightness]);

  useEffect(() => {
    return navigation.addListener('focus', setMaximumBrightness);
  }, [setMaximumBrightness, navigation]);

  useEffect(() => {
    // called when we close the screen
    return navigation.addListener('beforeRemove', restoreBrightness);
  }, [restoreBrightness, navigation]);

  useEffect(() => {
    // called when we navigate to the next screen e.g Send payment request
    return navigation.addListener('blur', restoreBrightness);
  }, [restoreBrightness, navigation]);

  const handleShare = useCallback(() => {
    return shareUrl(paymentUrl);
  }, [paymentUrl]);

  const handleRequestToPay = () => {
    navigation.navigate(Nav.RequestToPayScreen, {paymentId});
  };

  useLayoutEffect(() => {
    navigation.setOptions({
      headerRight: () => <HeaderButtonRight onPress={handleShare} icon={'share-social'} />
    });
  }, [navigation, route, handleShare]);

  if (loadingPayment || !payment) return <Loader active />;

  const properties = [
    {
      label: i18n.t('amount'),
      value: formatIntAmount(payment.amount)
    },
    {
      label: i18n.t('description'),
      value: payment.description
    },
    {
      label: i18n.t('companyName'),
      value: merchantName
    }
  ];

  return (
    <SafeAreaView style={[t.flex1, t.bgWhite]} edges={['bottom']}>
      <ScrollView>
        <Box style={[t.flex1, t.m4]}>
          <Box borderWidth="1" borderRadius="md" borderColor="gray.200" style={[t.p2, t.pB0]}>
            <Box style={[t.mT1, t.flex, t.itemsCenter, t.justifyCenter]}>
              <Box style={[t.p4, t.mB2]}>
                <QRCode paymentId={paymentId} />
              </Box>
              <Divider />
            </Box>
            <PropertyCardList properties={properties} />
          </Box>
        </Box>
      </ScrollView>
      <Box style={[t.p4]}>
        <Button onPress={handleRequestToPay}>{i18n.t('requestToPay')}</Button>
      </Box>
    </SafeAreaView>
  );
};
