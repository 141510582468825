import React, {useEffect} from 'react';
import {Box, HStack, Text, useTheme, VStack} from 'native-base';
import {
  Interval,
  useOnUpdateAnalyticsSubscription,
  useSucceededDateRangeKpiQuery
} from '../api/generated';
import moment from '../lib/moment';
import {t} from 'react-native-tailwindcss';
import {formatMoney} from '../lib/formatters';
import {useTranslation} from 'react-i18next';
import {useAsyncAction} from '../hooks/useAsyncAction';
import SkeletonContent from 'react-native-skeleton-content';
import {useUserContext} from '../lib/authContext';
import {useDebouncedCallback} from 'use-debounce';

interface PaymentSummaryCardProps {
  label: string;
  timezone: string;
  startDate: moment.Dayjs;
  endDate: moment.Dayjs;
  freshData: boolean;
  itemWidth?: number;
  isPlaceholder: boolean;
  isSubsequentItem?: boolean;
  shouldRefetch: boolean;
}

function TextLoadingSkeleton({
  isLoading,
  children
}: {
  isLoading: boolean;
  children: React.ReactNode;
}) {
  return (
    <SkeletonContent
      isLoading={isLoading}
      containerStyle={{minWidth: 60, minHeight: 20, marginVertical: 2, alignItems: 'center'}}
      layout={[{width: 60, height: 20}]}>
      {children}
    </SkeletonContent>
  );
}

export function PaymentSummaryCard({
  timezone,
  startDate,
  endDate,
  freshData,
  itemWidth,
  label,
  shouldRefetch,
  isPlaceholder,
  isSubsequentItem
}: PaymentSummaryCardProps) {
  const i18n = useTranslation();
  const {colors} = useTheme();
  const {accountId} = useUserContext();

  const {data, loading, refetch} = useSucceededDateRangeKpiQuery({
    variables: {
      start: startDate.unix(),
      end: endDate.unix(),
      interval: Interval.DAY,
      timezone
    },
    fetchPolicy: freshData ? 'network-only' : 'cache-first',
    skip: isPlaceholder
  });

  const [onRefresh, refreshing] = useAsyncAction(refetch);
  const refetchDebounced = useDebouncedCallback(refetch, 5000);

  useOnUpdateAnalyticsSubscription({
    variables: {accountId},
    skip: isPlaceholder || !freshData,
    onData: () => refetchDebounced()
  });

  useEffect(() => {
    if (shouldRefetch && !loading && !isPlaceholder && !refreshing) {
      onRefresh();
    }
  }, [shouldRefetch, loading, onRefresh, isPlaceholder, refreshing]);

  const renderContent = () => {
    const isLoading = isPlaceholder || loading;

    const totalAmount = data?.chargesDateRangeKPI.total.succeededAmount
      ? formatMoney(
          data.chargesDateRangeKPI.total.succeededAmount / 100,
          data.chargesDateRangeKPI.currency
        )
      : '0';

    const totalCount = data?.chargesDateRangeKPI.total.succeededCount ?? '0';

    return (
      <HStack justifyContent={'space-around'} alignSelf="stretch">
        <VStack flex={1} alignItems={'center'}>
          <Text style={[t.textLg, t.textGray700]}>{i18n.t('grossVolume')}</Text>
          <TextLoadingSkeleton isLoading={isLoading}>
            <Text bold style={[t.textLg]}>
              {totalAmount}
            </Text>
          </TextLoadingSkeleton>
        </VStack>
        <VStack flex={1} alignItems={'center'}>
          <Text style={[t.textGray700, t.textLg]}>{i18n.t('payments')}</Text>
          <TextLoadingSkeleton isLoading={isLoading}>
            <Text bold style={[t.textLg]}>
              {totalCount}
            </Text>
          </TextLoadingSkeleton>
        </VStack>
      </HStack>
    );
  };

  return (
    <Box style={[{width: itemWidth ?? '100%'}, t.pX4, t.pT2, t.pB4, isSubsequentItem && t._mR6]}>
      <Text style={[t.textLg]} bold>
        {label}
      </Text>
      <Box
        borderRadius={'sm'}
        style={[
          t.itemsCenter,
          t.justifyCenter,
          t.mT2,
          t.p2,
          {borderWidth: 1, minHeight: 90, borderColor: colors.gray[300]}
        ]}>
        {renderContent()}
      </Box>
    </Box>
  );
}
