import {modalOptions} from '../MainStack';
import React from 'react';
import {CreatePaymentAmountScreen} from './CreatePaymentAmountScreen';
import {CreatePaymentDescriptionScreen} from './CreatePaymentDescriptionScreen';
import {useTranslation} from 'react-i18next';
import {useTheme} from 'native-base';
import {createNativeStackNavigator} from '@react-navigation/native-stack';

export enum NavCreatePayment {
  AmountScreen = 'CreatePaymentAmountScreen',
  DescriptionScreen = 'CreatePaymentDescriptionScreen'
}

export type CreatePaymentParams = {
  [NavCreatePayment.AmountScreen]: undefined;
  [NavCreatePayment.DescriptionScreen]: {amount: number};
};

export const Stack = createNativeStackNavigator<CreatePaymentParams>();

export const createPaymentStackLinking = {
  initialRouteName: NavCreatePayment.AmountScreen,
  screens: {
    [NavCreatePayment.AmountScreen]: '/payments/new/amount',
    [NavCreatePayment.DescriptionScreen]: {
      path: '/payments/new/description',
      parse: {amount: Number}
    }
  }
};

export const CreatePaymentScreens: React.FC = () => {
  const i18n = useTranslation();
  const {colors} = useTheme();
  return (
    <Stack.Navigator
      initialRouteName={NavCreatePayment.AmountScreen}
      screenOptions={{
        headerTitleAlign: 'center',
        headerBackTitleVisible: false,
        headerTintColor: colors.primary[600],
        headerTitleStyle: {
          color: 'black'
        }
      }}>
      <Stack.Screen
        name={NavCreatePayment.AmountScreen}
        component={CreatePaymentAmountScreen}
        options={({navigation: {goBack}}) => ({
          title: i18n.t('createPayment'),
          ...modalOptions(goBack)
        })}
      />
      <Stack.Screen
        name={NavCreatePayment.DescriptionScreen}
        component={CreatePaymentDescriptionScreen}
        options={{title: i18n.t('paymentDescription')}}
      />
    </Stack.Navigator>
  );
};
