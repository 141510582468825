import React from 'react';
import {Platform, KeyboardAvoidingView as RNKeyboardAvoidingView} from 'react-native';
import {t} from 'react-native-tailwindcss';

interface Props {
  offset?: number;
  children?: React.ReactNode;
}

export const KeyboardAvoidingView: React.FC<Props> = ({children, offset}) => {
  return (
    <RNKeyboardAvoidingView
      style={[t.flex1]}
      behavior={Platform.OS === 'ios' ? 'padding' : undefined}
      keyboardVerticalOffset={offset}>
      {children}
    </RNKeyboardAvoidingView>
  );
};
