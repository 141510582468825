module.exports = {
  theme: {
    extend: {
      colors: {
        primary: '#8961a5',
        purple: {
          50: '#552E44',
          100: '#653859',
          200: '#764270',
          300: '#814C86',
          400: '#865695',
          500: '#8961a5',
          600: '#8F79B5',
          700: '#9992C4',
          800: '#ABAED2',
          900: '#C4CDE1'
        }
      }
    }
  }
};
