import {TransactionExpiration} from '../lib/paymentUtils';
import useAsyncStorage from './useAsyncStorage';
import {ASYNC_STORAGE} from '../storage/storageKeys';
import {useCallback} from 'react';

const DEFAULT_SETTINGS = {
  showQR: true,
  showDescription: false,
  useBiometricAuth: false,
  transactionExpiration: TransactionExpiration.MINUTES_15
};

export const useSettings = () => {
  return useAsyncStorage(ASYNC_STORAGE.APP_SETTINGS, DEFAULT_SETTINGS);
};

export function useClearSettings() {
  const [settings, setSettings] = useAsyncStorage(ASYNC_STORAGE.APP_SETTINGS, DEFAULT_SETTINGS);
  const clearSettings = useCallback(() => {
    setSettings({...settings, useBiometricAuth: DEFAULT_SETTINGS.useBiometricAuth});
  }, [settings, setSettings]);
  return clearSettings;
}
