import {
  CountryCode,
  getCountryCallingCode,
  formatIncompletePhoneNumber,
  getExampleNumber,
  isValidPhoneNumber,
  validatePhoneNumberLength,
  parseIncompletePhoneNumber
} from 'libphonenumber-js';
import examples from 'libphonenumber-js/examples.mobile.json';
import {IInputProps, Input} from 'native-base';
import React, {useMemo, useCallback} from 'react';

type PhoneNumberInputProps = IInputProps & {
  countryCode?: CountryCode;
  onChangeValidation?: (isValid: boolean) => void;
};

export const PhoneNumberInput = React.forwardRef<any, PhoneNumberInputProps>(
  ({onChangeText, onChangeValidation, countryCode, value, ...rest}, ref) => {
    const code = useMemo(() => {
      if (!countryCode) return '1';
      return getCountryCallingCode(countryCode);
    }, [countryCode]);

    const formattedValue = useMemo(() => formatIncompletePhoneNumber(value || ''), [value]);

    const placeholder = useMemo(
      () => getExampleNumber(countryCode as CountryCode, examples)?.formatInternational(),
      [countryCode]
    );

    const onChange = useCallback(
      (value: string) => {
        if (!value.startsWith('+')) {
          value = `+${code}${value}`;
        }
        if (validatePhoneNumberLength(value) === 'TOO_LONG') {
          return;
        }
        onChangeValidation?.(isValidPhoneNumber(value));
        onChangeText?.(parseIncompletePhoneNumber(value));
      },
      [code, onChangeText, onChangeValidation]
    );

    return (
      <Input
        {...rest}
        ref={ref ?? undefined}
        value={formattedValue}
        onChangeText={onChange}
        placeholder={placeholder}
        autoComplete="tel"
        textContentType="telephoneNumber"
        keyboardType="phone-pad"
      />
    );
  }
);
